import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody, Row, Col, Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import { formatNumber } from '../../helpers/utils';

const SituationAtHome = ({ className }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery('special-reports/home-situation', fetch);

  return (
    <Card className={className}>
      <FalconCardHeader title="Situation at Home" titleTag="h5">
        <Button color="falcon-default" size="sm" id="sahPopover" iconClassName="fs--2">
          SQL
        </Button>
        <UncontrolledPopover placement="left" target="sahPopover">
          <PopoverHeader>SQL</PopoverHeader>
          <PopoverBody>
            <pre>
              SELECT COUNT(*) as count_driver, ( SELECT COUNT(*) FROM drivers d JOIN relations r ON d.id = r.driver_id
              and r.enabled = true JOIN driver_addresses da on d.id = da.driver_id and da.type = 'home' and can_charge =
              true ) as charge_at_home, ( SELECT COUNT(*) FROM drivers d JOIN relations r ON d.id = r.driver_id and
              r.enabled = true JOIN driver_addresses da on d.id = da.driver_id and da.type = 'work' and can_charge =
              true ) as charge_at_work, ( SELECT COUNT(*) FROM drivers d JOIN relations r ON d.id = r.driver_id and
              r.enabled = true WHERE d.use_public_charging = true ) as charge_at_publicity, ( SELECT COUNT(*) FROM
              drivers d JOIN relations r ON d.id = r.driver_id and r.enabled = true JOIN driver_addresses da on d.id =
              da.driver_id and da.type = 'home' and photovoltaic_construction = true ) as photovoltaic_construction, (
              SELECT COUNT(*) FROM drivers d JOIN relations r ON d.id = r.driver_id and r.enabled = true JOIN
              driver_addresses da on d.id = da.driver_id and da.type = 'home' and energy_storage = true ) as
              energy_storage, ( SELECT COUNT(*) FROM drivers d JOIN relations r ON d.id = r.driver_id and r.enabled =
              true JOIN driver_addresses da on d.id = da.driver_id and da.type = 'home' and garage = true ) as garage
              FROM drivers d JOIN relations r ON d.id = r.driver_id and r.enabled = true
            </pre>
          </PopoverBody>
        </UncontrolledPopover>
      </FalconCardHeader>
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <Row className="align-items-center">
            <Col xs={6}>
              <Row className="mb-3">
                <Col xs={6}>Active Driver</Col>
                <Col>{data?.data?.count_driver}</Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>
                  Can charge at <span className="color-home">home</span>
                </Col>
                <Col>
                  {data?.data?.charge_at_home} (
                  {formatNumber((data?.data?.charge_at_home / data?.data?.count_driver) * 100)}%)
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>
                  Can charge at <span className="color-work">work</span>
                </Col>
                <Col>
                  {data?.data?.charge_at_work} (
                  {formatNumber((data?.data?.charge_at_work / data?.data?.count_driver) * 100)}%)
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>
                  Can charge at <span className="color-custom">publicly</span>
                </Col>
                <Col>
                  {data?.data?.charge_at_publicity} (
                  {formatNumber((data?.data?.charge_at_publicity / data?.data?.count_driver) * 100)}%)
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>Photovoltaic Construction</Col>
                <Col>
                  {data?.data?.photovoltaic_construction} (
                  {formatNumber((data?.data?.photovoltaic_construction / data?.data?.count_driver) * 100)}%)
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>Energy Storage</Col>
                <Col>
                  {data?.data?.energy_storage} (
                  {formatNumber((data?.data?.energy_storage / data?.data?.count_driver) * 100)}%)
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>Garage</Col>
                <Col>
                  {data?.data?.garage} ({formatNumber((data?.data?.garage / data?.data?.count_driver) * 100)}%)
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

SituationAtHome.propTypes = {
  className: PropTypes.string
};

export default SituationAtHome;

import React from 'react';
import PropTypes from 'prop-types';
import { getGrays, getPosition, themeColors } from '../../helpers/utils';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';

const getOption = (data, unit, noHome) => {
  const grays = getGrays(false);

  return {
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      formatter: `{c}${unit} ({d}%)`,
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    series: [
      {
        type: 'pie',
        radius: '75%',
        center: ['50%', '50%'],
        selectedMode: 'single',
        data,
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        }
      }
    ],
    color: noHome ? ['#56B6C2', '#C678DD'] : ['#E4C07B', '#56B6C2', '#C678DD']
  };
};

const PieChart = ({ data, unit, height, noHome }) => {
  return <ReactEchartsCore echarts={echarts} option={getOption(data, unit, noHome)} style={{ width: '100%', height }} />;
};

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  unit: PropTypes.string,
  height: PropTypes.string
};
PieChart.defaultProps = {
  unit: '',
  height: '12rem'
};

export default PieChart;

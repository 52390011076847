import React, { useState } from 'react';
import {
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col,
  Row
} from 'reactstrap';
import ChargingProviderGraph from '../components/dashboard/ChargingProviderGraph';
import Datetime from 'react-datetime';
import PageHeaderSplit from '../components/common/PageHeaderSplit';
import { useStoreState } from '../stores/RequestStore';
import moment from 'moment';
import ChargeLocations from '../components/stats/ChargeLocations';
import ElectricityContracts from '../components/special-reports/ElectricityContracts';
import SituationAtHome from '../components/special-reports/SituationAtHome';
import BatterySizeCharging from '../components/special-reports/BatterySizeCharging';
import BatterySizeChargedSum from '../components/special-reports/BatterySizeChargedSum';
import StatsByModel from '../components/special-reports/StatsByModel';
import CardSummary from '../components/CardSummary';
import { formatNumber } from '../helpers/utils';
import { useFetch } from '../request';
import { useQuery } from 'react-query';
import ActiveDriver from '../components/special-reports/ActiveDriver';
import ChargeLocationsWithoutHome from '../components/stats/ChargeLocationsWithoutHome';
import ActivitiesPerWeekday from '../components/special-reports/ActivitiesPerWeekday';

const initStart = moment.utc().startOf('month');
const initEnd = moment.utc().endOf('month');

const SpecialReports = () => {
  const [filter, setFilter] = useStoreState('special-reports', {
    startTime: {
      gte: initStart.toISOString(),
      lte: initEnd.toISOString()
    }
  });

  const [startDate, setStartDate] = useState(moment.utc(filter.startTime.gte));
  const [endDate, setEndDate] = useState(moment.utc(filter.startTime.lte));

  const submitDateFilter = e => {
    e.preventDefault();
    const newFilter = { ...filter };
    newFilter.startTime = {
      gte: startDate.toISOString(),
      lte: endDate.toISOString()
    };
    setFilter(newFilter);
  };

  const fetch = useFetch();
  const driverPublicQuery = useQuery(['special-reports/driver-public-charging'], fetch);

  return (
    <>
      <PageHeaderSplit title="Special Reports" className="mb-3">
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <InputGroup className="flex-nowrap">
              <Datetime
                closeOnSelect
                isValidDate={current => current.isBefore(endDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={startDate}
                onChange={setStartDate}
                inputProps={{ className: 'form-control border-right-0 rounded-right-0' }}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>-</InputGroupText>
              </InputGroupAddon>
              <Datetime
                closeOnSelect
                isValidDate={current => current.isAfter(startDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={endDate}
                onChange={setEndDate}
                inputProps={{ className: 'form-control rounded-left-0' }}
                className="calendar-right"
              />
            </InputGroup>
          </FormGroup>
          <Button onClick={submitDateFilter} color="falcon-default">
            Apply
          </Button>
        </Form>
      </PageHeaderSplit>
      <ChargingProviderGraph
        className="mb-3"
        queryKey="stats/charging-providers"
        params={{
          report: true,
          from: filter.startTime.gte,
          to: filter.startTime.lte
        }}
        minHeight="23rem"
        children={
          <>
            <Button color="falcon-default" size="sm" id="empPopover" iconClassName="fs--2">
              SQL
            </Button>
            <UncontrolledPopover placement="left" target="empPopover">
              <PopoverHeader>SQL</PopoverHeader>
              <PopoverBody>
                <pre>
                  SELECT COUNT(*) as num, cp.label as label FROM trips t LEFT JOIN positions p on t.start_id = p.id LEFT
                  JOIN charging_providers cp on t.charging_provider_id = cp.id WHERE t.type = 'charge' and p.type =
                  'custom' and cp.label IS NOT NULL GROUP BY cp.label ORDER BY cp.label
                </pre>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        }
      />

      <Row className="card-gutter-wrapper">
        <Col md={6} className="card-gutter mb-3">
          <ChargeLocations
            filter={{ report: true }}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
            children={
              <>
                <Button color="falcon-default" size="sm" id="empPopover" iconClassName="fs--2">
                  SQL
                </Button>
                <UncontrolledPopover placement="left" target="empPopover">
                  <PopoverHeader>SQL</PopoverHeader>
                  <PopoverBody>
                    <div>Driver's relation IDs</div>
                    <pre>
                      SELECT r.id FROM drivers d LEFT JOIN relations r on d.id = r.driver_id and r.enabled = true WHERE
                      (d.id IN (SELECT id FROM drivers WHERE use_public_charging = true) OR d.id IN (SELECT d.id FROM
                      drivers d LEFT JOIN driver_addresses da on d.id = da.driver_id WHERE d.use_public_charging = false
                      GROUP BY d.id)) AND r.id IS NOT NULL
                    </pre>
                    <div className="mt-2">Calculation</div>
                    <pre>
                      SELECT p.type, COUNT(t.*) as num, SUM(t.diff_energy) as "diffEnergySum", AVG(t.diff_energy) as
                      "diffEnergyAvg", AVG(t.avg_charge) as "chargeAvg", AVG(t.costs) as "costsAvg",
                      AVG(t.battery_price_per_kwh) as "pricePerKwhAvg", AVG(t.start_time::time)::time as "startTimeAvg",
                      AVG(t.end_time::time)::time as "endTimeAvg" FROM trips t LEFT JOIN positions p on t.start_id =
                      p.id WHERE t.type = 'charge' and p.type IS NOT NULL and t.relation_id IN (:id) and start_time >=
                      :from and start_time &lt;= :to GROUP BY p.type
                    </pre>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            }
          />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <ChargeLocationsWithoutHome
            filter={{ report: true }}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
            noHome
            children={
              <>
                <Button color="falcon-default" size="sm" id="empPopover" iconClassName="fs--2">
                  SQL
                </Button>
                <UncontrolledPopover placement="left" target="empPopover">
                  <PopoverHeader>SQL</PopoverHeader>
                  <PopoverBody>
                    <div>Driver's relation IDs</div>
                    <pre>
                      SELECT r.id FROM drivers d LEFT JOIN relations r on d.id = r.driver_id and r.enabled = true WHERE
                      (d.id IN (SELECT id FROM drivers WHERE use_public_charging = true) OR d.id IN (SELECT d.id FROM
                      drivers d LEFT JOIN driver_addresses da on d.id = da.driver_id WHERE d.use_public_charging = false
                      GROUP BY d.id)) AND r.id IS NOT NULL
                    </pre>
                    <div className="mt-2">Calculation</div>
                    <pre>
                      SELECT p.type, COUNT(t.*) as num, SUM(t.diff_energy) as "diffEnergySum", AVG(t.diff_energy) as
                      "diffEnergyAvg", AVG(t.avg_charge) as "chargeAvg", AVG(t.costs) as "costsAvg",
                      AVG(t.battery_price_per_kwh) as "pricePerKwhAvg", AVG(t.start_time::time)::time as "startTimeAvg",
                      AVG(t.end_time::time)::time as "endTimeAvg" FROM trips t LEFT JOIN positions p on t.start_id =
                      p.id WHERE t.type = 'charge' and p.type IS NOT NULL and t.relation_id IN (:id) and start_time >=
                      :from and start_time &lt;= :to GROUP BY p.type
                    </pre>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            }
          />
        </Col>
      </Row>

      <ElectricityContracts className="mb-3" />

      <SituationAtHome className="mb-3" />

      <StatsByModel className="mb-3" />

      <Row className="card-gutter-wrapper">
        <Col md={6} className="card-gutter mb-3">
          <BatterySizeCharging />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <BatterySizeCharging useRatio />
        </Col>
      </Row>

      <Row className="card-gutter-wrapper">
        <Col md={6} className="card-gutter mb-3">
          <BatterySizeChargedSum />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <ActiveDriver />
        </Col>
      </Row>

      <Row className="card-gutter-wrapper">
        <Col md={6} className="card-gutter mb-3">
          <ActivitiesPerWeekday type="ride" from={filter.startTime.gte} to={filter.startTime.lte} />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <ActivitiesPerWeekday type="charge" from={filter.startTime.gte} to={filter.startTime.lte} />
        </Col>
      </Row>

      <Row className="row-cols-2 row-cols-lg-4 card-gutter-wrapper align-items-stretch">
        <Col className="mb-3 card-gutter">
          <CardSummary title="Driver with public" color="info">
            {formatNumber(driverPublicQuery?.data?.data?.amount, 0) || 0}
          </CardSummary>
        </Col>
      </Row>
    </>
  );
};

export default SpecialReports;

import React from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import minBy from 'lodash/minBy';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Card, CardBody } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import { formatNumber, getGrays, getPosition, themeColors } from '../../helpers/utils';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import HelpTooltip from '../HelpTooltip';
import { format } from 'date-fns';

const getOptions = (data, color, xAxisFormat = 'dd/MM', xAxisFormatPrefix = '') => {
  const grays = getGrays(false);

  const minEntry = minBy(data, d => Math.min(d.accelerationScore, d.brakingScore, d.corneringScore, d.drivingScore));
  const min = Math.min(
    minEntry.accelerationScore,
    minEntry.brakingScore,
    minEntry.corneringScore,
    minEntry.drivingScore
  );

  const accelerationScore = data.map(d => [d.startTime, Math.round(d.accelerationScore * 100)]);
  const brakingScore = data.map(d => [d.startTime, Math.round(d.brakingScore * 100)]);
  const corneringScore = data.map(d => [d.startTime, Math.round(d.corneringScore * 100)]);
  const drivingScore = data.map(d => [d.startTime, Math.round(d.drivingScore * 100)]);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],

      formatter: function(params) {
        const paramText = params
          .map(p => {
            return `<span class="chart-tooltip-point" style="background-color:${p.color};"></span> ${
              p.seriesName
            }: ${formatNumber(p.value[1], 0)}`;
          })
          .join('<br />');
        return `${format(params[0].axisValue, xAxisFormat)}<br />${paramText}`;
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'time',
      // interval: 300 * 1000,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return xAxisFormatPrefix + format(new Date(value), xAxisFormat);
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      min: Math.floor(min / 10) * 1000, // take the previous thousand scale
      axisLine: { show: false },
      axisLabel: {
        show: true,
        // formatter: `{value} ${unit}`,
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'line',
        lineStyle: {
          color: themeColors.success,
          width: 3
        },
        itemStyle: {
          color: themeColors.success
        },
        hoverAnimation: true,
        name: 'Driving Score',
        data: drivingScore,
        smooth: 0.6,
        smoothMonotone: 'x',
        symbol: 'circle',
        symbolSize: 8
      },
      {
        type: 'line',
        lineStyle: {
          color: themeColors.info,
          width: 3
        },
        itemStyle: {
          color: themeColors.info
        },
        hoverAnimation: true,
        name: 'Acceleration Score',
        data: accelerationScore,
        smooth: 0.6,
        smoothMonotone: 'x',
        symbol: 'circle',
        symbolSize: 8
      },
      {
        type: 'line',
        lineStyle: {
          color: themeColors.info,
          width: 3
        },
        itemStyle: {
          color: themeColors.info
        },
        hoverAnimation: true,
        name: 'Braking Score',
        data: brakingScore,
        smooth: 0.6,
        smoothMonotone: 'x',
        symbol: 'circle',
        symbolSize: 8
      },
      {
        type: 'line',
        lineStyle: {
          color: themeColors.info,
          width: 3
        },
        itemStyle: {
          color: themeColors.info
        },
        hoverAnimation: true,
        name: 'Cornering Score',
        data: corneringScore,
        smooth: 0.6,
        smoothMonotone: 'x',
        symbol: 'circle',
        symbolSize: 8
      }
    ],
    grid: { right: '28px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const EcoScoresHistoryChart = ({ driverId, className, unit }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(`drivers/${driverId}/scores`, fetch);

  return (
    <Card className={className}>
      <FalconCardHeader
        title={
          <>
            Eco Score Chart <HelpTooltip id="ecoScoreHistoryTip">Eco Scores for the last 50 rides</HelpTooltip>
          </>
        }
        titleTag="h5"
      />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : size(data?.data) > 0 ? (
          <ReactEchartsCore
            echarts={echarts}
            option={getOptions(data.data)}
            style={{ width: '100%', minHeight: '15rem', height: '100%' }}
          />
        ) : (
          <Alert color="danger" className="text-center mb-0">
            Could not find any data.
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

EcoScoresHistoryChart.propTypes = {
  className: PropTypes.string,
  driverId: PropTypes.string.isRequired,
  unit: PropTypes.string,
};

export default EcoScoresHistoryChart;

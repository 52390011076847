import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useQueryCache } from 'react-query';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Badge, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { formatUser } from '../helpers/utils';
import ButtonIcon from '../components/common/ButtonIcon';
import SelectGroupModal from '../components/SelectGroupModal';
import { useStoreState as useSettingStoreState } from '../stores/SettingsStore';
import { useStoreState } from '../stores/RequestStore';
import Flex from '../components/common/Flex';
import FormModal from '../components/FormModal';
import DeviceForm from '../components/form/DeviceForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnlinkDeviceModal from '../components/device/UnlinkDeviceModal';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { useFetch } from '../request';
import DeviceStats from '../components/device/DeviceStats';
import AssignDriverModal from '../components/AssignDriverModal';

export const badgeFormatter = status => {
  let color = '';
  let icon = '';
  let text = '';
  switch (status) {
    case true:
      color = 'success';
      icon = 'check';
      text = 'Enabled';
      break;
    case false:
      color = 'warning';
      icon = 'ban';
      text = 'Disabled';
      break;
    default:
      color = 'info';
      icon = 'unlink';
      text = 'Unlinked';
      break;
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule">
      {text}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const Devices = () => {
  const [showModal, setShowModal] = useState(false);
  const [{ isAdmin = false }] = useSettingStoreState('currentUser');
  const [unlinkRelation, setUnlinkRelation] = useState(null);
  const [groupRelation, setGroupRelation] = useState(null);
  const [driverRelation, setDriverRelation] = useState(null);
  const [params, setParams] = useStoreState('devices', {
    page: 1,
    size: 15,
    order: 'id'
  });
  const queryCache = useQueryCache();
  const fetch = useFetch();
  const { data: groups } = useQuery(['groups', { order: 'name' }], fetch, {
    enabled: isAdmin
  });
  const columns = [
    {
      dataField: 'id',
      text: 'Device',
      sort: true,
      formatter: dataField => (
        <Link className="text-700" to={`/devices/${dataField}`}>
          {dataField}
        </Link>
      ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.id?.iLike
      })
    },
    {
      dataField: 'relation.vehicle.vin',
      text: 'VIN',
      formatter: (dataField, entry) =>
        entry?.relation?.vehicle ? (
          <Link className="text-700 demo-blur" to={`/vehicles/${entry.relation.vehicle.id}`}>
            {dataField || <i>Unknown</i>}
          </Link>
        ) : (
          ''
        ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.vehicle.vin']?.iLike
      })
    },
    {
      dataField: 'imsi',
      text: 'IMSI',
      sort: true,
      formatter: dataField => <span className="demo-blur">{dataField}</span>,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.imsi?.iLike
      })
    },
    {
      dataField: 'relation.driver.lastName',
      text: 'Driver',
      formatter: (dataField, entry) =>
        entry?.relation?.driver ? (
          <Link className="text-700 demo-blur" to={`/drivers/${entry.relation.driver.id}`}>
            {formatUser(entry.relation.driver)}
          </Link>
        ) : (
          ''
        ),
      filter: textFilter({
        className: 'form-control-sm',
        placeholder: `Driver's lastname`,
        defaultValue: params?.filter?.['relation.driver.lastName']?.iLike
      })
    },
    {
      dataField: 'relation.groupId',
      text: 'Group',
      formatter: (dataField, entry) =>
        dataField ? <span className="demo-blur">{entry.relation.group.name}</span> : '',
      filter:
        isAdmin && !isEmpty(groups?.data)
          ? selectFilter({
              className: 'form-control-sm',
              options: map(groups?.data, group => ({ value: group.id, label: group.name })),
              defaultValue: params?.filter?.['relation.groupId']
            })
          : undefined
    },
    {
      dataField: 'relation.enabled',
      text: 'Status',
      formatter: (dataField, entry) => badgeFormatter(entry.enabled ? dataField : false)
    },
    {
      dataField: 'notes',
      text: 'Notes',
      formatter: dataField => <div style={{ maxWidth: 200 }}>{dataField}</div>
    },
    {
      formatter: (dataField, entry) => (
        <>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="eye"
            className="fs--1 text-600 px-2 m-1"
            iconClassName="fs--1"
            tag={Link}
            to={`/devices/${entry.id}`}
            title="View details"
          />
          {isAdmin && (
            <UncontrolledButtonDropdown>
              <DropdownToggle color="falcon-default" caret size="sm" className="m-1">
                <FontAwesomeIcon icon="ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setShowModal(entry)}>Edit device</DropdownItem>
                <DropdownItem
                  onClick={() => setDriverRelation(entry?.relation || { deviceId: entry.id })}
                  disabled={entry?.relation?.driverId}
                >
                  Assign driver
                </DropdownItem>
                <DropdownItem onClick={() => setGroupRelation(entry?.relation || { deviceId: entry.id })}>
                  Select group
                </DropdownItem>
                <DropdownItem disabled={!entry?.relation} onClick={() => setUnlinkRelation(entry?.relation)}>
                  Unlink device
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}
        </>
      ),
      align: 'right'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Devices" className="mb-4 mt-3 align-items-center" icon="plug" />
      <DeviceStats />
      {isAdmin && (
        <Flex justify="end" className="mb-3">
          <ButtonIcon icon="plus" color="falcon-primary" onClick={() => setShowModal(true)}>
            New
          </ButtonIcon>
        </Flex>
      )}
      <CardTable params={params} setParams={setParams} queryKey="devices" columns={columns} />
      <SelectGroupModal
        relation={groupRelation}
        onClose={newGroupId => {
          if (newGroupId !== false) {
            queryCache.invalidateQueries('devices');
          }
          setGroupRelation(null);
        }}
      />
      <AssignDriverModal
        relation={driverRelation}
        onClose={newGroupId => {
          if (newGroupId !== false) {
            queryCache.invalidateQueries('devices');
          }
          setDriverRelation(null);
        }}
      />
      <FormModal
        open={!!showModal}
        form={DeviceForm}
        title="Device"
        data={typeof showModal !== 'boolean' ? showModal : undefined}
        onClose={newDriver => {
          if (newDriver) {
            queryCache.invalidateQueries('devices');
          }
          setShowModal(false);
        }}
      />
      <UnlinkDeviceModal
        relation={unlinkRelation}
        onClose={newData => {
          if (newData !== false) {
            queryCache.invalidateQueries('devices');
          }
          setUnlinkRelation(null);
        }}
      />
    </>
  );
};

export default Devices;

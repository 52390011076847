import React from 'react';
import PropTypes from 'prop-types';
import { getGrays, getPosition, themeColors, rgbaColor, numberFormatter } from '../../helpers/utils';
import { Card, CardBody, Col, Row } from 'reactstrap';
import echarts from 'echarts/lib/echarts';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import Flex from '../common/Flex';
import Dot from '../common/Dot';
import FalconCardHeader from '../common/FalconCardHeader';

const getOption = data => {
  const grays = getGrays(false);
  return {
    color: data.map(d => d.color),
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: grays.white,
      textStyle: { color: grays.black },
      transitionDuration: 0,
      borderColor: grays['300'],
      borderWidth: 1,
      formatter: function(params) {
        return `${params.data.value} kWh`;
      }
    },
    position(pos, params, dom, rect, size) {
      return getPosition(pos, params, dom, rect, size);
    },
    legend: { show: false },
    series: [
      {
        type: 'pie',
        radius: ['100%', '87%'],
        avoidLabelOverlap: false,
        hoverAnimation: false,
        itemStyle: {
          borderWidth: 2,
          borderColor: '#fff'
        },
        labelLine: { normal: { show: false } },
        data: data
      }
    ]
  };
};

const EnergyCard = ({ className, type, diffEnergy, remainingEnergy }) => {
  const isRide = type === 'ride';
  const color = isRide ? themeColors.info : themeColors.success;
  const data = [
    { value: diffEnergy || 0, color: rgbaColor(color, 1) },
    { value: remainingEnergy || 0, color: rgbaColor(color, 0.5) }
  ];

  return (
    <Card className={className}>
      <FalconCardHeader className="pb-0" title="Energy" light={false} titleTag="h6" />
      <CardBody>
        <Row noGutters className="h-100 justify-content-between">
          <Col xs={9} className="col-xxl pr-2 align-self-center">
            <Flex justify="between" align="center" className="mb-1">
              <Flex align="center">
                <Dot style={{ backgroundColor: data[0].color }} />
                <span className="font-weight-semi-bold fs-2">
                  {diffEnergy === null ? 'n/a' : numberFormatter(data[0].value, 1)} kWh
                  {isRide && <span className="ml-2 font-weight-medium fs--1">consumed</span>}
                </span>
              </Flex>
            </Flex>
            <Flex justify="between" align="center" className="mb-1">
              <Flex align="center">
                <Dot style={{ backgroundColor: data[1].color }} />
                <span className="font-weight-semi-bold fs--2">
                  {remainingEnergy === null ? 'n/a' : numberFormatter(data[1].value, 1)} kWh remaining
                </span>
              </Flex>
            </Flex>
          </Col>
          <Col xs={3}>
            {remainingEnergy !== null && diffEnergy !== null && (
              <ReactEchartsCore
                echarts={echarts}
                option={getOption(data)}
                style={{ width: '100%', minHeight: '5rem', height: '100%' }}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

EnergyCard.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  diffEnergy: PropTypes.number,
  remainingEnergy: PropTypes.number
};

export default EnergyCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody } from 'reactstrap';
import { useQuery } from 'react-query';
import size from 'lodash/size';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getGrays, themeColors, getPosition } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import { useFetch } from '../../request';
import Loader from '../common/Loader';

const getOption = data => {
  const grays = getGrays(false);
  const labels = data.map(entry => entry.label || 'Undefined');
  const numbers = data.map(entry => entry.num);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(params) {
        if (!Array.isArray(params)) {
          params = [params];
        }
        const paramText = params
          .map(p => {
            return `<span class="chart-tooltip-point" style="background-color:${p.color};"></span> ${p.value} ${
              p.seriesName
            }`;
          })
          .join('<br />');
        return `${params[0].name}<br />${paramText}`;
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    yAxis: {
      type: 'category',
      data: labels,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    xAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.info,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: numbers,
        name: 'Chargings'
      }
    ],
    grid: {
      top: '5%',
      left: '0%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    }
  };
};

const ChargingProviderGraph = ({ className, queryKey, params, minHeight = '15rem', children }) => {
  const fetch = useFetch();
  const { data, isLoading, isFetching } = useQuery([queryKey, params], fetch, {
    enabled: !params || (params?.from && params?.to)
  });

  return (
    <Card className={className}>
      <FalconCardHeader title="EMPs" titleTag="h5">{children}</FalconCardHeader>
      <CardBody>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            {size(data?.data) > 0 ? (
              <ReactEchartsCore
                echarts={echarts}
                option={getOption(data?.data)}
                style={{ width: '100%', minHeight, height: '100%' }}
              />
            ) : (
              <Alert color="info" className="text-center mb-0">
                Could not find any data.
              </Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

ChargingProviderGraph.propTypes = {
  className: PropTypes.string,
  queryKey: PropTypes.string.isRequired,
  params: PropTypes.object,
};

export default ChargingProviderGraph;

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { useFetch } from '../request';
import { useQuery } from 'react-query';
import Loader from '../components/common/Loader';
import { Alert, Card, CardBody, Col, Row, Button } from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import ButtonIcon from '../components/common/ButtonIcon';
import UserForm from '../components/form/UserForm';
import { useStoreState } from '../stores/SettingsStore';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const UserDetails = ({ match }) => {
  const { id } = match.params;
  const [currentUser] = useStoreState('currentUser');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const fetch = useFetch();
  const { data: user, isLoading, isError, error, refetch } = useQuery(`users/${id}`, fetch, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: id
  });

  if (!id) {
    return <Redirect to={`/users`} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <Alert color="danger" className="text-center mt-4">
        An error occurred during the request. Status code {error.status}.
      </Alert>
    );
  }

  return (
    <>
      <WidgetsSectionTitle title="User" className="mb-4 mt-3 align-items-center" />

      <Card>
        <FalconCardHeader title="Details">
          {currentUser?.isAdmin && (
            <ButtonIcon
              color="falcon-default"
              size="sm"
              icon="pencil-alt"
              onClick={() => setIsEdit(true)}
              iconClassName="fs--2"
            >
              Edit
            </ButtonIcon>
          )}
        </FalconCardHeader>
        {isEdit ? (
          <CardBody>
            <UserForm
              data={user}
              onSubmit={() => {
                refetch();
                setIsEdit(false);
              }}
              onStateChange={isSubmitting => setIsSubmitting(isSubmitting)}
            />
            <hr className="mt-0" />
            <Row className="justify-content-center">
              <Col xs={6} md={5} lg={4}>
                <Button color="falcon-default" disabled={isSubmitting} onClick={() => setIsEdit(false)} block>
                  Cancel
                </Button>
              </Col>
              <Col xs={6} md={5} lg={4}>
                <Button color="falcon-primary" form="userUpdateForm" disabled={isSubmitting} type="submit" block>
                  Apply
                </Button>
              </Col>
            </Row>
          </CardBody>
        ) : (
          <CardBody>
            <Row className={classes.row}>
              <Col xs={6} className={classes.colLabel}>
                Firstname
              </Col>
              <Col>{user?.firstName || '–'}</Col>
            </Row>
            <Row className={classes.row}>
              <Col xs={6} className={classes.colLabel}>
                Surname
              </Col>
              <Col>{user?.lastName || '–'}</Col>
            </Row>
            <Row className={classes.row}>
              <Col xs={6} className={classes.colLabel}>
                E-Mail
              </Col>
              <Col>{user?.email || '–'}</Col>
            </Row>
            <Row className={classes.row}>
              <Col xs={6} className={classes.colLabel}>
                Phone
              </Col>
              <Col>{user?.phone || '–'}</Col>
            </Row>
            <Row className={classes.row}>
              <Col xs={6} className={classes.colLabel}>
                Group
              </Col>
              <Col>{user?.group?.name || '–'}</Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </>
  );
};

export default UserDetails;

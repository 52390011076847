import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { format } from 'date-fns';
import { formatNumber } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import CardTable from '../../components/CardTable';
import { useStoreState } from '../../stores/RequestStore';
import PageHeader from '../../components/common/PageHeader';

const columns = params => {
  return [
    {
      dataField: 'id',
      text: 'Device',
      formatter: dataField => (
        <Link className="text-700" to={`/devices/${dataField}`}>
          {dataField}
        </Link>
      ),
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.id?.iLike
      })
    },
    {
      dataField: 'relation.vehicle.vehicleModel.label',
      text: 'Model (user)'
    },
    {
      dataField: 'soc',
      text: 'SOC',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`
    },
    {
      dataField: 'remainingRange',
      text: 'Remaining Range',
      formatter: dataField => `${formatNumber(dataField) || '–'} km`
    },
    {
      dataField: 'soc2',
      text: 'max. Range',
      formatter: (dataField, entry) =>
        entry.remainingRange && entry.soc ? `${formatNumber((entry.remainingRange / entry.soc) * 100)} km` : '– km'
    },
    {
      dataField: 'remainingRange2',
      text: 'Range per 1%',
      formatter: (dataField, entry) =>
        entry.remainingRange && entry.soc ? `${formatNumber(entry.remainingRange / entry.soc)} km` : '– km'
    },
    {
      dataField: 'createdAt',
      text: 'Created at',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    }
  ];
};

const RangeCalculation = () => {
  const [params, setParams] = useStoreState('rangecalc', {
    page: 1,
    size: 20,
    order: '-createdAt'
  });

  return (
    <>
      <PageHeader title="Range Calculation" className="mb-3" />
      <CardTable
        params={params}
        setParams={setParams}
        queryKey="control-center/range-calculation"
        columns={columns(params)}
      />
    </>
  );
};

export default RangeCalculation;

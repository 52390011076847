import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import CardTable from '../CardTable';
import { useStoreState } from '../../stores/RequestStore';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Badge } from 'reactstrap';
import { formatNumber } from '../../helpers/utils';

export const badgeFormatter = status => {
  const color =
    status >= 500
      ? 'danger' // red
      : status >= 400
      ? 'warning' // yellow
      : status >= 300
      ? 'info' // cyan
      : status >= 200
      ? 'success' // green
      : 'default'; // no color
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule">
      {status}
    </Badge>
  );
};

const columns = params => [
  {
    dataField: 'createdAt',
    text: 'Requested at',
    formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
    sort: true
  },
  {
    dataField: 'method',
    text: 'Method'
  },
  {
    dataField: 'path',
    text: 'Path',
    formatter: dataField => (dataField.length > 100 ? dataField.substr(0, 100) + '...' : dataField),
    sort: true,
    filter: textFilter({
      className: 'form-control-sm',
      defaultValue: params?.filter?.path?.iLike
    })
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: badgeFormatter,
    sort: true,
    filter: textFilter({
      className: 'form-control-sm',
      defaultValue: params?.filter?.status?.iLike
    })
  },
  {
    dataField: 'responseTime',
    text: 'Resp. Time',
    formatter: dataField => formatNumber(dataField, 3),
    sort: true
  },
  {
    dataField: 'token',
    formatter: dataField => (dataField ? dataField.substr(0, 15) + '...' : ''),
    text: 'Token'
  }
];

const RequestLogs = ({ title, groupId, entries, defaultOrder, defaultFilter }) => {
  const queryKey = `groups/${groupId}/request-logs`;
  const [params, setParams] = useStoreState(queryKey, {
    page: 1,
    size: entries,
    filter: defaultFilter,
    order: defaultOrder
  });
  return (
    <CardTable title={title} params={params} setParams={setParams} queryKey={queryKey} columns={columns(params)} />
  );
};

RequestLogs.propTypes = {
  title: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  defaultFilter: PropTypes.object
};

RequestLogs.defaultProps = {
  defaultOrder: '-createdAt',
  title: 'Request Logs',
  entries: 20
};

export default RequestLogs;

import React, { useState } from 'react';
import CardTable from '../../components/CardTable';
import { Card, CardBody } from 'reactstrap';
import { formatNumber } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';

const StatsByModel = ({ className }) => {
  const [params, setParams] = useState({});

  const columns = [
    {
      dataField: 'modelLabel',
      text: 'Model'
    },
    {
      dataField: 'brandLabel',
      text: 'Brand'
    },
    {
      dataField: 'countVehicles',
      text: 'Vehicles'
    },
    {
      dataField: 'distance',
      formatter: dataField => `${formatNumber(dataField) || '–'} km`,
      text: 'avg. distance'
    },
    {
      dataField: 'consumption',
      formatter: (dataField, entry) => `${formatNumber((dataField / entry?.distance) * 100) || '–'} kWh`,
      text: 'avg. consumption per 100 km'
    },
    {
      dataField: 'rides',
      formatter: (dataField, entry) => `${formatNumber(dataField / entry?.chargings) || '–'}`,
      text: 'Rides:Chargings'
    },
    {
      dataField: 'socStart',
      formatter: (dataField, entry) =>
        dataField && entry.socEnd ? `${formatNumber(dataField)} → ${formatNumber(entry.socEnd)}` : '–',
      text: 'avg. Charging Zone'
    }
  ];

  return (
    <Card className={className}>
      <FalconCardHeader title="Driving" titleTag="h5" />
      <CardBody className="pb-1">
        <CardTable params={params} setParams={setParams} queryKey="special-reports/report-by-model" columns={columns} />
      </CardBody>
    </Card>
  );
};

export default StatsByModel;

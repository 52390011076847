import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useQuery } from 'react-query';
import { formatNumber, formatSeconds } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import { useFetch } from '../../request';
import defaultTo from 'lodash/defaultTo';
import QuantityGraph from './QuantityGraph';

const QuantityCard = ({ className, type, filter, from, to }) => {
  const fetch = useFetch();
  const { data, isSuccess } = useQuery(
    [
      `stats/summary/${type}`,
      {
        ...filter,
        from,
        to
      }
    ],
    fetch,
    {
      enabled: from && to
    }
  );

  return (
    <Card className={className}>
      <FalconCardHeader title={type === 'ride' ? 'Rides' : 'Chargings'} titleTag="h5" />
      <CardBody>
        <QuantityGraph type={type} to={to} from={from} filter={filter} />
        {isSuccess &&
          (type === 'ride' ? (
            <>
              <Row className="mt-3">
                <Col xs={12} lg={3} className="mb-3 mb-lg-0">
                  <div className="text-500 fs--1">Rides</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatNumber(data?.count, 0) || 0}
                  </div>
                </Col>
                <Col xs={6} lg={5}>
                  <div className="text-500 fs--1">Avg. duration</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatSeconds(defaultTo(data?.durationAvg, 0))}
                  </div>
                </Col>
                <Col xs={6} lg={4}>
                  <div className="text-500 fs--1">Avg. distance</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatNumber(data?.distance / data?.count) || '–'} km
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={6} lg={{ size: 5, offset: 3 }}>
                  <div className="text-500 fs--1">Total duration</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatSeconds(defaultTo(data?.duration, 0))}
                  </div>
                </Col>
                <Col xs={6} lg={4}>
                  <div className="text-500 fs--1">Total distance</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatNumber(data?.distance) || '–'} km
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="mt-3">
                <Col xs={12} lg={3} className="mb-3 mb-lg-0">
                  <div className="text-500 fs--1">Chargings</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatNumber(data?.count, 0) || 0}
                  </div>
                </Col>
                <Col xs={6} lg={5}>
                  <div className="text-500 fs--1">Avg. energy</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatNumber(data?.diffEnergy / data?.count) || '–'} kWh
                  </div>
                </Col>
                <Col xs={6} lg={4}>
                  <div className="text-500 fs--1">Avg. standing time</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatSeconds(defaultTo(data?.durationAvg, 0))}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={6} lg={{ size: 5, offset: 3 }}>
                  <div className="text-500 fs--1">Total energy</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatNumber(data?.diffEnergy) || '–'} kWh
                  </div>
                </Col>
                <Col xs={6} lg={4}>
                  <div className="text-500 fs--1">Total standing time</div>
                  <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                    {formatSeconds(defaultTo(data?.duration, 0))}
                  </div>
                </Col>
              </Row>
            </>
          ))}
      </CardBody>
    </Card>
  );
};

QuantityCard.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['ride', 'charge']).isRequired,
  filter: PropTypes.object,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default QuantityCard;

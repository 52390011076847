import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import PageHeaderSplit from '../common/PageHeaderSplit';
import CardTable from '../CardTable';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatAddress, formatSeconds } from '../../helpers/utils';
import { format } from 'date-fns';
import { selectFilter } from 'react-bootstrap-table2-filter';
import RideSummary from '../stats/RideSummary';
import ChargeSummary from '../stats/ChargeSummary';
import ChargeLocations from '../stats/ChargeLocations';
import { useStoreState } from '../../stores/RequestStore';
import { badgeFormatter } from '../../pages/Trips';

const columns = params => {
  return [
    {
      dataField: 'startTime',
      text: 'Start date',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      dataField: 'type',
      text: 'Type',
      formatter: dataField => (dataField === 'ride' ? 'Ride' : 'Charging'),
      filter: selectFilter({
        className: 'form-control-sm',
        options: [{ value: 'ride', label: 'Rides' }, { value: 'charge', label: 'Charges' }],
        defaultValue: params?.filter?.type
      })
    },
    {
      dataField: 'duration',
      text: 'Duration',
      formatter: dataField => formatSeconds(dataField || 0),
      filter: selectFilter({
        className: 'form-control-sm',
        options: [
          { value: '{"gte":3600}', label: '≥ 1 hour' },
          { value: '{"lt":3600}', label: '< 1 hour' },
          { value: '{"lt":1800}', label: '< 30 minutes' }
        ],
        defaultValue: params?.filter?.duration ? JSON.stringify(params?.filter?.duration) : undefined
      }),
      sort: true
    },
    {
      dataField: 'start',
      text: 'Start address',
      formatter: dataField => <span className="demo-blur">{formatAddress(dataField)}</span>
    },
    {
      dataField: 'hasGraph',
      text: 'Currents Chart',
      formatter: badgeFormatter,
      filter: selectFilter({
        placeholder: 'All',
        className: 'form-control-sm',
        options: [{ value: 'true', label: 'Success' }, { value: 'false', label: 'Missing' }],
        defaultValue: params?.filter?.hasGraph
      })
    },
    {
      formatter: (dataField, { id }) => (
        <Link to={`/activities/${id}`} className="fs--1 text-600 px-2">
          <FontAwesomeIcon icon="eye" className="fs--1" />
        </Link>
      ),
      align: 'right'
    }
  ];
};

const initStart = moment.utc().startOf('month');
const initEnd = moment.utc().endOf('month');

const TimeRangeDetails = ({ vehicle }) => {
  const [params, setParams] = useStoreState(`vehicle/${vehicle.id}`, {
    page: 1,
    size: 10,
    order: '-startTime',
    filter: {
      relationId: vehicle.relation.id,
      startTime: {
        gte: initStart.toISOString(),
        lte: initEnd.toISOString()
      }
    }
  });

  const [startDate, setStartDate] = useState(moment.utc(params.filter.startTime.gte));
  const [endDate, setEndDate] = useState(moment.utc(params.filter.startTime.lte));

  const submitDateFilter = e => {
    e.preventDefault();
    const filter = { ...params.filter };
    filter.startTime = {
      gte: startDate.toISOString(),
      lte: endDate.toISOString()
    };
    setParams({ ...params, filter });
  };

  return (
    <>
      <PageHeaderSplit title="Rides & Chargings" className="mb-3">
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <InputGroup className="flex-nowrap">
              <Datetime
                closeOnSelect
                isValidDate={current => current.isBefore(endDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={startDate}
                onChange={setStartDate}
                inputProps={{ className: 'form-control border-right-0 rounded-right-0' }}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>-</InputGroupText>
              </InputGroupAddon>
              <Datetime
                closeOnSelect
                isValidDate={current => current.isAfter(startDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={endDate}
                onChange={setEndDate}
                inputProps={{ className: 'form-control rounded-left-0' }}
                className="calendar-right"
              />
            </InputGroup>
          </FormGroup>
          <Button onClick={submitDateFilter} color="falcon-default">
            Apply
          </Button>
        </Form>
      </PageHeaderSplit>

      <CardTable params={params} setParams={setParams} queryKey="trips" columns={columns(params)} />

      <RideSummary
        className="mb-3"
        filter={{ vehicleId: vehicle.id }}
        from={params.filter.startTime.gte}
        to={params.filter.startTime.lte}
      />

      <Row className="mb-3 card-gutter-wrapper">
        <Col md={6} className="card-gutter">
          <ChargeSummary
            className="h-100"
            filter={{ vehicleId: vehicle.id }}
            from={params.filter.startTime.gte}
            to={params.filter.startTime.lte}
          />
        </Col>
        <Col md={6} className="card-gutter">
          <ChargeLocations
            className="h-100"
            filter={{ vehicleId: vehicle.id }}
            from={params.filter.startTime.gte}
            to={params.filter.startTime.lte}
          />
        </Col>
      </Row>
    </>
  );
};

TimeRangeDetails.propTypes = {
  vehicle: PropTypes.object.isRequired
};

export default TimeRangeDetails;

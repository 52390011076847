import React, { useState } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Card, CardBody, CustomInput } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import { getChartOptions, themeColors } from '../../helpers/utils';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import Flex from '../common/Flex';

const StateOfHealthGraph = ({ vehicle, className }) => {
  const fetch = useFetch();
  const [interval, setInterval] = useState('week');

  const { data, isLoading } = useQuery([`vehicles/${vehicle.id}/points/health`, { interval }], fetch, {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  let xAxisFormat, xAxisFormatPrefix;
  switch (interval) {
    case 'week':
      xAxisFormatPrefix = 'CW ';
      xAxisFormat = 'II';
      break;
    case 'month':
      xAxisFormat = 'MMM';
      break;
    default:
      xAxisFormat = undefined;
  }

  return (
    <Card className={className}>
      <FalconCardHeader title="State of Health" light titleTag="h5">
        <Flex>
          <CustomInput
            type="select"
            id="exampleCustomSelect"
            bsSize="sm"
            className="select-month mr-2"
            value={interval}
            onChange={({ target }) => setInterval(target.value)}
          >
            <option value="event">all</option>
            <option value="week">weekly</option>
            <option value="month">monthly</option>
          </CustomInput>
        </Flex>
      </FalconCardHeader>

      <CardBody>
        {isLoading ? (
          <Loader />
        ) : size(data?.data) > 0 ? (
          <ReactEchartsCore
            echarts={echarts}
            option={getChartOptions(data.data.map(d => [d.recordedTimestamp, d.value]), '%', themeColors.info, {
              xAxisFormat,
              xAxisFormatPrefix
            })}
            style={{ width: '100%', minHeight: '15rem', height: '100%' }}
          />
        ) : (
          <Alert color="danger" className="text-center mb-0">
            Could not find any data.
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

StateOfHealthGraph.propTypes = { className: PropTypes.string, vehicle: PropTypes.object.isRequired };

export default StateOfHealthGraph;

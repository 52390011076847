import React from 'react';
import { Link } from 'react-router-dom';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { formatUser } from '../helpers/utils';
import { useStoreState } from '../stores/RequestStore';
import ButtonIcon from '../components/common/ButtonIcon';
import { badgeFormatter } from './Devices';
import VehicleStats from '../components/vehicle/VehicleStats';
import { format } from 'date-fns';

const Vehicles = () => {
  const [params, setParams] = useStoreState('vehicles', {
    page: 1,
    size: 15,
    order: 'vin'
  });

  const columns = [
    {
      dataField: 'brandName',
      text: 'Brand',
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.brandName?.iLike
      })
    },
    {
      dataField: 'modelName',
      text: 'Model',
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.modelName?.iLike
      })
    },
    {
      dataField: 'batteryCapacity',
      text: 'Battery size',
      sort: true
    },
    {
      dataField: 'relation.driver',
      text: 'Driver',
      formatter: dataField =>
        dataField ? (
          <Link className="text-700 demo-blur" to={`/drivers/${dataField.id}`}>
            {formatUser(dataField)}
          </Link>
        ) : (
          ''
        )
    },
    {
      dataField: 'vin',
      text: 'VIN',
      sort: true,
      formatter: (dataField, entry) => (
        <Link className="text-700 demo-blur" to={`/vehicles/${entry.id}`}>
          {dataField || <i>Unknown</i>}
        </Link>
      ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.vin?.iLike
      })
    },
    {
      dataField: 'relation.deviceId',
      text: 'Device',
      formatter: dataField =>
        dataField ? (
          <Link className="text-700" to={`/devices/${dataField}`}>
            {dataField}
          </Link>
        ) : (
          ''
        ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.deviceId']?.iLike
      })
    },
    {
      dataField: 'relation.enabled',
      text: 'Connected',
      formatter: badgeFormatter,
      filter: selectFilter({
        placeholder: 'All',
        className: 'form-control-sm',
        options: [
          { value: 'true', label: 'Enabled' },
          { value: 'false', label: 'Disabled' },
          { value: '{"is":""}', label: 'Unlinked' }
        ],
        defaultValue: params?.filter?.['relation.enabled']
      })
    },
    {
      dataField: 'pluginDate',
      text: 'Plugged in date',
      formatter: dataField => dataField ? format(new Date(dataField), 'dd/MM/yyyy HH:mm') : '–'
    },
    {
      dataField: 'createdAt',
      text: 'Created at',
      sort: true,
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm')
    },
    {
      formatter: (dataField, entry) => (
        <>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="eye"
            className="fs--1 text-600 px-2 ml-2"
            iconClassName="fs--1"
            tag={Link}
            to={`/vehicles/${entry.id}`}
            title="View details"
          />
        </>
      ),
      align: 'right'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Vehicles" className="mb-4 mt-3 align-items-center" icon="car" />
      <VehicleStats />
      <CardTable params={params} setParams={setParams} queryKey="vehicles" columns={columns} />
    </>
  );
};

export default Vehicles;

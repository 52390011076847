import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import map from 'lodash/map';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Label,
  Col,
  Input,
  FormFeedback,
  FormGroup,
  Alert
} from 'reactstrap';
import { normalizeError, useFetch, usePostOrPatch } from '../request';
import Loader from './common/Loader';
import { useStoreState } from '../stores/SettingsStore';
import { convertValues, formatUser } from '../helpers/utils';

const AssignDriverModal = ({ relation, onClose }) => {
  const [{ isAdmin = false }] = useStoreState('currentUser');
  const { control, handleSubmit, errors, setError } = useForm();
  const patch = usePostOrPatch(['relations', relation?.id], { throwOnError: true });
  const fetch = useFetch();
  const { data: drivers, isLoading, isError, error } = useQuery(
    ['drivers', { order: 'lastName,firstName,email' }],
    fetch,
    {
      enabled: isAdmin
    }
  );

  const update = async data => {
    try {
      const newData = await patch.send(convertValues(data));
      onClose(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
  };

  return (
    <Modal isOpen={!!relation} keyboard={false} centered>
      <ModalHeader>Assign Driver</ModalHeader>
      <ModalBody tag={Form} onSubmit={handleSubmit(update)} id="assignDriverModalForm">
        {isError && <Alert color="danger">{error?.data?.message || error?.message || 'An error occurred.'}</Alert>}
        {patch.isError && (
          <Alert color="danger">{patch.error?.data?.message || patch.error?.message || 'An error occurred.'}</Alert>
        )}
        {patch.isPost && relation?.deviceId && (
          <Controller
            as={<input type="hidden" />}
            control={control}
            name="deviceId"
            defaultValue={relation.deviceId + ''}
          />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <FormGroup row className="my-3">
            <Label xs={6} md={4} className="text-500">
              Driver
            </Label>
            <Col>
              <Controller
                as={
                  <Input type="select" invalid={!!errors?.driverId}>
                    <option value="">Please select</option>
                    {map(drivers?.data, driver => (
                      <option key={driver.id} value={driver.id}>
                        {formatUser(driver)}
                      </option>
                    ))}
                  </Input>
                }
                control={control}
                name="driverId"
                rules={{ required: 'Required' }}
                defaultValue={relation?.driverId || ''}
              />
              <FormFeedback>{errors?.driverId?.message}</FormFeedback>
            </Col>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button outline color="falcon-default" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button color="falcon-primary" type="submit" form="assignDriverModalForm">
          Assign driver
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AssignDriverModal.propTypes = {
  relation: PropTypes.object,
  onClose: PropTypes.func
};

export default AssignDriverModal;

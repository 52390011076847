import React from 'react';
import { format } from 'date-fns';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getGrays, themeColors, getPosition, formatNumber } from '../../helpers/utils';

const getOption = (data, from, to) => {
  const grays = getGrays(false);
  const dataPos = data.map(entry => [entry.recordedTimestamp, entry.value >= 0 ? entry.value : '-']);
  const dataNeg = data.map(entry => [entry.recordedTimestamp, entry.value < 0 ? entry.value : '-']);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(entry) {
        const value = entry[0].value[1] === '-' ? entry[1].value[1] : entry[0].value[1];
        return format(entry[0].axisValue, 'dd/MM HH:mm') + ' ' + formatNumber(value) + 'kWh';
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'time',
      // splitNumber: 4,
      // interval: 300 * 1000,
      // minInterval: 600 * 1000,
      // maxInterval: 600 * 1000,
      // data: labels,
      min: from,
      max: to,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return format(new Date(value), 'dd/MM');
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        formatter: `{value} kWh`,
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        stack: 'colorbyvalue',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.success,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: dataPos
      },
      {
        type: 'bar',
        stack: 'colorbyvalue',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.info,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: dataNeg
      }
    ],
    grid: { right: '28px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const CurrentsByTime = ({ data, from, to }) => {
  return (
    <ReactEchartsCore
      echarts={echarts}
      option={getOption(data, from, to)}
      style={{ width: '100%', minHeight: '15rem', height: '100%' }}
    />
  );
};

export default CurrentsByTime;

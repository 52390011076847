import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { format } from 'date-fns';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const GeneralCard = ({ className, user }) => {
  return (
    <Card className={className}>
      <FalconCardHeader title="General" titleTag="h5" />
      <CardBody>
        <Row className={classes.row}>
          <Col xs={6} className={classes.colLabel}>
            Account created
          </Col>
          <Col xs={6}>{format(new Date(user.createdAt), 'dd/MM/yyyy HH:mm')}</Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={6} className={classes.colLabel}>
            In-app star rating
          </Col>
          <Col xs={6}>{user?.rating || '–'}</Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={6} className={classes.colLabel}>
            App OS
          </Col>
          <Col xs={6}>{user?.appOs || '–'}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

GeneralCard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object
};

export default GeneralCard;

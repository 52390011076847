import React from 'react';
// import { Link } from 'react-router-dom';
import LoginForm from '../components/auth/LoginForm';
import AuthCardLayout from '../layouts/AuthCardLayout';

const Login = () => {

  return (
    <AuthCardLayout
      // leftSideContent={
      //   <>
      //     <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
      //       Read our{' '}
      //       <Link className="text-underline text-300" to="#!">
      //         terms
      //       </Link>{' '}
      //       and{' '}
      //       <Link className="text-underline text-300" to="#!">
      //         conditions{' '}
      //       </Link>
      //     </p>
      //   </>
      // }
    >
      <h3>Account Login</h3>
      <LoginForm />
    </AuthCardLayout>
  );
};

export default Login;

import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { format } from 'date-fns';
import { formatNumber } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CardTable from '../../components/CardTable';
import { useStoreState } from '../../stores/RequestStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeaderWithTimeRange from '../../components/common/PageHeaderWithTimeRange';

const columns = params => {
  return [
    {
      dataField: 'relation.deviceId',
      text: 'Device',
      formatter: dataField =>
        dataField ? (
          <Link className="text-700" to={`/devices/${dataField}`}>
            {dataField}
          </Link>
        ) : (
          ''
        ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.deviceId']?.iLike
      })
    },
    {
      dataField: 'minSoc',
      text: 'Lowest SOC',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`
    },
    {
      dataField: 'maxSoc',
      text: 'Highest SOC',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`
    },
    {
      dataField: 'createdAt',
      text: 'Created at',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      formatter: (dataField, { id }) => (
        <Link to={`/vehicles/${id}`} className="fs--1 text-600 px-2">
          <FontAwesomeIcon icon="eye" className="fs--1" />
        </Link>
      ),
      align: 'right'
    }
  ];
};

const initStart = moment.utc().subtract(1, 'month');
const initEnd = moment.utc();

const SocRange = () => {
  const [params, setParams] = useStoreState('socrange', {
    page: 1,
    size: 30,
    order: '-createdAt',
    startRange: initStart.toISOString(),
    endRange: initEnd.toISOString()
  });

  const submitDateFilter = ({ startDate, endDate }) => {
    setParams({ ...params, startRange: startDate, endRange: endDate });
  };

  return (
    <>
      <PageHeaderWithTimeRange
        title="SOC Range"
        defaultStartDate={params.filter?.createdAt?.gte}
        defaultEndDate={params.filter?.createdAt?.lte}
        onSubmit={submitDateFilter}
      />
      <CardTable params={params} setParams={setParams} queryKey="control-center/soc-range" columns={columns(params)} />
    </>
  );
};

export default SocRange;

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Alert } from 'reactstrap';
import { useQuery } from 'react-query';
import { formatNumber, getGrays, getPosition, themeColors } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import { useFetch } from '../../request';
import size from 'lodash/size';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import Loader from '../common/Loader';

const days = ['', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'So'];

const getOption = (data, color) => {
  const grays = getGrays(false);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(entry) {
        return days[entry[0].axisValue] + ': ' + formatNumber(entry[0].value[1]);
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'value',
      // splitNumber: 4,
      // interval: 300 * 1000,
      // minInterval: 600 * 1000,
      // maxInterval: 600 * 1000,
      // data: labels,
      // boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return days[value];
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      allowDecimals: false,
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        stack: 'one',
        barMaxWidth: 20,
        itemStyle: {
          color,
          borderWidth: 0
        },
        hoverAnimation: true,
        data
      }
    ],
    grid: {
      top: '5%',
      left: '0%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    }
  };
};

const ActivitiesPerWeekday = ({ className, type, from, to }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(
    [
      `special-reports/stats-weekday/${type}`,
      {
        from,
        to
      }
    ],
    fetch,
    {
      enabled: from && to
    }
  );

  return (
    <Card className={className}>
      <FalconCardHeader title={`${type === 'ride' ? 'Rides' : 'Chargings'} weekly`} titleTag="h5" />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : size(data?.data) > 0 ? (
          <ReactEchartsCore
            echarts={echarts}
            option={getOption(
              data.data.map(d => [d.dow, d.value]),
              type === 'ride' ? themeColors.info : themeColors.success
            )}
            style={{ width: '100%', height: '15rem' }}
          />
        ) : (
          <Alert color="info" className="text-center mb-0">
            Could not find any data.
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

ActivitiesPerWeekday.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['ride', 'charge']).isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default ActivitiesPerWeekday;

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import { AuthContext } from './context/auth';
import AppContext from './context/app';
import PrivateRoute from './PrivateRoute';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import DashboardLayout from './layouts/DashboardLayout';
import { settings, reactQuery } from './config';
import { getItemFromStore, setItemToStore } from './helpers/utils';
import { useStoreState } from './stores/SettingsStore';

// import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
// import 'react-image-lightbox/style.css';

const App = () => {
  const [, setDemoMode] = useStoreState('demoMode');
  let existingTokens, demoMode;
  try {
    existingTokens = getItemFromStore('tokens');
    demoMode = getItemFromStore('demoMode');
    if (demoMode) {
      setDemoMode(demoMode);
    }
  } catch (e) {}
  const [authToken, setAuthToken] = useState(existingTokens);
  const setTokens = data => {
    setItemToStore('tokens', data);
    setAuthToken(data);
  };

  return (
    <ReactQueryConfigProvider config={reactQuery}>
      <AppContext.Provider value={settings}>
        <AuthContext.Provider value={{ authToken, setAuthToken: setTokens }}>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forget-password" component={ForgetPassword} />
              <PrivateRoute component={DashboardLayout} />
            </Switch>
          </Router>
        </AuthContext.Provider>
      </AppContext.Provider>
    </ReactQueryConfigProvider>
  );
};

export default App;

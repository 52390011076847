import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody, Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { useQuery } from 'react-query';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/scatter';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import Loader from '../common/Loader';
import { useFetch } from '../../request';
import { formatNumber, getGrays, getPosition, themeColors } from '../../helpers/utils';

const getOption = data => {
  const grays = getGrays(false);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'item',
      padding: [7, 10],
      formatter: function(entry) {
        return entry.data[0] + 'kWh - ' + formatNumber(entry.data[1]) + 'kWh';
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'value',
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      allowDecimals: false,
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        symbolSize: 10,
        data,
        type: 'scatter'
      }
    ],
    grid: {
      top: '5%',
      left: '0%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    }
  };
};

const BatterySizeChargedSum = ({ className }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery('special-reports/battery-charged', fetch);

  return (
    <Card className={className}>
      <FalconCardHeader title={`Battery Size and Charged Energy`} titleTag="h5">
        <Button color="falcon-default" size="sm" id="bscePopover" iconClassName="fs--2">
          SQL
        </Button>
        <UncontrolledPopover placement="left" target="bscePopover">
          <PopoverHeader>SQL</PopoverHeader>
          <PopoverBody>
            <pre>
              SELECT battery_capacity as "batteryCapacity", charged_energy as "chargedEnergy" FROM ( SELECT
              battery_capacity, ( SELECT SUM(t.diff_energy) FROM trips t WHERE t.type = 'charge' and t.relation_id =
              r.id and t.diff_energy IS NOT NULL ) as charged_energy FROM vehicles v LEFT JOIN relations r on v.id =
              r.vehicle_id and r.enabled = true WHERE v.battery_capacity IS NOT NULL and vin IS NOT NULL ) as sub WHERE
              charged_energy IS NOT NULL ORDER BY sub.battery_capacity
            </pre>
          </PopoverBody>
        </UncontrolledPopover>
      </FalconCardHeader>
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <ReactEchartsCore
            echarts={echarts}
            option={getOption(data.data.map(d => [d.batteryCapacity, d.chargedEnergy]))}
            style={{ width: '100%', height: '15rem' }}
          />
        )}
      </CardBody>
    </Card>
  );
};

BatterySizeChargedSum.propTypes = {
  className: PropTypes.string,
  useRatio: PropTypes.bool
};

export default BatterySizeChargedSum;

import React, { useState } from 'react';
import moment from 'moment';
import { getChartOptions, themeColors } from '../../helpers/utils';
import { useStoreState } from '../../stores/RequestStore';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import Datetime from 'react-datetime';
import PageHeaderSplit from '../../components/common/PageHeaderSplit';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import Loader from '../../components/common/Loader';
import size from 'lodash/size';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import { useFetch } from '../../request';
import SocDiffBarChartByTime from '../../components/control-center/SocDiffBarChartByTime';
import ConsumptionByTime from '../../components/control-center/ConsumptionByTime';
import CurrentsByTime from "../../components/control-center/CurrentsByTime";

const units = {
  HV_BATTERY_SOC: '%',
  HV_BATTERY_SOH: '%',
  HV_BATTERY_VOLTAGE: 'V',
  HV_BATTERY_CHARGING_CURRENT: 'kW',
  HV_BATTERY_TOTAL_CAPACITY: 'kWh',
  HV_BATTERY_REMAINING_RANGE: 'km',
  HV_BATTERY_TEMPERATURE: '°C',
  HV_BATTERY_REMAINING_ENERGY: 'kWh',
  GPS_SPEED: 'km/h',
  GPS_COURSE: '°',
  MILEAGE: 'km'
};

const EventChartItem = ({ params }) => {
  const [eventType, setEventType] = useState('');
  const fetch = useFetch();
  const { data, isLoading } = useQuery(['control-center/event-points', { ...params, event: eventType }], fetch, {
    enabled: !!eventType
  });

  return (
    <Card className="mt-3">
      <FalconCardHeader
        className={!eventType ? 'rounded-bottom' : null}
        title={
          <CustomInput
            type="select"
            id="exampleCustomSelect"
            bsSize="sm"
            className="w-auto mr-2"
            value={eventType}
            onChange={({ target }) => setEventType(target.value)}
          >
            <option value="">Select Event</option>
            <option value="HV_BATTERY_SOC">HV_BATTERY_SOC</option>
            <option value="HV_BATTERY_SOH">HV_BATTERY_SOH</option>
            <option value="HV_BATTERY_VOLTAGE">HV_BATTERY_VOLTAGE</option>
            <option value="HV_BATTERY_CHARGING_CURRENT">HV_BATTERY_CHARGING_CURRENT</option>
            <option value="HV_BATTERY_TOTAL_CAPACITY">HV_BATTERY_TOTAL_CAPACITY</option>
            <option value="HV_BATTERY_REMAINING_RANGE">HV_BATTERY_REMAINING_RANGE</option>
            <option value="HV_BATTERY_TEMPERATURE">HV_BATTERY_TEMPERATURE</option>
            <option value="HV_BATTERY_REMAINING_ENERGY">HV_BATTERY_REMAINING_ENERGY</option>
            <option value="GPS_SPEED">GPS_SPEED</option>
            <option value="GPS_COURSE">GPS_COURSE</option>
            <option value="MILEAGE">MILEAGE</option>
            <option value="consumption">Consumption per 100 km</option>
            <option value="chargings">Chargings</option>
          </CustomInput>
        }
        light
        titleTag="div"
      />

      {eventType && (
        <CardBody>
          {isLoading ? (
            <Loader />
          ) : size(data?.data) > 0 ? (
            eventType === 'consumption' ? (
              <ConsumptionByTime data={data?.data} from={params.from} to={params.to} />
            ) : eventType === 'chargings' ? (
              <SocDiffBarChartByTime data={data?.data} from={params.from} to={params.to} />
            ) : eventType === 'HV_BATTERY_CHARGING_CURRENT' ? (
              <CurrentsByTime data={data?.data} from={params.from} to={params.to} />
            ) : (
              <ReactEchartsCore
                echarts={echarts}
                option={getChartOptions(
                  data.data.map(d => [d.recordedTimestamp, d.value]),
                  units[eventType],
                  themeColors.info,
                  {
                    tooltipFormat: 'dd/MM HH:mm',
                    xAxisFormat: 'dd/MM',
                    xAxisOptions: {
                      min: params.from,
                      max: params.to
                    }
                  }
                )}
                style={{ width: '100%', minHeight: '15rem', height: '100%' }}
              />
            )
          ) : (
            <Alert color="info" className="text-center mb-0">
              Could not find any data.
            </Alert>
          )}
        </CardBody>
      )}
    </Card>
  );
};

const initStart = moment.utc().subtract(1, 'month').startOf('day');
const initEnd = moment().endOf('day');

const EventCharts = () => {
  const [params, setParams] = useStoreState('eventcharts', {
    from: initStart.toISOString(),
    to: initEnd.toISOString()
  });
  const { control, handleSubmit, errors } = useForm();

  const update = data => {
    setParams({
      ...params,
      deviceId: data.deviceId,
      from: moment.utc(data.startDate).startOf('day').toISOString(),
      to: moment.utc(data.endDate).endOf('day').toISOString()
    });
  };

  return (
    <>
      <PageHeaderSplit title="Event Charts" className="mb-3">
        <Form inline onSubmit={handleSubmit(update)} id="eventChartSubmit">
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Controller
              as={<Input invalid={!!errors?.deviceId} placeholder="Device ID" />}
              control={control}
              name="deviceId"
              rules={{ required: 'Required' }}
              defaultValue={params?.deviceId || ''}
            />
          </FormGroup>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <InputGroup className="flex-nowrap">
              <Controller
                as={
                  <Datetime
                    closeOnSelect
                    // isValidDate={current => current.isBefore(endDate)}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    inputProps={{ className: 'form-control border-right-0 rounded-right-0' }}
                  />
                }
                control={control}
                name="startDate"
                rules={{ required: 'Required' }}
                defaultValue={params?.from ? new Date(params?.from) : null}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>-</InputGroupText>
              </InputGroupAddon>
              <Controller
                as={
                  <Datetime
                    closeOnSelect
                    // isValidDate={current => current.isBefore(endDate)}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    inputProps={{ className: 'form-control rounded-left-0' }}
                    className="calendar-right"
                  />
                }
                control={control}
                name="endDate"
                rules={{ required: 'Required' }}
                defaultValue={params?.to ? new Date(params?.to) : null}
              />
            </InputGroup>
          </FormGroup>
          <Button type="submit" form="eventChartSubmit" color="falcon-default">
            Apply
          </Button>
        </Form>
      </PageHeaderSplit>

      {params?.deviceId && (
        <>
          <EventChartItem params={params} />
          <EventChartItem params={params} />
          <EventChartItem params={params} />
        </>
      )}
    </>
  );
};

export default EventCharts;

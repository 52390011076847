import React from 'react';
import { Link } from 'react-router-dom';
import { useQueryCache } from 'react-query';
import { CustomInput } from 'reactstrap';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import ButtonIcon from '../components/common/ButtonIcon';
import { useStoreState } from '../stores/RequestStore';
import { textFilter } from 'react-bootstrap-table2-filter';
import { usePatch } from '../request';

const PositionSettings = () => {
  const [params, setParams] = useStoreState('devices', {
    page: 1,
    size: 15,
    order: 'id',
    filter: {
      'relation.group.tag': 'arge'
    }
  });
  const patch = usePatch('devices', { throwOnError: true });
  const queryCache = useQueryCache();
  const updateSetting = async (id, status) => {
    const newData = await patch.send({ status }, `${id}/hide-position`);
    if (!newData.isError) {
      queryCache.invalidateQueries('devices');
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Device',
      sort: true,
      formatter: dataField => (
        <Link className="text-700" to={`/devices/${dataField}`}>
          {dataField}
        </Link>
      ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.id?.iLike
      })
    },
    {
      dataField: 'relation.vehicle.vin',
      text: 'VIN',
      formatter: (dataField, entry) =>
        entry?.relation?.vehicle ? (
          <Link className="text-700 demo-blur" to={`/vehicles/${entry.relation.vehicle.id}`}>
            {dataField || <i>Unknown</i>}
          </Link>
        ) : (
          ''
        ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.vehicle.vin']?.iLike
      })
    },
    {
      dataField: 'hidePositionData',
      text: 'Show GPS',
      formatter: (dataField, entry) => (
        <>
          <CustomInput
            type="switch"
            id={`${entry.id}-hide`}
            name="hidePositionData"
            checked={!dataField}
            value="1"
            onChange={() => updateSetting(entry.id, !dataField)}
          />
        </>
      )
    },
    {
      formatter: (dataField, entry) => (
        <>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="eye"
            className="fs--1 text-600 px-2 m-1"
            iconClassName="fs--1"
            tag={Link}
            to={`/devices/${entry.id}`}
            title="View details"
          />
        </>
      ),
      align: 'right'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Position Settings" className="mb-4 mt-3 align-items-center" icon="crosshairs" />
      <CardTable params={params} setParams={setParams} queryKey="devices" columns={columns} />
    </>
  );
};

export default PositionSettings;

import React, { useState } from 'react';
import { format } from 'date-fns';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { Link } from 'react-router-dom';
import { formatUser } from '../helpers/utils';

const Feedback = () => {
  const [params, setParams] = useState({
    page: 1,
    size: 30,
    order: '-createdAt'
  });

  const columns = [
    {
      dataField: 'createdAt',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      text: 'Created at'
    },
    {
      dataField: 'createdById',
      text: 'Driver',
      formatter: (dataField, entry) =>
        dataField ? (
          <Link className="text-700 demo-blur" to={`/drivers/${dataField}`}>
            {formatUser(entry?.createdBy)}
          </Link>
        ) : (
          ''
        )
    },
    {
      dataField: 'message',
      text: 'Message'
    },
    {
      dataField: 'os',
      text: 'OS'
    },
    {
      dataField: 'version',
      text: 'Version'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Feedback" className="mb-4 mt-3 align-items-center" />
      <CardTable params={params} setParams={setParams} queryKey="feedback" columns={columns} />
    </>
  );
};

export default Feedback;

import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { normalizeError, useFetch, usePostOrPatch } from '../../request';
import { convertValues } from '../../helpers/utils';
import RadioGroup from './RadioGroup';
import { useStoreState } from '../../stores/SettingsStore';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const DeviceForm = ({ className, formId, data: device, onSubmit, onStateChange }) => {
  const { control, handleSubmit, errors, setError } = useForm();
  const [{ isAdmin = false }] = useStoreState('currentUser');
  const post = usePostOrPatch(['devices', device?.id], { throwOnError: true });
  const fetch = useFetch();
  const { data: groups } = useQuery(['groups', { order: 'name' }], fetch, {
    enabled: isAdmin
  });

  const update = async data => {
    onStateChange && onStateChange(true);
    try {
      const newData = await post.send(convertValues(data));
      onSubmit(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
    onStateChange && onStateChange(false);
  };

  return (
    <Form className={className} onSubmit={handleSubmit(update)} id={formId}>
      {post.isError && !post.error?.data?.errorList && (
        <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
      )}
      <FormGroup row className={classes.row}>
        <Label xs={4} className={classes.colLabel}>
          ID *
        </Label>
        <Col>
          <Controller
            as={<Input type="number" disabled={post.isPatch} invalid={!!errors?.id} />}
            control={control}
            name="id"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(device?.id, '')}
          />
          <FormFeedback>{errors?.id?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={4} className={classes.colLabel}>
          Enabled
        </Label>
        <Col className="py-1">
          <Controller
            as={
              <RadioGroup
                invalid={!!errors?.enabled}
                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
              />
            }
            name="enabled"
            control={control}
            defaultValue={isNil(device?.enabled) ? 'true' : device.enabled.toString()}
          />
          <FormFeedback>{errors?.enabled?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={4} className={classes.colLabel}>
          IMSI
        </Label>
        <Col>
          <Controller
            as={<Input type="number" invalid={!!errors?.imsi} />}
            control={control}
            name="imsi"
            defaultValue={defaultTo(device?.imsi, '')}
          />
          <FormFeedback>{errors?.imsi?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={4} className={classes.colLabel}>
          Hardware
        </Label>
        <Col>
          <Controller
            as={<Input />}
            control={control}
            name="hardware"
            defaultValue={defaultTo(device?.hardware, 'EX2')}
          />
          <FormFeedback>{errors?.hardware?.message}</FormFeedback>
        </Col>
      </FormGroup>
      {post.isPost && (
        <FormGroup row className={classes.row}>
          <Label xs={4} className={classes.colLabel}>
            Group
          </Label>
          <Col>
            <Controller
              as={
                <Input type="select">
                  <option value="">Without group relation</option>
                  {map(groups?.data, group => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Input>
              }
              control={control}
              name="groupId"
              defaultValue=""
            />
            <FormFeedback>{errors?.groupId?.message}</FormFeedback>
          </Col>
        </FormGroup>
      )}
      <FormGroup row className={classes.row}>
        <Label xs={4} className={classes.colLabel}>
          Notes
        </Label>
        <Col>
          <Controller
            as={<Input type="textarea" rows={3} />}
            control={control}
            name="notes"
            defaultValue={defaultTo(device?.notes, '')}
          />
          <FormFeedback>{errors?.notes?.message}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
};

DeviceForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onStateChange: PropTypes.func
};

DeviceForm.defaultProps = { formId: 'deviceUpdateForm' };

export default DeviceForm;

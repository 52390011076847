import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import SocCard from './SocCard';
import EnergyCard from './EnergyCard';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Badge, Card, CardBody, Col, Row } from 'reactstrap';
import Flex from '../common/Flex';
import { numberFormatter, formatAddress, formatSeconds } from '../../helpers/utils';
import WeatherCard from './WeatherCard';
import SocGraph from './SocGraph';
import CurrentsGraph from './CurrentsGraph';
import RemainingEnergyGraph from './RemainingEnergyGraph';
import SpeedGraph from './SpeedGraph';
import TemperatureGraph from './TemperatureGraph';
import VoltageGraph from './VoltageGraph';
import LogsCard from './LogsCard';
import DriverCard from './DriverCard';
import ScoreCard from './ScoreCard';
import { useStoreState } from '../../stores/SettingsStore';
import AltitudeGraph from './AltitudeGraph';
import GpsCourseGraph from './GpsCourseGraph';
import VehicleCard from './VehicleCard';

const DetailsRide = ({ trip }) => {
  const [{ isAdmin = false }] = useStoreState('currentUser');
  const [isDemoMode] = useStoreState('demoMode');
  return (
    <>
      <Row className="card-gutter-wrapper align-items-stretch">
        <Col md={6} className="card-gutter mb-3">
          <Card className="h-100">
            <FalconCardHeader
              className="pb-0"
              title={format(new Date(trip.startTime), 'MMMM, dd, yyyy')}
              light={false}
              titleTag="h6"
            />
            <CardBody tag={Flex} column>
              {!trip.endTime ? (
                <Row>
                  <Col xs="auto">{format(new Date(trip.startTime), 'HH:mm')}</Col>
                  <Col>
                    <Badge color="soft-success" className="rounded-capsule">
                      currently active
                    </Badge>
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    <Col xs="auto">{format(new Date(trip.startTime), 'HH:mm')}</Col>
                    <Col className="demo-blur">{formatAddress(trip.start)}</Col>
                  </Row>
                  <Row className="mt-1">
                    <Col xs="auto">{format(new Date(trip.endTime), 'HH:mm')}</Col>
                    <Col className="demo-blur">{formatAddress(trip.end)}</Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="fs--1 text-600">
                      <span className="mr-3">{formatSeconds(trip.duration)}</span>
                      <span>{numberFormatter(trip.distance, 1)} km</span>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <VehicleCard deviceId={trip.deviceId} vehicle={trip?.relation?.vehicle} className="h-100" />
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <DriverCard driver={trip?.relation?.driver} className="h-100" />
        </Col>
      </Row>

      {!trip.endTime ? (
        <Alert color="info" className="text-center">
          Vehicle is currently driving.
        </Alert>
      ) : (
        <Row className="row-cols-1 row-cols-md-2 card-gutter-wrapper align-items-stretch">
          <Col className="card-gutter">
            <Row className="row-cols-1 row-cols-sm-2 card-gutter-wrapper align-items-stretch">
              <Col className="mb-3 card-gutter">
                <ScoreCard data={trip} />
              </Col>
              <Col className="mb-3 card-gutter" tag={Flex} column>
                <SocCard className="flex-sm-grow-1 mb-2" begin={trip.socStart} end={trip.socEnd} />
                <Card className="flex-sm-grow-1 mt-2">
                  <FalconCardHeader className="pb-0" title="Consumption / 100 km" light={false} titleTag="h6" />
                  <CardBody tag={Flex} align="center">
                    <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif">
                      {trip.diffEnergy && trip.distance
                        ? numberFormatter((trip.diffEnergy / trip.distance) * 100, 2)
                        : 'n/a'}{' '}
                      kWh
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className="card-gutter">
            <Row className="row-cols-1 row-cols-sm-2 card-gutter-wrapper align-items-stretch h-100">
              <Col className="mb-3 card-gutter" tag={Flex} column>
                <WeatherCard className="flex-sm-grow-1 mb-2" trip={trip} />
                <EnergyCard
                  className="flex-sm-grow-1 mt-2"
                  type="ride"
                  diffEnergy={trip.diffEnergy}
                  remainingEnergy={trip.remainingEnergy}
                />
              </Col>
              <Col className="mb-3 card-gutter" tag={Flex} column />
            </Row>
          </Col>
        </Row>
      )}

      <SocGraph trip={trip} className="mb-3" />
      <RemainingEnergyGraph trip={trip} className="mb-3" />
      <CurrentsGraph queryKey={`trips/${trip.id}`} className="mb-3" />
      <SpeedGraph trip={trip} className="mb-3" />
      <AltitudeGraph trip={trip} className="mb-3" />
      <GpsCourseGraph trip={trip} className="mb-3" />
      <TemperatureGraph trip={trip} className="mb-3" />
      <VoltageGraph trip={trip} className="mb-3" />

      {isAdmin && !isDemoMode && <LogsCard queryKey={`trips/${trip.id}/logs`} />}
    </>
  );
};

DetailsRide.propTypes = {
  trip: PropTypes.object
};

export default DetailsRide;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, CardBody, Col, Row } from 'reactstrap';

const PageHeader = ({ title, titleTag: TitleTag, description, col, children, ...rest }) => (
  <Card {...rest}>
    <CardBody>
      <Row>
        <Col {...col}>
          <TitleTag className="mb-0">{title}</TitleTag>
          {description && (
            <p
              className={classNames('mt-2', { 'mb-0': !children })}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {children}
        </Col>
      </Row>
    </CardBody>
  </Card>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  className: PropTypes.string,
  children: PropTypes.node
};

PageHeader.defaultProps = { col: { lg: 8 }, titleTag: 'h3' };

export default PageHeader;

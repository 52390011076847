import React from 'react';
import { format } from 'date-fns';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getGrays, themeColors, getPosition, formatNumber } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import {Button, Card, CardBody, PopoverBody, PopoverHeader, UncontrolledPopover} from 'reactstrap';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';

const getOption = data => {
  const grays = getGrays(false);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(entry) {
        return format(new Date(entry[0].axisValue), 'dd/MM') + ' ' + formatNumber(entry[0].value[1]);
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'time',
      // type: 'category',
      // data: labels,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return format(new Date(value), 'dd/MM');
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      max: 100,
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        color: grays['400'],
        margin: 15,
        formatter: `{value} kWh`
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        stack: 'one',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.info,
          borderWidth: 0
        },
        hoverAnimation: true,
        data
      }
    ],
    grid: { right: '28px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const ActiveDriver = ({ className }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery('special-reports/active-driver', fetch);

  return (
    <Card className={className}>
      <FalconCardHeader title="Active Driver per Month" titleTag="h5" children={
        <>
          <Button color="falcon-default" size="sm" id="adPopover" iconClassName="fs--2">
            SQL
          </Button>
          <UncontrolledPopover placement="left" target="adPopover">
            <PopoverHeader>SQL</PopoverHeader>
            <PopoverBody>
              <pre>
                  SELECT COUNT(*) as value, date_trunc('month', "startTime") as "startTime" FROM
(
    SELECT COUNT(*)                 as "value",
           date_trunc('month', t.start_time) as "startTime"
    FROM trips t
    WHERE t.type = 'ride' and t.distance > 0 and t.start_time >= :start
    group by date_trunc('month', t.start_time), t.relation_id
) AS sub
GROUP BY "startTime"
ORDER BY "startTime"
                </pre>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      } />
      <CardBody className="pb-1">
        {isLoading ? (
          <Loader />
        ) : (
          <ReactEchartsCore
            echarts={echarts}
            option={getOption(data?.data?.map(entry => [entry.startTime, entry.value]))}
            style={{ width: '100%', minHeight: '15rem', height: '100%' }}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default ActiveDriver;

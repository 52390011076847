import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import map from 'lodash/map';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Label,
  Col,
  Input,
  FormFeedback,
  FormGroup,
  Alert
} from 'reactstrap';
import { normalizeError, useFetch, usePostOrPatch } from '../request';
import Loader from './common/Loader';
import mapValues from 'lodash/mapValues';
import { useStoreState } from '../stores/SettingsStore';

const SelectGroupModal = ({ relation, onClose }) => {
  const [{ isAdmin = false }] = useStoreState('currentUser');
  const { control, handleSubmit, errors, setError } = useForm();
  const patch = usePostOrPatch(['relations', relation?.id], { throwOnError: true });
  const fetch = useFetch();
  const { data: groups, isLoading, isError, error } = useQuery(['groups', { order: 'name' }], fetch, {
    enabled: isAdmin
  });

  const update = async data => {
    try {
      const newData = await patch.send(
        mapValues(data, value => {
          return value === '' ? null : value;
        })
      );
      onClose(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
  };

  return (
    <Modal isOpen={!!relation} keyboard={false} centered>
      <ModalHeader>Select Group</ModalHeader>
      <ModalBody tag={Form} onSubmit={handleSubmit(update)} id="groupModalForm">
        {isError && <Alert color="danger">{error?.data?.message || error?.message || 'An error occurred.'}</Alert>}
        {patch.isError && (
          <Alert color="danger">{patch.error?.data?.message || patch.error?.message || 'An error occurred.'}</Alert>
        )}
        {patch.isPost && relation?.deviceId && (
          <Controller
            as={<input type="hidden" />}
            control={control}
            name="deviceId"
            defaultValue={relation.deviceId + ''}
          />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <FormGroup row className="my-3">
            <Label xs={6} md={4} className="text-500">
              Group
            </Label>
            <Col>
              <Controller
                as={
                  <Input type="select" invalid={!!errors?.groupId}>
                    <option value="">{patch.isPost ? 'Please select' : 'Without group relation'}</option>
                    {map(groups?.data, group => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </Input>
                }
                control={control}
                name="groupId"
                rules={patch.isPost ? { required: 'Required' } : undefined}
                defaultValue={relation?.groupId || ''}
              />
              <FormFeedback>{errors?.groupId?.message}</FormFeedback>
            </Col>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button outline color="falcon-default" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button color="falcon-primary" type="submit" form="groupModalForm">
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SelectGroupModal.propTypes = {
  relation: PropTypes.object,
  onClose: PropTypes.func
};

export default SelectGroupModal;

import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody } from 'reactstrap';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import Flex from '../common/Flex';
import { formatNumber, formatSeconds } from '../../helpers/utils';
import HelpTooltip from '../HelpTooltip';

const ChargeSummary = ({ className, filter, from, to }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(
    [
      `stats/summary/charge`,
      {
        ...filter,
        from,
        to
      }
    ],
    fetch,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: from && to
    }
  );

  return (
    <Card className={className}>
      <FalconCardHeader title="Charging Summary" titleTag="h5" />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <Flex wrap>
            <div className="w-50 mb-3">Charging</div>
            <div className="w-50 mb-3">{formatNumber(data?.count) || '0'}</div>
            <div className="w-50 mb-3">Energy</div>
            <div className="w-50 mb-3">{formatNumber(data?.diffEnergy) || '–'} kWh</div>
            <div className="w-50 mb-3">Type</div>
            <div className="w-50 mb-3">
              <div className="mb-1">
                AC: {formatNumber(data?.chargingType?.ac, 0) || '0'}
                <span className="fs--1 ml-3 text-600">
                  (
                  {formatNumber((data?.chargingType?.ac / (data?.chargingType?.ac + data?.chargingType?.dc)) * 100) ||
                    '0'}{' '}
                  %)
                </span>
              </div>
              <div>
                DC: {formatNumber(data?.chargingType?.dc, 0) || '0'}
                <span className="fs--1 ml-3 text-600">
                  (
                  {formatNumber((data?.chargingType?.dc / (data?.chargingType?.ac + data?.chargingType?.dc)) * 100) ||
                    '0'}{' '}
                  %)
                </span>
              </div>
            </div>
            <div className="w-50 mb-3">
              avg. charging loss
              <HelpTooltip className="ml-1" id="avgChargingLossTooltip">
                The difference between kWh on charging station and the amount the EV received.
              </HelpTooltip>
            </div>
            <div className="w-50 mb-3">
              {!data?.loss?.count && <>– %</>}
              {data?.loss?.count && (
                <>
                  {formatNumber((1 - data?.loss?.diffEnergyAvg / data?.loss?.chargingProviderEnergy) * 100) || '0'} %
                  <span className="fs--1 ml-3 text-600">
                    ({formatNumber((data?.loss?.count / data?.count) * 100) || '0'} % entry rate)
                  </span>
                </>
              )}
            </div>
            <div className="w-50 mb-3">avg. charging speed</div>
            <div className="w-50 mb-3">{formatNumber(data?.chargeAvg) || '–'} kW</div>
            <div className="w-50 mb-3">max. charging speed</div>
            <div className="w-50 mb-3">{formatNumber(data?.chargeMax) || '–'} kW</div>
            <div className="w-50 mb-3">avg. standing time</div>
            <div className="w-50 mb-3">{formatSeconds(defaultTo(data?.durationAvg, 0))}</div>
            <div className="w-50 mb-3">avg. standing time until 80%</div>
            <div className="w-50 mb-3">{formatSeconds(defaultTo(data?.duration80Avg, 0))}</div>
            <div className="w-50 mb-3">avg. Battery Temperature</div>
            <div className="w-50 mb-3">{formatNumber(data?.batteryTemperatureAvg) || '–'} °C</div>
            <div className="w-50 mb-3">avg. SOC at charging start</div>
            <div className="w-50 mb-3">{formatNumber(data?.socStartAvg) || '–'} %</div>
            <div className="w-50 mb-3">avg. SOC at charging end</div>
            <div className="w-50 mb-3">{formatNumber(data?.socEndAvg) || '–'} %</div>
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};

ChargeSummary.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

ChargeSummary.defaultProps = { filter: {} };

export default ChargeSummary;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const FormModal = props => {
  const { open, title, form: FormComp, onClose, ...attributes } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formId = camelCase(title) + 'Form';
  const kind = !props.data ? 'Create' : 'Update';

  return (
    <Modal isOpen={open} keyboard={false} centered>
      <ModalHeader>
        {kind} {title}
      </ModalHeader>
      <ModalBody>
        <FormComp
          {...attributes}
          formId={formId}
          onSubmit={newData => onClose(newData)}
          onStateChange={isSubmitting => setIsSubmitting(isSubmitting)}
        />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button outline color="falcon-default" disabled={isSubmitting} onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button color="falcon-primary" type="submit" disabled={isSubmitting} form={formId}>
          {kind}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FormModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  form: PropTypes.func,
  onClose: PropTypes.func
};

export default FormModal;

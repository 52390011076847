import React, { useState } from 'react';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import map from 'lodash/map';
import moment from 'moment';
import Datetime from 'react-datetime';
import { useQuery } from 'react-query';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { useStoreState as useSettingsStoreState } from '../stores/SettingsStore';
import { useStoreState } from '../stores/RequestStore';
import RideSummary from '../components/stats/RideSummary';
import ChargeSummary from '../components/stats/ChargeSummary';
import ChargeLocations from '../components/stats/ChargeLocations';
import PageHeaderSplit from '../components/common/PageHeaderSplit';
import { useFetch } from '../request';
import ActivityByBrandGraph from '../components/dashboard/ActivityByBrandGraph';
import ChargingProviderGraph from '../components/dashboard/ChargingProviderGraph';
import SpeedChart from '../components/dashboard/SpeedChart';
import QuantityCard from '../components/dashboard/QuantityCard';
import SocDiffBarChart from '../components/dashboard/SocDiffBarChart';

const initStart = moment.utc().startOf('month');
const initEnd = moment.utc().endOf('month');

function Dashboard() {
  const [currentUser] = useSettingsStoreState('currentUser');
  const fetch = useFetch();
  const { data: groups } = useQuery(['groups', { order: 'name' }], fetch, {
    enabled: currentUser.isAdmin
  });

  const [filter, setFilter] = useStoreState('dashboard', {
    startTime: {
      gte: initStart.toISOString(),
      lte: initEnd.toISOString()
    }
  });

  const [startDate, setStartDate] = useState(moment.utc(filter.startTime.gte));
  const [endDate, setEndDate] = useState(moment.utc(filter.startTime.lte));

  const submitDateFilter = e => {
    e.preventDefault();
    const newFilter = { ...filter };
    newFilter.startTime = {
      gte: startDate.toISOString(),
      lte: endDate.toISOString()
    };
    setFilter(newFilter);
  };

  return (
    <>
      <PageHeaderSplit title="Dashboard" className="mb-3">
        <Form inline>
          {currentUser?.isAdmin && (
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Input
                type="select"
                value={filter.groupId || ''}
                onChange={e => {
                  const groupId = e.target.value;
                  if (!groupId) {
                    setFilter(omit(filter, 'groupId'));
                  } else {
                    setFilter({ ...filter, groupId });
                  }
                }}
              >
                <option value="">All groups</option>
                {map(groups?.data, group => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <InputGroup className="flex-nowrap">
              <Datetime
                closeOnSelect
                isValidDate={current => current.isBefore(endDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={startDate}
                onChange={setStartDate}
                inputProps={{ className: 'form-control border-right-0 rounded-right-0' }}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>-</InputGroupText>
              </InputGroupAddon>
              <Datetime
                closeOnSelect
                isValidDate={current => current.isAfter(startDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={endDate}
                onChange={setEndDate}
                inputProps={{ className: 'form-control rounded-left-0' }}
                className="calendar-right"
              />
            </InputGroup>
          </FormGroup>
          <Button onClick={submitDateFilter} color="falcon-default">
            Apply
          </Button>
        </Form>
      </PageHeaderSplit>

      <Row className="row-cols-1 row-cols-md-2 card-gutter-wrapper align-items-stretch">
        <Col className="mb-3 card-gutter">
          <QuantityCard
            type="ride"
            filter={pick(filter, 'groupId')}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
          />
        </Col>
        <Col className="mb-3 card-gutter">
          <QuantityCard
            type="charge"
            filter={pick(filter, 'groupId')}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
          />
        </Col>
      </Row>

      <Row className="card-gutter-wrapper">
        <Col md={6} className="card-gutter mb-3">
          <ActivityByBrandGraph
            filter={pick(filter, 'groupId')}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
          />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <ChargingProviderGraph
            queryKey="stats/charging-providers"
            params={{
              ...pick(filter, 'groupId'),
              from: filter.startTime.gte,
              to: filter.startTime.lte
            }}
          />
        </Col>
      </Row>

      <Row className="card-gutter-wrapper">
        <Col md={6} className="card-gutter mb-3">
          <SpeedChart filter={pick(filter, 'groupId')} from={filter.startTime.gte} to={filter.startTime.lte} />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <SocDiffBarChart
            className="h-md-100"
            filter={filter.groupId ? { 'relation.groupId': filter.groupId } : undefined}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
          />
        </Col>
      </Row>

      <RideSummary
        className="mb-3"
        filter={pick(filter, 'groupId')}
        from={filter.startTime.gte}
        to={filter.startTime.lte}
      />

      <Row className="card-gutter-wrapper">
        <Col md={6} className="card-gutter mb-3">
          <ChargeSummary
            className="h-100"
            filter={pick(filter, 'groupId')}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
          />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <ChargeLocations
            className="h-100"
            filter={pick(filter, 'groupId')}
            from={filter.startTime.gte}
            to={filter.startTime.lte}
          />
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody } from 'reactstrap';
import { useQuery } from 'react-query';
import size from 'lodash/size';
import _filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getGrays, themeColors, getPosition, formatNumber } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import { useFetch } from '../../request';
import Loader from '../common/Loader';
import { format } from 'date-fns';

const getOption = data => {
  const grays = getGrays(false);
  const labels = data.map(entry => entry.startTime);
  const start = data.map(entry => entry.socStart);
  const diff = data.map(entry => entry.socEnd - entry.socStart);
  // const start = data.map(entry => [entry.socStart, entry.startTime]);
  // const diff = data.map(entry => [entry.socEnd - entry.socStart, entry.startTime]);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(entry) {
        return (
          format(new Date(entry[0].name), 'dd/MM') +
          ' ' +
          formatNumber(entry[0].value) +
          '% → ' +
          formatNumber(entry[0].value + entry[1].value) +
          '%'
        );
        // return format(entry[0].axisValue, 'dd/MM') + ' ' + formatNumber(entry[1].value[0]) + '%';
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      // type: 'time',
      type: 'category',
      data: labels,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return format(new Date(value), 'dd/MM');
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        color: grays['400'],
        margin: 15,
        formatter: `{value} %`
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        stack: 'one',
        barMaxWidth: 10,
        itemStyle: {
          barBorderColor: 'rgba(0,0,0,0)',
          color: 'rgba(0,0,0,0)'
        },
        emphasis: {
          itemStyle: {
            barBorderColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)'
          }
        },
        data: start
      },
      {
        type: 'bar',
        stack: 'one',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.success,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: diff
      }
    ],
    grid: {
      top: '5%',
      left: '0%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    }
  };
};

const SocDiffBarChart = ({ className, filter, from, to }) => {
  const fetch = useFetch();
  const { data, isLoading, isFetching } = useQuery(
    [
      'trips',
      {
        select: 'id,socStart,socEnd,startTime',
        order: 'startTime',
        filter: {
          type: 'charge',
          startTime: {
            gte: from,
            lte: to
          },
          ...filter
        }
      }
    ],
    fetch,
    {
      enabled: from && to
    }
  );

  return (
    <Card className={className}>
      <FalconCardHeader title="SOC start / end" titleTag="h5" />
      <CardBody>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            {size(data?.data) > 0 ? (
              <ReactEchartsCore
                echarts={echarts}
                option={getOption(_filter(data?.data, entry => !isNil(entry.socStart) && !isNil(entry.socEnd)))}
                style={{ width: '100%', minHeight: '15rem', height: '100%' }}
              />
            ) : (
              <Alert color="info" className="text-center mb-0">
                Could not find any data.
              </Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

SocDiffBarChart.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default SocDiffBarChart;

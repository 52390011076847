import React, { useState } from 'react';
import { format } from 'date-fns';
import _ from 'lodash';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import ButtonIcon from '../components/common/ButtonIcon';
import { Link } from 'react-router-dom';

const problems = {
  ride: {
    soc: 'SOC',
    consumption: 'consumption',
    duration: 'duration',
    multiple: 'multiple rides',
    position: 'location',
    score: 'score'
  },
  charge: {
    chart: 'chart',
    place: 'address type',
    position: 'location',
    duration: 'duration',
    multiple: 'multiple chargings',
    mileage: 'mileage'
  }
};

const ActivityIssues = () => {
  const [params, setParams] = useState({
    page: 1,
    size: 15,
    order: '-reportedAt'
  });

  const columns = [
    {
      dataField: 'deviceId',
      text: 'Device ID'
    },
    {
      dataField: 'startTime',
      text: 'Time',
      formatter: (dataField, entry) =>
        format(new Date(dataField), 'HH:mm') + ' - ' + format(new Date(entry.endTime), 'HH:mm dd/MM/yyyy')
    },
    {
      dataField: 'reportedIssues',
      text: 'Issues',
      formatter: (dataField, { type }) => _.map(dataField, d => problems[type][d]).join(', ')
    },
    {
      dataField: 'reportedMessage',
      text: 'Message'
    },
    {
      dataField: 'reportedAt',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      text: 'Reported at'
    },
    {
      formatter: (dataField, entry) => (
        <>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="eye"
            className="fs--1 text-600 px-2 m-1"
            iconClassName="fs--1"
            tag={Link}
            to={`/activities/${entry.id}`}
            title="View activity"
          />
        </>
      ),
      align: 'right'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Activity Issues" className="mb-4 mt-3 align-items-center" />
      <CardTable params={params} setParams={setParams} queryKey="manage/issues" columns={columns} />
    </>
  );
};

export default ActivityIssues;

import React from 'react';
import PropTypes from 'prop-types';
import toString from 'lodash/toString';
import { format } from 'date-fns';
import CardTable from '../CardTable';
import { useStoreState } from '../../stores/RequestStore';
import { textFilter } from 'react-bootstrap-table2-filter';

const columns = params => [
  // {
  //   dataField: 'deviceId',
  //   text: 'Device',
  //   formatter: dataField => <Link to={`/devices/${dataField}`}>{dataField}</Link>,
  //   sort: true
  // },
  {
    dataField: 'eventType',
    text: 'Event Type',
    sort: true,
    filter: textFilter({
      className: 'form-control-sm',
      // defaultValue: params?.filter?.eventType?.iLike
    })
  },
  {
    dataField: 'value',
    text: 'Value Raw',
    formatter: (dataField, entry) => (entry.dataType === 'GEO' ? JSON.stringify(dataField) : toString(dataField))
  },
  {
    dataField: 'unit',
    text: 'Unit'
  },
  {
    dataField: 'recordedTimestamp',
    text: 'Recorded Timestamp',
    formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
    sort: true
  },
  {
    dataField: 'updatedAt',
    text: 'Updated at',
    formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
    sort: true
  }
];

const LogsCard = ({ title, queryKey, storeKey, entries, defaultOrder, defaultFilter }) => {
  const [params, setParams] = useStoreState(storeKey || queryKey, {
    page: 1,
    size: entries,
    filter: defaultFilter,
    order: defaultOrder
  });
  return (
    <CardTable title={title} params={params} setParams={setParams} queryKey={queryKey} columns={columns(params)} />
  );
};

LogsCard.propTypes = {
  title: PropTypes.string,
  queryKey: PropTypes.string.isRequired,
  storeKey: PropTypes.string,
  defaultFilter: PropTypes.object
};

LogsCard.defaultProps = {
  defaultOrder: 'recordedTimestamp',
  title: 'Logs',
  entries: 20
};

export default LogsCard;

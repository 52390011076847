import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCrosshairs,
  faBars,
  faBolt,
  faSpinner,
  faKey,
  faChartLine,
  faUserAstronaut,
  faPercent,
  faUnlink,
  faChargingStation,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faPen,
  faFlag,
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faAngleRight,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArrowLeft,
  faArrowRight,
  faArchive,
  faEnvelope,
  faClock,
  faReply,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faRedo,
  faSlidersH,
  faTrashAlt,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faEye,
  faCaretUp,
  faCaretDown,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faUsers,
  faGlobeAmericas,
  faPlusSquare,
  faUserPlus,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faComments,
  faPhone,
  faInfo,
  faVideo,
  faPalette,
  faEdit,
  faShare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faPercentage,
  faIcons,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faAt,
  faCar
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebook,
  faFacebookSquare,
  faGulp,
  faNodeJs,
  faSass,
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,
  faYoutube,
  faTrello
} from '@fortawesome/free-brands-svg-icons';

import {
  faHeart as farHeart,
  faLightbulb as farLightbulb,
  faObjectUngroup as farObjectUngroup,
  faPaperPlane as farPaperPlane,
  faStar as farStar,
  faImage as farImage,
  faFileArchive as farFileArchive,
  faFilePdf as farFilePdf,
  faFile as farFile,
  faFileVideo as farFileVideo,
  faFileAudio as farFileAudio,
  faFileImage as farFileImage,
  faFileCode as farFileCode,
  faClock as farClock,
  faQuestionCircle as farQuestionCircle,
  faDizzy as farDizzy,
  faLaughBeam as farLaughBeam,
  faSmileBeam as farSmileBeam,
  faCircle as farCircle,
  faCopy as farCopy,
  faComment as farComment
} from '@fortawesome/free-regular-svg-icons';

library.add(
  // Solid
  faCrosshairs,
  faBars,
  faBolt,
  faSpinner,
  faKey,
  faChartLine,
  faUserAstronaut,
  faPercent,
  faUnlink,
  faPen,
  faChargingStation,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCar,
  faFlag,
  faChartPie,
  faCopy,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArchive,
  faEnvelope,
  faClock,
  faStar,
  faReply,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faRedo,
  faSlidersH,
  faTrashAlt,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faEye,
  faCaretUp,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faUsers,
  faGlobeAmericas,
  faPlusSquare,
  faCaretDown,
  faUserPlus,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faComments,
  faPalette,
  faEdit,
  faTrashAlt,
  faShare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faPercentage,
  faIcons,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faAt,

  // Brand
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGulp,
  faNodeJs,
  faSass,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,
  faYoutube,
  faVideo,
  faInfo,
  faPhone,
  faTrello,

  // Regular
  farHeart,
  farLightbulb,
  farObjectUngroup,
  farPaperPlane,
  farStar,
  farImage,
  farFileArchive,
  farFilePdf,
  farFile,
  farFileVideo,
  farFileAudio,
  farFileImage,
  farFileCode,
  farClock,
  farQuestionCircle,
  farDizzy,
  farLaughBeam,
  farSmileBeam,
  farCircle,
  farCopy,
  farComment
);

import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import isNil from 'lodash/isNil';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { normalizeError, usePostOrPatch } from '../../request';
import RadioGroup from './RadioGroup';
import { convertValues } from '../../helpers/utils';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const SponsorForm = ({ className, formId, data: sponsor, onSubmit, onStateChange }) => {
  const { control, handleSubmit, errors, setError } = useForm();
  const post = usePostOrPatch(['sponsors', sponsor?.id], { throwOnError: true });

  const update = async data => {
    onStateChange && onStateChange(true);
    try {
      const newData = await post.send(convertValues(data));
      onSubmit(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
    onStateChange && onStateChange(false);
  };

  return (
    <Form className={className} onSubmit={handleSubmit(update)} id={formId}>
      {post.isError && !post.error?.data?.errorList && (
        <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
      )}
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Name *
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.name} />}
            control={control}
            name="name"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(sponsor?.name, '')}
          />
          <FormFeedback>{errors?.name?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Tag *
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.tag} />}
            control={control}
            name="tag"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(sponsor?.tag, '')}
          />
          <FormFeedback>{errors?.tag?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Invite Code
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.inviteCode} />}
            control={control}
            name="inviteCode"
            defaultValue={defaultTo(sponsor?.inviteCode, '')}
          />
          <FormFeedback>{errors?.inviteCode?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Usage limit
        </Label>
        <Col>
          <Controller
            as={<Input type="number" invalid={!!errors?.codeLimit} />}
            control={control}
            name="codeLimit"
            defaultValue={defaultTo(sponsor?.codeLimit, '')}
          />
          <FormFeedback>{errors?.codeLimit?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Show <i>Next Charging</i> card in app.
        </Label>
        <Col className="py-1">
          <Controller
            as={
              <RadioGroup
                invalid={!!errors?.showNextCharging}
                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
              />
            }
            name="showNextCharging"
            control={control}
            defaultValue={isNil(sponsor?.showNextCharging) ? 'true' : sponsor.showNextCharging.toString()}
          />
          <FormFeedback>{errors?.showNextCharging?.message}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
};

SponsorForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onStateChange: PropTypes.func
};

SponsorForm.defaultProps = { formId: 'sponsorUpdateForm' };

export default SponsorForm;

import React from 'react';
// import ForgetPasswordForm from '../components/auth/ForgetPasswordForm';
import AuthCardLayout from '../layouts/AuthCardLayout';
import { Link } from 'react-router-dom';

const ForgetPassword = () => {
  return (
    <AuthCardLayout
    // leftSideContent={
    //   <p className="mb-0 mt-4 mt-md-5 fs--1 font-weight-semi-bold text-300">
    //     Read our{' '}
    //     <Link className="text-underline text-300" to="#!">
    //       terms
    //     </Link>{' '}
    //     and{' '}
    //     <Link className="text-underline text-300" to="#!">
    //       conditions{' '}
    //     </Link>
    //   </p>
    // }
    >
      <h4>Forgot your password?</h4>
      <p>
        Send us an email to <Link to="mailto:info@evelix.app">info@evelix.app</Link> and we will take care of resetting
        your password.
      </p>
      <Link className="fs--1 text-600" to="/login">
        <span className="d-inline-block mr-1">&larr;</span>
        Back to login page.
      </Link>
      {/*<ForgetPasswordForm layout="card" />*/}
    </AuthCardLayout>
  );
};

export default ForgetPassword;

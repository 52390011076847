import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { normalizeError, usePostOrPatch } from '../../request';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const GroupForm = ({ className, formId, data: group, onSubmit, onStateChange }) => {
  const { control, handleSubmit, errors, setError } = useForm();
  const post = usePostOrPatch(['groups', group?.id], { throwOnError: true });

  const update = async data => {
    onStateChange && onStateChange(true);
    try {
      const newData = await post.send(data);
      onSubmit(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
    onStateChange && onStateChange(false);
  };

  return (
    <Form className={className} onSubmit={handleSubmit(update)} id={formId}>
      {post.isError && (
        <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
      )}
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Name *
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.name} />}
            control={control}
            name="name"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(group?.name, '')}
          />
          <FormFeedback>{errors?.name?.message}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
};

GroupForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onStateChange: PropTypes.func
};

GroupForm.defaultProps = { formId: 'groupUpdateForm' };

export default GroupForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, CardBody, Tooltip } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Flex from '../common/Flex';
import { formatNumber } from '../../helpers/utils';

export const differenceBadge = (val, prevVal) => {
  let diff = Math.round((val - prevVal) * 100);
  let sign = '±';
  let color = 'soft-secondary';
  if (diff !== 0) {
    sign = diff < 0 ? '-' : '+';
    color = diff < 0 ? 'soft-warning' : 'soft-success';
    diff = formatNumber(Math.abs(diff), 0);
  }
  return (
    <Badge color={color} className="ml-3">
      {sign} {diff}
    </Badge>
  );
};

const ScoreCard = ({ data }) => {
  const [accelerationScore, setAccelerationScore] = useState(false);
  const [brakingScore, setBrakingScore] = useState(false);
  const [corneringScore, setCorneringScore] = useState(false);

  return (
    <Card className="h-sm-100">
      <FalconCardHeader className="pb-0" title="Eco Score" light={false} titleTag="h6" />
      <CardBody tag={Flex} column>
        <div className="mb-2">
          {!data.drivingScore && 'n/a'}
          {data.drivingScore && (
            <>
              <span className="display-4 fs-4 font-weight-normal text-sans-serif align-bottom">
                {formatNumber(data.drivingScore * 100, 0)}
              </span>
              {data?.prevScore?.drivingScore && differenceBadge(data.drivingScore, data.prevScore.drivingScore)}
            </>
          )}
        </div>
        <div className="mt-3 position-relative" id="accelerationScore">
          <div className="mb-1">
            <span className="align-middle">Acceleration score</span>
            {data.accelerationScore &&
              data?.prevScore?.accelerationScore &&
              differenceBadge(data.accelerationScore, data.prevScore.accelerationScore)}
          </div>
          <div className="score-bar">
            <div className="score-bar-value" style={{ width: `${data.accelerationScore || 0}%` }} />
          </div>
          {data.accelerationScore && (
            <Tooltip
              placement="bottom"
              container="#accelerationScore"
              fade={false}
              isOpen={accelerationScore}
              target="accelerationScore"
              toggle={() => setAccelerationScore(!accelerationScore)}
              popperClassName="popper-bottom"
            >
              {formatNumber(data.accelerationScore * 100, 0)}
            </Tooltip>
          )}
        </div>
        <div className="mt-3 position-relative" id="brakingScore">
          <div className="mb-1">
            <span className="align-middle">Braking score</span>
            {data.brakingScore &&
              data?.prevScore?.brakingScore &&
              differenceBadge(data.brakingScore, data.prevScore.brakingScore)}
          </div>
          <div className="score-bar">
            <div className="score-bar-value" style={{ width: `${data.brakingScore || 0}%` }} />
          </div>
          {data.brakingScore && (
            <Tooltip
              placement="bottom"
              container="#brakingScore"
              fade={false}
              isOpen={brakingScore}
              target="brakingScore"
              toggle={() => setBrakingScore(!brakingScore)}
              popperClassName="popper-bottom"
            >
              {formatNumber(data.brakingScore * 100, 0)}
            </Tooltip>
          )}
        </div>
        <div className="mt-3 position-relative" id="corneringScore">
          <div className="mb-1">
            <span className="align-middle">Cornering score</span>
            {data.corneringScore &&
              data?.prevScore?.corneringScore &&
              differenceBadge(data.corneringScore, data.prevScore.corneringScore)}
          </div>
          <div className="score-bar">
            <div className="score-bar-value" style={{ width: `${data.corneringScore || 0}%` }} />
          </div>
          {data.corneringScore && (
            <Tooltip
              placement="bottom"
              container="#corneringScore"
              fade={false}
              isOpen={corneringScore}
              target="corneringScore"
              toggle={() => setCorneringScore(!corneringScore)}
              popperClassName="popper-bottom"
            >
              {formatNumber(data.corneringScore * 100, 0)}
            </Tooltip>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

ScoreCard.propTypes = { data: PropTypes.object.isRequired };

export default ScoreCard;

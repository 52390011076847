import React from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import map from 'lodash/map';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { format } from 'date-fns';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';

const classes = {
  row: 'my-3',
  colLabel: 'text-md-right text-500'
};

const FeedbackCard = ({ className, driver }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(
    ['feedback', { filter: { createdById: driver.id }, order: '-createdAt' }],
    fetch,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  return (
    <Card className={className}>
      <FalconCardHeader title="Feedback" titleTag="h5" />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : size(data?.data) === 0 ? (
          <Alert color="info" className="text-center rounded-0 mb-0">
            Could not find any data.
          </Alert>
        ) : (
          map(data?.data, feedback => (
            <Row className={classes.row} key={feedback.id}>
              <Col md={3} className={classes.colLabel}>
                {format(new Date(feedback.createdAt), 'dd/MM/yyyy HH:mm')}
              </Col>
              <Col md={9}>{feedback.message}</Col>
            </Row>
          ))
        )}
      </CardBody>
    </Card>
  );
};

FeedbackCard.propTypes = {
  className: PropTypes.string,
  driver: PropTypes.object
};

export default FeedbackCard;

import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody } from 'reactstrap';
import Flex from '../common/Flex';
import { Link } from 'react-router-dom';
import HelpTooltip from '../HelpTooltip';
import { format } from 'date-fns';

const VehicleCard = ({ className, deviceId, vehicle }) => {
  return (
    <Card className={className}>
      <FalconCardHeader className="pb-0" title="Vehicle" light={false} titleTag="h6" />
      <CardBody tag={Flex} column>
        <div className="display-4 fs-1 mb-2 font-weight-normal text-sans-serif">
          {vehicle?.vehicleBrand?.label || vehicle?.vehicleModel?.label ? (
            <span className="mr-2">
              {vehicle?.vehicleBrand?.label || ''} {vehicle?.vehicleModel?.label || ''}
            </span>
          ) : (
            <i className="mr-2 fs-0">Vehicle unset</i>
          )}
          {(vehicle?.brandName ||
            vehicle?.modelName ||
            vehicle?.numberPlate ||
            vehicle?.initialRegistration ||
            vehicle?.batteryCapacity) && (
            <HelpTooltip id="vehicleTip">
              {(vehicle?.brandName || vehicle?.modelName) && (
                <div className="mb-3">
                  {vehicle?.brandName || ''} <br />
                  {vehicle?.modelName || ''}
                </div>
              )}
              {vehicle?.numberPlate && <div>{vehicle.numberPlate}</div>}
              {vehicle?.initialRegistration && <div>{format(new Date(vehicle.initialRegistration), 'MM/yyyy')}</div>}
              {vehicle?.batteryCapacity && <div>{vehicle.batteryCapacity} kWh battery size</div>}
            </HelpTooltip>
          )}
        </div>

        <div className="fs--1 text-600">
          Connector{' '}
          <Link to={`/devices/${deviceId}`} className="text-600">
            {deviceId}
          </Link>
        </div>
        {vehicle && (
          <div className="fs--1 text-600">
            VIN{' '}
            <Link to={`/vehicles/${vehicle.id}`} className="text-600 demo-blur">
              {vehicle?.vin || <i>Unknown</i>}
            </Link>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

VehicleCard.propTypes = {
  className: PropTypes.string,
  deviceId: PropTypes.number,
  vehicle: PropTypes.object
};

export default VehicleCard;

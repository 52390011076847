import React from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Card, CardBody } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import { getChartOptions, themeColors } from '../../helpers/utils';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';

const RemainingEnergyGraph = ({ trip, className }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(`trips/${trip.id}/points/remaining-energy`, fetch);

  return (
    <Card className={className}>
      <FalconCardHeader title="Remaining Energy" titleTag="h5" />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : size(data?.data) > 0 ? (
          <ReactEchartsCore
            echarts={echarts}
            option={getChartOptions(
              data.data.map(d => [d.recordedTimestamp, d.value]),
              'kWh',
              trip.type === 'ride' ? themeColors.info : themeColors.success
            )}
            style={{ width: '100%', minHeight: '15rem', height: '100%' }}
          />
        ) : (
          <Alert color="danger" className="text-center mb-0">
            Could not find any data.
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

RemainingEnergyGraph.propTypes = { className: PropTypes.string, trip: PropTypes.object.isRequired };

export default RemainingEnergyGraph;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useQuery, useQueryCache } from 'react-query';
import { format } from 'date-fns';
import { Badge } from 'reactstrap';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { formatUser } from '../helpers/utils';
import ButtonIcon from '../components/common/ButtonIcon';
import { useStoreState as useSettingStoreState } from '../stores/SettingsStore';
import { useStoreState } from '../stores/RequestStore';
import FormModal from '../components/FormModal';
import Flex from '../components/common/Flex';
import DriverForm from '../components/form/DriverForm';
import { useFetch } from '../request';
import DriverStats from '../components/driver/DriverStats';

const formatCharging = (addresses, entry) => {
  const types = {};
  map(addresses, a => {
    types[a.type] = a.canCharge;
  });
  return (
    <>
      <Badge className={`mr-2 mb-1 badge-home${types.home ? '' : '-outline'}`} pill>
        H {types.home ? '✓' : '✗'}
      </Badge>
      <Badge className={`mr-2 mb-1 badge-work${types.work ? '' : '-outline'}`} pill>
        W {types.work ? '✓' : '✗'}
      </Badge>
      <Badge className={`mr-2 mb-1 badge-custom${entry?.usePublicCharging ? '' : '-outline'}`} pill>
        P {entry?.usePublicCharging ? '✓' : '✗'}
      </Badge>
    </>
  );
};

const Drivers = () => {
  const [showModal, setShowModal] = useState(false);
  const [{ isAdmin = false }] = useSettingStoreState('currentUser');
  const [params, setParams] = useStoreState('drivers', {
    page: 1,
    size: 15,
    order: '-createdAt'
  });
  const queryCache = useQueryCache();
  const fetch = useFetch();
  const { data: groups } = useQuery(['groups', { order: 'name' }], fetch, {
    enabled: isAdmin
  });

  const columns = [
    {
      dataField: 'firstName',
      text: 'Driver',
      formatter: (dataField, entry) => (
        <Link className="text-700 demo-blur" to={`/drivers/${entry.id}`}>
          {formatUser(entry)}
        </Link>
      )
    },
    {
      dataField: 'relation.deviceId',
      text: 'Device ID',
      formatter: dataField =>
        dataField ? (
          <Link className="text-700" to={`/devices/${dataField}`}>
            {dataField}
          </Link>
        ) : (
          ''
        ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.deviceId']?.iLike
      })
    },
    {
      dataField: 'relation.groupId',
      text: 'Group',
      formatter: (dataField, entry) =>
        dataField ? <span className="demo-blur">{entry.relation.group.name}</span> : '',
      filter:
        isAdmin && !isEmpty(groups?.data)
          ? selectFilter({
              className: 'form-control-sm',
              options: map(groups?.data, group => ({ value: group.id, label: group.name })),
              defaultValue: params?.filter?.['relation.groupId']
            })
          : undefined
    },
    {
      dataField: 'relation.vehicle.brandName',
      text: 'Brand',
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.vehicle.brandName']?.iLike
      })
    },
    {
      dataField: 'relation.vehicle.modelName',
      text: 'Model',
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.vehicle.modelName']?.iLike
      })
    },
    {
      dataField: 'addresses',
      text: 'Charging',
      formatter: (dataField, entry) => formatCharging(dataField, entry)
    },
    {
      dataField: 'createdAt',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      text: 'Sign up',
      sort: true
    },
    {
      formatter: (dataField, entry) => (
        <>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="eye"
            className="fs--1 text-600 px-2 ml-2"
            iconClassName="fs--1"
            tag={Link}
            to={`/drivers/${entry.id}`}
            title="View details"
          />
        </>
      ),
      align: 'right'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Drivers" className="mb-4 mt-3 align-items-center" />
      <DriverStats />
      {isAdmin && (
        <Flex justify="end" className="mb-3">
          <ButtonIcon icon="plus" color="falcon-primary" onClick={() => setShowModal(true)}>
            New
          </ButtonIcon>
        </Flex>
      )}
      <CardTable params={params} setParams={setParams} queryKey="drivers" columns={columns} />
      <FormModal
        open={!!showModal}
        form={DriverForm}
        title="Driver"
        onClose={newDriver => {
          if (newDriver) {
            queryCache.invalidateQueries('drivers');
          }
          setShowModal(false);
        }}
      />
    </>
  );
};

export default Drivers;

import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Card, CardBody } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/heatmap';
import 'echarts/lib/component/visualMap';
import { getGrays, themeColors, getPosition } from '../../helpers/utils';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import size from 'lodash/size';

const getOption = (data, unit) => {
  const grays = getGrays(false);
  const hours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];
  const days = ['Sunday', 'Saturday', 'Friday', 'Thursday', 'Wednesday', 'Tuesday', 'Monday'];

  const map = {};
  let max = 0;
  data.forEach(d => {
    const dow = (7 - d.dayOfWeek) % 7; // reorder indices
    if (!map[dow]) map[dow] = {};
    map[dow][d.hour] = d.value;
    max = Math.max(d.value, max);
  });

  const heatData = [];
  for (let day = 0; day < days.length; day++) {
    for (let hour = 0; hour < hours.length; hour++) {
      const val = map?.[day]?.[hour] ? Math.round(map[day][hour] * 10) / 10 : '-';
      heatData.push([hour, day, val]);
    }
  }

  return {
    tooltip: {
      triggerOn: 'mousemove',
      // trigger: 'axis',
      padding: [7, 10],
      formatter: function(params) {
        return `<span class="chart-tooltip-point" style="background-color:${params.color};"></span> ${params.name}: ${
          params.value[2]
        } ${unit}`;
      },
      // formatter: function(entry) {
      //   const value = entry[0].value[1] === '-' ? entry[1].value[1] : entry[0].value[1];
      //   return format(entry[0].axisValue, 'HH:mm') + ' ' + formatNumber(value) + ' ' + unit;
      // },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    animation: false,
    xAxis: {
      type: 'category',
      data: hours,
      splitArea: {
        show: true
      }
    },
    yAxis: {
      type: 'category',
      data: days,
      splitArea: {
        show: true
      }
    },
    visualMap: {
      type: 'continuous',
      min: 0,
      max: Math.ceil(max),
      show: false
      // inRange: {
      //   color: ['#DD2000', '#009000'] //From smaller to bigger value ->
      // }
    },
    series: {
      type: 'heatmap',
      data: heatData,
      label: {
        show: true
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    },
    grid: { right: '28px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const ActivityWeeklyHeatmap = ({ driverId, type, className, from, to, title, unit }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery([`drivers/${driverId}/activity/${type}`, { from, to }], fetch);

  return (
    <Card className={className}>
      <FalconCardHeader title={title} titleTag="h5" />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {size(data?.data) > 0 ? (
              <ReactEchartsCore
                echarts={echarts}
                option={getOption(data?.data, unit)}
                style={{ width: '100%', minHeight: '15rem', height: '100%' }}
              />
            ) : (
              <Alert color="info" className="text-center mb-0">
                Could not find any data.
              </Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

ActivityWeeklyHeatmap.propTypes = {
  className: PropTypes.string,
  driverId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['charge', 'ride']).isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  unit: PropTypes.node.isRequired
};

ActivityWeeklyHeatmap.defaultProps = {
  title: 'Weekly Heatmap'
};

export default ActivityWeeklyHeatmap;

import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import FalconCardHeader from '../common/FalconCardHeader';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import Flex from '../common/Flex';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import { getGrays, themeColors, rgbaColor, getPosition, numberFormatter } from '../../helpers/utils';

const getOption = (begin, end) => {
  const grays = getGrays(false);
  const color = begin > end ? themeColors.info : themeColors.success;
  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: '{b0}: {c0}%',
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'category',
      data: ['Begin', 'End'],
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { type: 'none' }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'line',
        lineStyle: {
          color,
          width: 3
        },
        itemStyle: {
          color: grays['100'],
          borderColor: color,
          borderWidth: 2
        },
        hoverAnimation: true,
        data: [begin, end],
        symbol: 'circle',
        symbolSize: 8,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(color, 0.25)
              },
              {
                offset: 1,
                color: rgbaColor(color, 0)
              }
            ]
          }
        }
      }
    ],
    grid: { bottom: '0%', top: '5px', right: '10px', left: '10px' }
  };
};

const SocCard = ({ className, begin, end }) => {
  if (isNil(begin) || isNil(end)) {
    return (
      <Card className={className}>
        <FalconCardHeader className="pb-0" title="State of Charge" light={false} titleTag="h6" />
        <CardBody tag={Flex} align="center">
          <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif">n/a %</div>
        </CardBody>
      </Card>
    );
  }

  const isRide = begin > end;
  return (
    <Card className={className}>
      <FalconCardHeader className="pb-0" title="State of Charge" light={false} titleTag="h6" />
      <CardBody tag={Flex} align="end" className="pt-2">
        <Row className="flex-grow-1">
          <Col xs={6} className="align-self-center">
            <div className="fs-3 font-weight-normal text-sans-serif text-700 line-height-1">
              {numberFormatter(end, 1)}%{' '}
              <Badge pill color="soft-secondary" className="fs--2">
                end
              </Badge>
            </div>
            <Badge pill color={isRide ? 'soft-info' : 'soft-success'} className="fs--2">
              {isRide ? '-' : '+'} {numberFormatter(Math.abs(begin - end), 1)}
            </Badge>
            <div className="fs-0 font-weight-normal text-sans-serif text-700 line-height-1 mt-1">
              {numberFormatter(begin, 1)}%{' '}
              <Badge pill color="soft-secondary" className="fs--2">
                old
              </Badge>
            </div>
          </Col>
          <Col xs={6} className="pl-0">
            <ReactEchartsCore
              echarts={echarts}
              option={getOption(begin, end)}
              style={{ width: '100%', minHeight: '5rem', height: '100%' }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

SocCard.propTypes = { className: PropTypes.string, begin: PropTypes.number, end: PropTypes.number };

export default SocCard;

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { format } from 'date-fns';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { useFetch } from '../request';
import { useQuery } from 'react-query';
import Loader from '../components/common/Loader';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import Flex from '../components/common/Flex';
import DriverCard from '../components/trip/DriverCard';
import SimpleCard from '../components/SimpleCard';
import CurrentsGraph from '../components/trip/CurrentsGraph';
import LogsCard from '../components/trip/LogsCard';
import TimeRangeDetails from '../components/vehicle/TimeRangeDetails';
import { formatNumber } from '../helpers/utils';
import BatteryCapacityGraph from '../components/vehicle/BatteryCapacityGraph';
import StateOfHealthGraph from '../components/vehicle/StateOfHealthGraph';
import { useStoreState } from '../stores/SettingsStore';
import RelationHistoryCard from '../components/RelationHistoryCard';
import VehicleForm from '../components/form/VehicleForm';
import FormModal from '../components/FormModal';
import ButtonIcon from '../components/common/ButtonIcon';
import VehicleCard from '../components/trip/VehicleCard';

const VehicleDetails = ({ match }) => {
  const { id } = match.params;
  const fetch = useFetch();
  const [showModal, setShowModal] = useState(false);
  const [{ isAdmin = false }] = useStoreState('currentUser');
  const [isDemoMode] = useStoreState('demoMode');
  const { data: vehicle, isLoading, isError, error, refetch } = useQuery(`vehicles/${id}`, fetch, {
    enabled: id
  });
  const [showDate, setShowDate] = useState(false);
  if (!id) {
    return <Redirect to={`/vehicles`} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <Alert color="danger" className="text-center mt-4">
        An error occurred during the request. Status code {error.status}.
      </Alert>
    );
  }

  const formatDate = entry => {
    if (!showDate || !entry?.recordedTimestamp) {
      return null;
    }
    return format(new Date(entry?.recordedTimestamp), 'dd/MM/yyyy HH:mm');
  };

  return (
    <>
      <WidgetsSectionTitle
        title={`Vehicle`}
        className="mb-4 mt-3 align-items-center"
        icon="car"
        buttons={
          isAdmin ? (
            <ButtonIcon
              color="falcon-default"
              size="sm"
              icon="pen"
              className="fs--1 text-600 px-2 ml-2"
              onClick={() => setShowModal(true)}
              iconClassName="fs--1"
              title="Edit"
            />
          ) : null
        }
      />
      <Row className="card-gutter-wrapper align-items-stretch">
        <Col md={3} className="card-gutter mb-3">
          <VehicleCard deviceId={vehicle?.relation?.deviceId} vehicle={vehicle} className="h-100" />
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <DriverCard driver={vehicle?.relation?.driver} className="h-100" />
        </Col>
        <Col md={6} className="card-gutter mb-3">
          <Card className="h-100">
            <FalconCardHeader className="pb-0" title="Data control" light={false} titleTag="h6" />
            <CardBody tag={Flex} column>
              {vehicle.latestEvent ? (
                <>
                  <div>
                    Latest <span className="ml-3">{format(new Date(vehicle.latestEvent), 'dd/MM/yyyy HH:mm')}</span>
                  </div>
                  <div className="mt-2">
                    <Button color="falcon-primary" size="sm" className="mt-2" onClick={() => setShowDate(!showDate)}>
                      {showDate ? 'Hide' : 'Show'} timestamps
                    </Button>
                  </div>
                </>
              ) : (
                <div>
                  <i>Not found</i>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <div className="card-deck align-items-stretch mb-3">
        <SimpleCard
          title="State of Charge"
          value={vehicle?.soc?.value}
          unit="%"
          description={formatDate(vehicle?.soc)}
        />
        <SimpleCard
          title="Range"
          value={vehicle?.remainingRange?.value}
          unit="km"
          description={formatDate(vehicle?.remainingRange)}
        />
        <Card>
          <FalconCardHeader className="pb-0" title="avg. Consumption / 100 km" light={false} titleTag="h6" />
          <CardBody tag={Flex} column>
            <div className="display-4 fs-1 mt-1 mb-2 font-weight-normal text-sans-serif">
              {vehicle?.diffEnergyAvg ? `${formatNumber(vehicle?.diffEnergyAvg)} kWh` : 'n/a kWh'}
            </div>
          </CardBody>
        </Card>
        <SimpleCard
          title="State of Health"
          value={vehicle?.stateOfHealth?.value}
          unit="%"
          description={formatDate(vehicle?.stateOfHealth)}
        />
      </div>

      <div className="card-deck align-items-stretch mb-3">
        <SimpleCard
          title="Mileage"
          value={formatNumber(vehicle?.mileage?.value)}
          unit="km"
          description={formatDate(vehicle?.mileage)}
        />
        <SimpleCard
          title="Battery temperature"
          value={vehicle?.batteryTemperature?.value}
          unit="°C"
          description={formatDate(vehicle?.batteryTemperature)}
        />
        <SimpleCard
          title="Battery voltage"
          value={vehicle?.batteryVoltage?.value}
          unit="V"
          description={formatDate(vehicle?.batteryVoltage)}
        />
        <SimpleCard title="Location" value={vehicle?.position?.street} description={formatDate(vehicle?.position)} />
      </div>

      <div className="card-deck align-items-stretch mb-3">
        <SimpleCard title="Firmware" value={vehicle?.firmware?.value} description={formatDate(vehicle?.firmware)} />

        <SimpleCard
          title="Remaining Energy"
          value={formatNumber(vehicle?.remainingEnergy?.value, 1)}
          description={formatDate(vehicle?.remainingEnergy)}
          unit="kWh"
        />

        <Card>
          <FalconCardHeader className="pb-0" title="Highest Remaining Energy" light={false} titleTag="h6" />
          <CardBody tag={Flex} column>
            <div className="display-4 fs-1 mt-1 mb-2 font-weight-normal text-sans-serif">
              {!vehicle?.maxRemainingEnergy?.value
                ? 'n/a'
                : `${formatNumber(vehicle?.maxRemainingEnergy?.value)} kWh (${formatNumber(vehicle?.maxSoc?.value)} %)`}
            </div>
            <div className="text-500 fs--1">{formatDate(vehicle?.maxRemainingEnergy) || ''}</div>
          </CardBody>
        </Card>

        <SimpleCard title="Battery Size" value={formatNumber(vehicle?.batteryCapacity, 1)} unit="kWh" />
      </div>

      <CurrentsGraph queryKey={`vehicles/${vehicle.id}`} className="mb-3" />

      <StateOfHealthGraph vehicle={vehicle} className="mb-3" />

      <BatteryCapacityGraph vehicle={vehicle} className="mb-3" />

      <hr />

      {vehicle?.relation?.id ? (
        <TimeRangeDetails vehicle={vehicle} />
      ) : (
        <Alert color="warning" className="text-center mb-0">
          Vehicle is not connected to any device.
        </Alert>
      )}

      <hr />

      {isAdmin && !isDemoMode && (
        <>
          <LogsCard
            title="History"
            queryKey={`vehicles/${vehicle.id}/logs`}
            storeKey={`vehicles/${vehicle.id}/history`}
            entries={10}
            defaultOrder="-recordedTimestamp"
            defaultFilter={{
              eventType: [
                'IMSI',
                'VIN',
                'REBOOT_RESPONSE',
                'IS_DONGLE_PLUGGED_IN',
                'OBD_CALIBRATION_VERIFICATION_NUMBER',
                'OBD_CALIBRATION_ID'
              ]
            }}
          />

          <LogsCard queryKey={`vehicles/${vehicle.id}/logs`} defaultOrder="-recordedTimestamp" />

          <RelationHistoryCard
            queryKey="relations"
            storeKey={`vehicles/${vehicle.id}/relations`}
            defaultFilter={{ vehicleId: vehicle.id }}
          />
        </>
      )}
      <FormModal
        open={showModal}
        form={VehicleForm}
        data={vehicle}
        title="Vehicle"
        onClose={newData => {
          if (newData) {
            refetch();
          }
          setShowModal(false);
        }}
      />
    </>
  );
};

export default VehicleDetails;

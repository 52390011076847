import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Media } from 'reactstrap';
import Flex from "./Flex";

const WidgetsSectionTitle = ({ title, subtitle, className, icon, transform, buttons }) => (
  <Media className={className}>
    {icon && (
      <span className="fa-stack ml-n1 mr-2">
        <FontAwesomeIcon icon="circle" className="text-300 fa-stack-2x" />
        <FontAwesomeIcon icon={icon} transform={transform} className="text-primary fa-stack-1x" inverse />
      </span>
    )}
    <Media body>
      <Media heading tag="h5" className="text-primary position-relative mb-0">
        <Flex justify="between">
          <span className="bg-body pr-3">{title}</span>
          {buttons && <div className="bg-body pl-3">{buttons}</div>}
        </Flex>
        <span className="border position-absolute absolute-vertical-center w-100 z-index--1 l-0" />
      </Media>
      {subtitle && <p className="mb-0">{subtitle}</p>}
    </Media>
  </Media>
);

WidgetsSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string.isRequired,
  icon: PropTypes.string,
  transform: PropTypes.string
};

WidgetsSectionTitle.defaultProps = {
  title: 'Your Title',
  className: 'mt-6 mb-4'
};

export default WidgetsSectionTitle;

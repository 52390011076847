import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input, FormGroup, Alert } from 'reactstrap';
import { usePost } from '../request';
import { useStoreState } from '../stores/SettingsStore';
import Flex from './common/Flex';

const AccessTokenModal = ({ group, onClose }) => {
  const [{ isAdmin }] = useStoreState('currentUser');
  const post = usePost(`groups/${group?.id}/token/renew`);
  const postDelete = usePost(`groups/${group?.id}/token/remove`);
  const [token, setToken] = useState('');

  const renewToken = async () => {
    const newData = await post.send();
    if (!newData.isError) {
      setToken(newData.token);
    }
  };
  const removeToken = async () => {
    const newData = await postDelete.send();
    if (!newData.isError) {
      setToken('');
    }
  };

  useEffect(() => {
    if (group) {
      setToken(group.token || '');
    }
  }, [group]);

  return (
    <Modal isOpen={!!group} centered>
      <ModalHeader>Access Token</ModalHeader>
      <ModalBody>
        {post.isError && (
          <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
        )}
        {postDelete.isError && (
          <Alert color="danger">
            {postDelete.error?.data?.message || postDelete.error?.message || 'An error occurred.'}
          </Alert>
        )}
        <FormGroup>
          <Label sm={6}>Access Token</Label>
          <Input readOnly name="token" value={token} />
        </FormGroup>

        {isAdmin && (
          <>
            <hr />
            <Alert color="info">
              You can generate a new access token or remove the current token. Keep in mind, that the current token
              becomes immediately invalid in both cases.
            </Alert>
            <Flex justify="between" align="center">
              <Button
                disabled={post.isLoading || postDelete.isLoading}
                color="falcon-primary"
                onClick={() => renewToken()}
              >
                Renew Token
              </Button>
              <Button
                disabled={post.isLoading || postDelete.isLoading}
                color="falcon-danger"
                onClick={() => removeToken()}
              >
                Remove Token
              </Button>
            </Flex>
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button outline color="falcon-default" onClick={() => onClose(post.data !== null || postDelete.data !== null)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AccessTokenModal.propTypes = {
  group: PropTypes.object,
  onClose: PropTypes.func
};

export default AccessTokenModal;

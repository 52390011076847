import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import CardSummary from '../CardSummary';
import { useFetch } from '../../request';
import { formatNumber } from '../../helpers/utils';
import Loader from '../common/Loader';

const TripStats = ({ from, to }) => {
  const fetch = useFetch();
  const { data, isLoading, isFetching } = useQuery(['trips/stats', { from, to }], fetch);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <Row className="row-cols-1 row-cols-sm-2 row-cols-lg-4 card-gutter-wrapper align-items-stretch">
      <Col className="mb-3 card-gutter">
        <CardSummary title="Number of Rides" color="info">
          {formatNumber(data?.rides, 0) || 0}
        </CardSummary>
      </Col>
      <Col className="mb-3 card-gutter">
        <CardSummary title="Consumption" color="info">
          {formatNumber(data?.consumption, 1) || 0} kWh
        </CardSummary>
      </Col>
      <Col className="mb-3 card-gutter">
        <CardSummary title="Number of Chargings" color="success">
          {formatNumber(data?.chargings, 0) || 0}
        </CardSummary>
      </Col>
      <Col className="mb-3 card-gutter">
        <CardSummary title="Total Energy charged" color="success">
          {formatNumber(data?.energyCharged, 1) || 0} kWh
        </CardSummary>
      </Col>
    </Row>
  );
};

TripStats.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string
};

export default TripStats;

import React, { useState } from 'react';
import { format } from 'date-fns';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { textFilter } from 'react-bootstrap-table2-filter';
import { formatSeconds } from '../helpers/utils';

const ChargingElcinstation = () => {
  const [params, setParams] = useState({
    page: 1,
    size: 15,
    order: '-createdAt'
  });

  const columns = [
    {
      dataField: 'station',
      text: 'Station',
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.station?.iLike
      })
    },
    {
      dataField: 'currentSoc',
      text: 'Current SOC',
      sort: true
    },
    {
      dataField: 'wantedSoc',
      text: 'Wanted SOC',
      sort: true
    },
    {
      dataField: 'wantedEndTime',
      text: 'Departure time',
      formatter: dataField => format(new Date(dataField), 'dd/MM HH:mm'),
      sort: true
    },
    {
      dataField: 'duration',
      text: 'Duration',
      formatter: dataField => formatSeconds(dataField || 0),
      sort: true
    },
    {
      dataField: 'batteryCapacity',
      text: 'Capacity',
      sort: true
    },
    {
      dataField: 'chargingPower',
      text: 'Charging power',
      sort: true
    },
    {
      dataField: 'os',
      text: 'Submitted from',
      formatter: dataField => (['ios', 'android'].includes((dataField || '').toLowerCase()) ? dataField : '')
    },
    {
      dataField: 'createdAt',
      formatter: dataField => format(new Date(dataField), 'dd/MM HH:mm'),
      text: 'Submitted',
      sort: true
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="ElCiN Station" className="mb-4 mt-3 align-items-center" />
      <CardTable params={params} setParams={setParams} queryKey="neuss/charging-elcinstation" columns={columns} />
    </>
  );
};

export default ChargingElcinstation;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import { usePost } from '../../request';
import Loader from '../common/Loader';

const UnlinkDeviceModal = ({ relation, onClose }) => {
  const post = usePost(`relations/${relation?.id}/disable`);
  const unlink = async () => {
    const newData = await post.send();
    if (!newData.isError) {
      onClose(newData);
    }
  };

  return (
    <Modal isOpen={!!relation} keyboard={false} centered>
      <ModalHeader>Unlink Device</ModalHeader>
      <ModalBody>
        {post.isError && (
          <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
        )}
        {post.isLoading ? (
          <Loader />
        ) : (
          <>
            <Alert color="warning">Sie sind dabei das Gerät zu deaktivieren.</Alert>
            <p>
              Nach der Deaktivierung kann das Gerät nicht mehr vom Fahrer oder Studio-User verwendet werden, das heißt
              die Daten zur Auswertung sind nicht mehr sichtbar. Das Gerät ist anschließend frei zur Neuregistrierung
              für einen Fahrer oder zur Übertragung in eine andere Gruppe.
            </p>
            <p>
              Wird das Gerät während der Deaktivierung verwendet, werden zwar Lade- und Fahrvorgänge aufgezeichnet,
              jedoch sind diese weder von einem zukünftigen Fahrer noch Studio-User einsehbar.
            </p>
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button disabled={post.isLoading} outline color="falcon-default" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button disabled={post.isLoading} color="falcon-primary" onClick={() => unlink()}>
          Unlink device
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UnlinkDeviceModal.propTypes = {
  relation: PropTypes.object,
  onClose: PropTypes.func
};

export default UnlinkDeviceModal;

import React from 'react';
import { Link } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { textFilter } from 'react-bootstrap-table2-filter';
import { format } from 'date-fns';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { formatNumber, formatUser } from '../helpers/utils';
import { useStoreState } from '../stores/RequestStore';
import ButtonIcon from '../components/common/ButtonIcon';
import FalconProgress from '../components/common/FalconProgress';
import Flex from '../components/common/Flex';
import { Badge } from 'reactstrap';

const ChargingReport = () => {
  const [params, setParams] = useStoreState('trips/charging-report', {
    order: 'soc'
  });

  const columns = [
    {
      dataField: 'brandName',
      text: 'Brand',
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.brandName?.iLike
      })
    },
    {
      dataField: 'modelName',
      text: 'Model',
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.modelName?.iLike
      })
    },
    {
      dataField: 'numberPlate',
      text: 'Number plate',
      formatter: dataField => <span className="demo-blur">{dataField}</span>
    },
    {
      dataField: 'driverId',
      text: 'Driver',
      formatter: (dataField, entry) =>
        dataField ? (
          <Link className="text-700 demo-blur" to={`/drivers/${dataField}`}>
            {formatUser(entry)}
          </Link>
        ) : (
          ''
        )
    },
    {
      dataField: 'soc',
      text: 'SOC',
      sort: true,
      headerStyle: { width: 120 },
      formatter: dataField => {
        if (isNil(dataField)) {
          return '– %';
        }
        return (
          <Flex align="center" justify="between">
            <FalconProgress
              value={dataField}
              color="success"
              className="w-100 rounded-soft"
              barClassName="rounded-capsule"
              style={{ height: 5, width: 54 }}
            />
            <div className="ml-2">{formatNumber(dataField, 0)}%</div>
          </Flex>
        );
      }
    },
    {
      dataField: 'remainingRange',
      text: 'Range',
      sort: true,
      formatter: dataField => `${formatNumber(dataField, 0) || '–'} km`
    },
    {
      dataField: 'distanceThreeDays',
      text: 'Distance last 3 days',
      sort: true,
      headerStyle: { width: 90 },
      formatter: dataField => `${formatNumber(dataField, 0) || '–'} km`
    },
    {
      dataField: 'distanceOneWeek',
      text: 'Distance last 7 days',
      sort: true,
      headerStyle: { width: 90 },
      formatter: dataField => `${formatNumber(dataField, 0) || '–'} km`
    },
    {
      dataField: 'startTime',
      text: 'Latest Charging',
      sort: true,
      formatter: (dataField, entry) =>
        entry.endTime === null ? (
          <Badge color="soft-success" className="rounded-capsule">
            active
          </Badge>
        ) : (
          format(new Date(dataField), 'dd/MM/yyyy HH:mm')
        )
    },
    {
      formatter: (dataField, entry) => (
        <>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="eye"
            className="fs--1 text-600 px-2 ml-2"
            iconClassName="fs--1"
            tag={Link}
            to={`/activities/${entry.tripId}`}
            title="View details"
          />
        </>
      ),
      align: 'right'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Charging Report" className="mb-4 mt-3 align-items-center" icon="charging-station" />
      <CardTable params={params} setParams={setParams} queryKey="trips/charging-report" columns={columns} />
    </>
  );
};

export default ChargingReport;

import React from 'react';
import { Nav } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
// import NotificationDropdown from './NotificationDropdown';

const TopNavRightSideNavItem = () => {
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      {/*<NotificationDropdown />*/}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;

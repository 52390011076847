import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Flex from '../common/Flex';
import { numberFormatter } from '../../helpers/utils';

const WeatherCard = ({ className, trip }) => {
  const windType = useMemo(() => {
    if (isNil(trip?.weather?.windDeg) || isNil(trip?.avgCourse)) {
      return false;
    }
    const { windDeg } = trip.weather;
    const course = Math.round(trip.avgCourse);

    // contrary wind: 90deg in front of the vehicle
    if (windDeg >= (course + 360 - 45) % 360 && windDeg <= (course + 45) % 360) {
      return 'contrary';
    }
    // following wind: 90deg in the back
    if (windDeg >= (course + 180 - 45) % 360 && windDeg <= (course + 180 + 45) % 360) {
      return 'following';
    }
    return 'contrary';
  }, [trip]);

  const isRide = trip.type === 'ride';
  return (
    <Card className={className}>
      <FalconCardHeader className="pb-0" title="Weather" light={false} titleTag="h6" />
      {!trip.weather ? (
        <CardBody tag={Flex} align="center">
          <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif">n/a</div>
        </CardBody>
      ) : (
        <CardBody tag={Flex}>
          <div className="mr-2">
            <div className="rounded-circle p-1" style={{ width: 40, height: 40, backgroundColor: '#0E171F' }}>
              <img className="w-100" src={`/assets/img/weather/${trip.weather.icon}@3x.png`} alt="" />
            </div>
          </div>
          <div>
            <div className="display-4 fs-4 mb-2 font-weight-normal">
              {numberFormatter(trip.weather.temperature, 1)} °C
            </div>
            {isRide && windType !== false && <div className="text-600">Trip with {windType} wind.</div>}
          </div>
        </CardBody>
      )}
    </Card>
  );
};

WeatherCard.propTypes = { className: PropTypes.string, trip: PropTypes.object.isRequired };

export default WeatherCard;

import React from 'react';
import { Redirect } from 'react-router-dom';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { useFetch } from '../request';
import { useQuery } from 'react-query';
import { Alert } from 'reactstrap';
import Loader from '../components/common/Loader';
import DetailsCharge from '../components/trip/DetailsCharge';
import DetailsRide from '../components/trip/DetailsRide';

const TripDetails = ({ match }) => {
  const { id } = match.params;
  const fetch = useFetch();
  const { data, isLoading, isError, error } = useQuery(`trips/${id}`, fetch, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: id
  });
  if (!id) {
    return <Redirect to={`/trips`} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <Alert color="danger" className="text-center mt-4">
        An error occurred during the request. Status code {error.status}.
      </Alert>
    );
  }
  const isRide = data.type === 'ride';

  return (
    <>
      <WidgetsSectionTitle
        title={`${isRide ? 'Ride' : 'Charge'} Details`}
        className="mb-4 mt-3 align-items-center"
        icon={isRide ? 'flag' : 'charging-station'}
      />
      {isRide ? <DetailsRide trip={data} /> : <DetailsCharge trip={data} />}
    </>
  );
};

export default TripDetails;

import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { format } from 'date-fns';
import { formatNumber } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import CardTable from '../../components/CardTable';
import { useStoreState } from '../../stores/RequestStore';
import PageHeaderWithTimeRange from '../../components/common/PageHeaderWithTimeRange';

const columns = params => {
  return [
    {
      dataField: 'deviceId',
      text: 'Device',
      formatter: dataField => (
        <Link className="text-700" to={`/devices/${dataField}`}>
          {dataField}
        </Link>
      ),
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.deviceId?.iLike
      })
    },
    {
      dataField: 'startTime',
      text: 'Start date',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      dataField: 'distance',
      text: 'GPS Distance',
      formatter: dataField => `${formatNumber(dataField, 2) || '–'} km`
    },
    {
      dataField: 'distance',
      text: 'GPS Distance (rounded)',
      formatter: dataField => `${formatNumber(dataField, 0) || '–'} km`
    },
    {
      dataField: 'startMileage',
      text: 'Mileage difference',
      formatter: (dataField, entry) =>
        dataField && entry.endMileage ? `${formatNumber(entry.endMileage - dataField)} km` : '–'
    },
    {
      dataField: 'startMileage',
      text: 'Difference',
      formatter: (dataField, entry) =>
        dataField && entry.endMileage
          ? `${formatNumber((1 - (entry.endMileage - dataField) / entry.distance) * -100)} %`
          : '– %'
    },
    {
      formatter: (dataField, { id }) => (
        <Link to={`/activities/${id}`} className="fs--1 text-600 px-2">
          <FontAwesomeIcon icon="eye" className="fs--1" />
        </Link>
      ),
      align: 'right'
    }
  ];
};

const initStart = moment.utc().subtract(1, 'month');
const initEnd = moment.utc();

const DistanceDifference = () => {
  const [params, setParams] = useStoreState('distancediff', {
    page: 1,
    size: 30,
    order: '-startTime',
    filter: {
      type: 'ride',
      endTime: { not: 'null' },
      startTime: {
        gte: initStart.toISOString(),
        lte: initEnd.toISOString()
      }
    }
  });

  const submitDateFilter = ({ startDate, endDate }) => {
    const filter = { ...params.filter };
    filter.startTime = {
      gte: startDate,
      lte: endDate
    };
    setParams({ ...params, filter });
  };

  return (
    <>
      <PageHeaderWithTimeRange
        title="Distance Difference"
        defaultStartDate={params.filter?.startTime?.gte}
        defaultEndDate={params.filter?.startTime?.lte}
        onSubmit={submitDateFilter}
      />
      <CardTable
        params={params}
        setParams={setParams}
        queryKey="control-center/distance-difference"
        columns={columns(params)}
      />
    </>
  );
};

export default DistanceDifference;

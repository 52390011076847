import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useFetch } from '../request';
import { Alert, Button, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Loader from '../components/common/Loader';
import DriverCard from '../components/trip/DriverCard';
import Datetime from 'react-datetime';
import PageHeaderSplit from '../components/common/PageHeaderSplit';
import moment from 'moment';
import { useStoreState } from '../stores/RequestStore';
import ActivityWeeklyHeatmap from '../components/driver/ActivityWeeklyHeatmap';
import HelpTooltip from '../components/HelpTooltip';
import EcoScoresHistoryChart from '../components/driver/EcoScoresHistoryChart';
import VehicleCard from '../components/trip/VehicleCard';

const initStart = moment.utc().startOf('month');
const initEnd = moment.utc().endOf('month');

const DriverReport = ({ match }) => {
  const { id } = match.params;
  const fetch = useFetch();
  const { data: driver, isLoading, isError, error } = useQuery(`drivers/${id}`, fetch, {
    enabled: id
  });
  const [params, setParams] = useStoreState(`drivers/${id}/report`, {
    from: initStart.toISOString(),
    to: initEnd.toISOString()
  });
  const [startDate, setStartDate] = useState(moment.utc(params.from));
  const [endDate, setEndDate] = useState(moment.utc(params.to));

  if (!id) {
    return <Redirect to={`/drivers`} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <Alert color="danger" className="text-center mt-4">
        An error occurred during the request. Status code {error.status}.
      </Alert>
    );
  }

  const submitDateFilter = e => {
    e.preventDefault();
    setParams({ from: startDate.toISOString(), to: endDate.toISOString() });
  };

  return (
    <>
      <PageHeaderSplit title="Driver Behavior Report" className="mb-3">
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <InputGroup className="flex-nowrap">
              <Datetime
                closeOnSelect
                isValidDate={current => current.isBefore(endDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={startDate}
                onChange={setStartDate}
                inputProps={{ className: 'form-control border-right-0 rounded-right-0' }}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>-</InputGroupText>
              </InputGroupAddon>
              <Datetime
                closeOnSelect
                isValidDate={current => current.isAfter(startDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={endDate}
                onChange={setEndDate}
                inputProps={{ className: 'form-control rounded-left-0' }}
                className="calendar-right"
              />
            </InputGroup>
          </FormGroup>
          <Button onClick={submitDateFilter} color="falcon-default">
            Apply
          </Button>
        </Form>
      </PageHeaderSplit>
      <div className="card-deck align-items-stretch mb-3">
        <DriverCard deviceId={driver?.relation?.deviceId} driver={driver} />
        <VehicleCard deviceId={driver?.relation?.deviceId} vehicle={driver?.relation?.vehicle} className="h-100" />
      </div>

      <ActivityWeeklyHeatmap
        className="mb-3"
        from={params.from}
        to={params.to}
        driverId={driver.id}
        type="charge"
        unit="kWh"
        title={
          <>
            Chargings Weekly Heatmap{' '}
            <HelpTooltip id="chargeHeatmapTip">
              Total kWh charged associated with day and start time of the charging session
            </HelpTooltip>
          </>
        }
      />

      <ActivityWeeklyHeatmap
        className="mb-3"
        from={params.from}
        to={params.to}
        driverId={driver.id}
        type="ride"
        unit="km"
        title={
          <>
            Rides Weekly Heatmap{' '}
            <HelpTooltip id="rideHeatmapTip">
              Total km driven associated with day and start time of the ride
            </HelpTooltip>
          </>
        }
      />

      <EcoScoresHistoryChart driverId={driver.id} />
    </>
  );
};

export default DriverReport;

import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { useFetch } from '../request';
import Loader from '../components/common/Loader';
import SimpleCard from '../components/SimpleCard';
import DriverCard from '../components/trip/DriverCard';
import LogsCard from '../components/trip/LogsCard';
import { useStoreState } from '../stores/SettingsStore';
import RelationHistoryCard from '../components/RelationHistoryCard';
import VehicleCard from '../components/trip/VehicleCard';

const DeviceDetails = ({ match }) => {
  const { id } = match.params;
  const fetch = useFetch();
  const [{ isAdmin = false }] = useStoreState('currentUser');
  const [isDemoMode] = useStoreState('demoMode');
  const { data: device, isLoading, isError, error } = useQuery(`devices/${id}`, fetch, {
    enabled: id
  });
  if (!id) {
    return <Redirect to={`/devices`} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <Alert color="danger" className="text-center mt-4">
        An error occurred during the request. Status code {error.status}.
      </Alert>
    );
  }

  return (
    <>
      <WidgetsSectionTitle title={`Device`} className="mb-4 mt-3 align-items-center" icon="plug" />

      <div className="card-deck align-items-stretch mb-3">
        <SimpleCard title="Device ID" value={device?.id} />
        <SimpleCard title="Firmware" value={device?.firmware} />
        <SimpleCard title="Hardware" value={device?.hardware} />
        <SimpleCard
          title="Latest Event"
          value={!device.latestEvent ? null : format(new Date(device.latestEvent), 'dd/MM/yyyy HH:mm')}
        />
      </div>

      <Row className="card-gutter-wrapper align-items-stretch">
        <Col md={3} className="card-gutter mb-3">
          <SimpleCard title="IMSI" value={device?.imsi} className="h-100" />
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <VehicleCard deviceId={device.id} vehicle={device?.relation?.vehicle} className="h-100" />
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <DriverCard driver={device?.relation?.driver} className="h-100" />
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <SimpleCard title="Notes" value={device?.notes} className="h-100" />
        </Col>
      </Row>

      {isAdmin && !isDemoMode && (
        <>
          <LogsCard
            title="History"
            queryKey={`devices/${device.id}/logs`}
            entries={10}
            defaultOrder="-recordedTimestamp"
            defaultFilter={{
              eventType: [
                'IMSI',
                'VIN',
                'FIRMWARE_VERSION',
                'REBOOT_RESPONSE',
                'IS_DONGLE_PLUGGED_IN',
                'OBD_CALIBRATION_VERIFICATION_NUMBER',
                'OBD_CALIBRATION_ID'
              ]
            }}
          />

          <LogsCard
            title="OBD History"
            queryKey={`devices/${device.id}/logs`}
            storeKey={`devices/${device.id}/history`}
            entries={10}
            defaultOrder="-recordedTimestamp"
            defaultFilter={{
              eventType: ['OBD_DTC_COUNT', 'OBD_CALIBRATION_ID', 'CALIBRATION_GRAVITY']
            }}
          />

          <RelationHistoryCard
            queryKey="relations"
            storeKey={`devices/${device.id}/relations`}
            defaultFilter={{ deviceId: device.id }}
          />
        </>
      )}
    </>
  );
};

export default DeviceDetails;

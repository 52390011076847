import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';

const PageHeaderSplit = ({ title, titleTag: TitleTag, col, children, ...rest }) => (
  <Card {...rest}>
    <CardBody>
      <Row className="justify-content-between">
        <Col xs="auto">
          <TitleTag className="mb-0">{title}</TitleTag>
        </Col>
        {children && <Col xs="auto">{children}</Col>}
      </Row>
    </CardBody>
  </Card>
);

PageHeaderSplit.propTypes = {
  title: PropTypes.string.isRequired,
  titleTag: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  className: PropTypes.string,
  children: PropTypes.node
};

PageHeaderSplit.defaultProps = { col: {}, titleTag: 'h3' };

export default PageHeaderSplit;

import { Store } from 'pullstate';

export const SettingsStore = new Store({
  isNavbarCollapsed: true,
  currentUser: null,
  demoMode: false
});

export const useStoreState = key => {
  const params = SettingsStore.useState(s => s[key]);
  const setParams = newParams => {
    SettingsStore.update(s => {
      s[key] = newParams;
    });
  };
  return [params, setParams];
};

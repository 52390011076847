import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import defaultTo from 'lodash/defaultTo';
import mapValues from 'lodash/mapValues';
import NumberFormat from 'react-number-format';
import FalconCardHeader from '../common/FalconCardHeader';
import {
  Card,
  CardBody,
  Row,
  Col,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  CustomInput
} from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { formatCurrency } from '../../helpers/utils';
import { Controller, useForm } from 'react-hook-form';
import { normalizeError, usePatch, usePost } from '../../request';
import RadioGroup from '../form/RadioGroup';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const formatBool = val => {
  if (isNil(val)) return '–';
  return val ? 'available' : 'unavailable';
};
const formatBoolYesNo = val => {
  if (isNil(val)) return '–';
  return val ? 'yes' : 'no';
};

const EditAddressForm = ({ address, onUpdate }) => {
  const patch = usePatch(`driver-addresses/${address.id}/address`, { throwOnError: true });
  const { control, handleSubmit, errors, setError } = useForm();

  const update = async data => {
    try {
      const newData = await patch.send(data);
      onUpdate(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(update)}>
      {(patch?.error?.message || patch?.error?.data?.message) && (
        <Alert color="danger">{patch?.error?.message || patch?.error?.data?.message}</Alert>
      )}
      <Row form>
        <Col sm tag={FormGroup}>
          <Label for="street">Street / No.*</Label>
          <Controller
            as={<Input invalid={errors?.street} />}
            control={control}
            name="street"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(address?.street, '')}
          />
          <FormFeedback>{errors?.street?.message}</FormFeedback>
        </Col>
      </Row>
      <Row form>
        <Col sm={4} tag={FormGroup}>
          <Label for="zip">ZIP*</Label>
          <Controller
            as={<Input invalid={errors?.zip} />}
            control={control}
            name="zip"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(address?.zip, '')}
          />
          <FormFeedback>{errors?.zip?.message}</FormFeedback>
        </Col>
        <Col sm={8} tag={FormGroup}>
          <Label for="city">City*</Label>
          <Controller
            as={<Input invalid={errors?.city} />}
            control={control}
            name="city"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(address?.city, '')}
          />
          <FormFeedback>{errors?.city?.message}</FormFeedback>
        </Col>
      </Row>
      <Row form>
        <Col sm={6} tag={FormGroup}>
          <Label for="state">State</Label>
          <Controller as={<Input />} control={control} name="state" defaultValue={defaultTo(address?.state, '')} />
        </Col>
        <Col sm={6} tag={FormGroup}>
          <Label for="country">Country*</Label>
          <Controller
            as={<Input invalid={errors?.country} />}
            control={control}
            name="country"
            rules={{ required: 'Required' }}
            defaultValue={defaultTo(address?.country, '')}
          />
          <FormFeedback>{errors?.country?.message}</FormFeedback>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row className="justify-content-between">
        <Col xs={6} md={5} lg={4}>
          <Button color="falcon-default" disabled={patch.isLoading} onClick={() => onUpdate(false)} block>
            Cancel
          </Button>
        </Col>
        <Col xs={6} md={5} lg={4}>
          <Button color="falcon-primary" disabled={patch.isLoading} type="submit" block>
            Apply
          </Button>
        </Col>
      </Row>
      <hr className="d-md-none mb-3" />
    </Form>
  );
};

const EditSettingsForm = ({ address, onUpdate }) => {
  const patch = usePatch(`driver-addresses/${address.id}`, { throwOnError: true });
  const { control, handleSubmit, errors, setError } = useForm();

  const update = async data => {
    try {
      const updateData = mapValues(data, value => {
        switch (value) {
          case 'true':
            return true;
          case 'false':
            return false;
          case '':
            return null;
          default:
            return value;
        }
      });
      if (updateData.kwhPrice) updateData.kwhPrice = parseFloat(updateData.kwhPrice);
      if (updateData.monthlyFee) updateData.monthlyFee = parseFloat(updateData.monthlyFee);
      const newData = await patch.send(updateData);
      onUpdate(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit(update)}>
      {(patch?.error?.message || patch?.error?.data?.message) && (
        <Alert color="danger">{patch?.error?.message || patch?.error?.data?.message}</Alert>
      )}
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Utility Services Provider
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.energyProvider} />}
            control={control}
            name="energyProvider"
            defaultValue={defaultTo(address?.energyProvider, '')}
          />
          <FormFeedback>{errors?.energyProvider?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Price per kWh
        </Label>
        <Col>
          <InputGroup>
            <Controller
              as={
                <NumberFormat
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator={true}
                  customInput={Input}
                />
              }
              control={control}
              name="kwhPrice"
              defaultValue={defaultTo(address?.kwhPrice, '')}
            />
            <InputGroupAddon addonType="append">€</InputGroupAddon>
          </InputGroup>
          <FormFeedback>{errors?.kwhPrice?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Monthly Basic Fee
        </Label>
        <Col>
          <InputGroup>
            <Controller
              as={
                <NumberFormat
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator={true}
                  customInput={Input}
                />
              }
              control={control}
              name="monthlyFee"
              defaultValue={defaultTo(address?.monthlyFee, '')}
            />
            <InputGroupAddon addonType="append">€</InputGroupAddon>
          </InputGroup>
          <FormFeedback>{errors?.monthlyFee?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          PV System
        </Label>
        <Col className="py-1">
          <Controller
            as={<RadioGroup options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]} />}
            name="photovoltaicConstruction"
            control={control}
            defaultValue={isNil(address?.photovoltaicConstruction) ? '' : address.photovoltaicConstruction.toString()}
          />
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Battery Storage
        </Label>
        <Col className="py-1">
          <Controller
            as={<RadioGroup options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]} />}
            name="energyStorage"
            control={control}
            defaultValue={isNil(address?.energyStorage) ? '' : address.energyStorage.toString()}
          />
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Underground Car Park
        </Label>
        <Col className="py-1">
          <Controller
            as={<RadioGroup options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]} />}
            name="garage"
            control={control}
            defaultValue={isNil(address?.garage) ? '' : address.garage.toString()}
          />
        </Col>
      </FormGroup>
      <hr className="mt-0" />
      <Row className="justify-content-between">
        <Col xs={6} md={5} lg={4}>
          <Button color="falcon-default" disabled={patch.isLoading} onClick={() => onUpdate(false)} block>
            Cancel
          </Button>
        </Col>
        <Col xs={6} md={5} lg={4}>
          <Button color="falcon-primary" disabled={patch.isLoading} type="submit" block>
            Apply
          </Button>
        </Col>
      </Row>
      <hr className="d-md-none mb-3" />
    </Form>
  );
};

const AddressCard = ({ className, type, address: initData, driverId }) => {
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [address, setAddress] = useState(initData);
  const post = usePost(`drivers/${driverId}/address`);
  const patch = usePatch(`driver-addresses/${address?.id}`);

  const onUpdate = newData => {
    if (newData) {
      setAddress({
        ...address,
        ...newData
      });
    }
    if (isAddressEdit) setIsAddressEdit(false);
    if (isEdit) setIsEdit(false);
  };

  const createAddress = async () => {
    const newData = await post.send({ type });
    if (!newData.isError) {
      setAddress(newData);
    }
  };

  const updateAddressState = async canCharge => {
    const newData = await patch.send({ canCharge });
    if (!newData.isError) {
      setAddress(newData);
    }
  };

  return (
    <Card className={className}>
      <FalconCardHeader title={upperFirst(type)} titleTag="h5" className={!address?.id ? 'rounded-soft' : undefined}>
        {!address?.id ? (
          <ButtonIcon
            disabled={post.isLoading}
            color="falcon-default"
            size="sm"
            icon="plus"
            onClick={() => createAddress()}
            iconClassName="fs--2"
          >
            Create
          </ButtonIcon>
        ) : (
          <CustomInput
            disabled={patch.isLoading}
            type="switch"
            id={`${address.id}-canCharge`}
            name="canCharge"
            checked={address.canCharge}
            value="1"
            onChange={() => updateAddressState(!address.canCharge)}
          />
        )}
      </FalconCardHeader>
      {address?.id && (
        <CardBody>
          {!address?.canCharge && (
            <Alert color="info" className="text-center rounded-0 mb-0">
              The charging address is disabled.
            </Alert>
          )}
          <Row>
            <Col md={6}>
              {isAddressEdit ? (
                <EditAddressForm address={address} onUpdate={onUpdate} />
              ) : (
                <>
                  <Row className={classes.row}>
                    <Col className="text-center mb-2">
                      <ButtonIcon
                        color="falcon-default"
                        size="sm"
                        icon="pencil-alt"
                        onClick={() => setIsAddressEdit(!isAddressEdit)}
                        iconClassName="fs--2"
                      >
                        Edit address
                      </ButtonIcon>
                    </Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Street / No.
                    </Col>
                    <Col className="demo-blur">{address?.street || '–'}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      ZIP
                    </Col>
                    <Col className="demo-blur">{address?.zip || '–'}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      City
                    </Col>
                    <Col className="demo-blur">{address?.city || '–'}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      State
                    </Col>
                    <Col>{address?.state || '–'}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Country
                    </Col>
                    <Col>{address?.country || '–'}</Col>
                  </Row>
                </>
              )}
            </Col>
            <Col md={6}>
              {isEdit ? (
                <EditSettingsForm address={address} onUpdate={onUpdate} />
              ) : (
                <>
                  <Row className={classes.row}>
                    <Col className="text-center mb-2">
                      <ButtonIcon
                        color="falcon-default"
                        size="sm"
                        icon="pencil-alt"
                        onClick={() => setIsEdit(!isEdit)}
                        iconClassName="fs--2"
                      >
                        Edit settings
                      </ButtonIcon>
                    </Col>
                  </Row>

                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Charging Station
                    </Col>
                    <Col>{formatBool(!isNil(address?.wallboxModelId))}</Col>
                  </Row>
                  {address?.wallboxModelId && (
                    <>
                      <Row className={classes.row}>
                        <Col xs={6} className={classes.colLabel}>
                          Charging Station Brand
                        </Col>
                        <Col>{address?.wallboxModel?.brand?.label || '–'}</Col>
                      </Row>
                      <Row className={classes.row}>
                        <Col xs={6} className={classes.colLabel}>
                          Charging Station Model
                        </Col>
                        <Col>{address?.wallboxModel?.label || '–'}</Col>
                      </Row>
                    </>
                  )}
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Utility Services Provider
                    </Col>
                    <Col>{address?.energyProvider || '–'}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Price per kWh
                    </Col>
                    <Col>{address?.kwhPrice === 0 ? 'free' : `${formatCurrency(address?.kwhPrice) || '–'} €`}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Monthly Basic Fee
                    </Col>
                    <Col>{formatCurrency(address?.monthlyFee) || '–'} €</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      PV System
                    </Col>
                    <Col>{formatBoolYesNo(address?.photovoltaicConstruction)}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Battery Storage
                    </Col>
                    <Col>{formatBoolYesNo(address?.energyStorage)}</Col>
                  </Row>
                  <Row className={classes.row}>
                    <Col xs={6} className={classes.colLabel}>
                      Underground Car Park
                    </Col>
                    <Col>{formatBoolYesNo(address?.garage)}</Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
};

AddressCard.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  driverId: PropTypes.string.isRequired,
  address: PropTypes.object
};

export default AddressCard;

import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody, Col, Row } from 'reactstrap';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const GroupSponsorCard = ({ className, relation, sponsor }) => {
  return (
    <Card className={className}>
      <FalconCardHeader title="Groups & Sponsor" titleTag="h5" />
      <CardBody>
        <Row className={classes.row}>
          <Col xs={6} className={classes.colLabel}>
            Sponsor
          </Col>
          <Col xs={6}>{sponsor?.name || '–'}</Col>
        </Row>
        <Row className={classes.row}>
          <Col xs={6} className={classes.colLabel}>
            Group
          </Col>
          <Col xs={6}>{relation?.group?.name || '–'}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

GroupSponsorCard.propTypes = {
  className: PropTypes.string,
  relation: PropTypes.object
};

export default GroupSponsorCard;

import React from 'react';
import { format } from 'date-fns';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getGrays, themeColors, getPosition, formatNumber } from '../../helpers/utils';

const getOption = (data, from, to) => {
  const grays = getGrays(false);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(entry) {
        return format(new Date(entry[0].axisValue), 'dd/MM HH:mm') + ' ' + formatNumber(entry[0].value[1]) + 'kWh';
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'time',
      // type: 'category',
      // data: labels,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      min: from,
      max: to,
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return format(new Date(value), 'dd/MM');
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      max: 100,
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        color: grays['400'],
        margin: 15,
        formatter: `{value} kWh`
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        stack: 'one',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.info,
          borderWidth: 0
        },
        hoverAnimation: true,
        data
      }
    ],
    grid: { right: '28px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const ConsumptionByTime = ({ data, from, to }) => {
  return (
    <ReactEchartsCore
      echarts={echarts}
      option={getOption(data.map(entry => [entry.startTime, entry.value]), from, to)}
      style={{ width: '100%', minHeight: '15rem', height: '100%' }}
    />
  );
};

export default ConsumptionByTime;

import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Form, Row, Col, FormGroup, Input, CustomInput, Label } from 'reactstrap';
import { usePost } from '../../request';
import { useAuth } from '../../context/auth';
import { useStoreState } from '../../stores/SettingsStore';

const LoginForm = ({ history, location }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [, setCurrentUser] = useStoreState('currentUser');
  const { setAuthToken } = useAuth();
  const post = usePost('login');

  const handleSubmit = async e => {
    e.preventDefault();
    setIsDisabled(true);
    const user = await post.send({ email, password });
    if (!user.isError) {
      setCurrentUser(user);
      setAuthToken(user.token);
      setIsDisabled(false);
      history.push(location?.state?.referrer ? location?.state?.referrer : '/');
    }
  };

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  return (
    <Form onSubmit={handleSubmit}>
      {post.isError && (
        <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
      )}
      <FormGroup>
        <Label>Email address</Label>
        <Input value={email} onChange={({ target }) => setEmail(target.value)} type="email" />
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <Input value={password} onChange={({ target }) => setPassword(target.value)} type="password" />
      </FormGroup>
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <CustomInput
            id="customCheckRemember"
            label="Remember me"
            checked={remember}
            onChange={({ target }) => setRemember(target.checked)}
            type="checkbox"
          />
        </Col>
        <Col xs="auto">
          <Link className="fs--1" to={`/forget-password`}>
            Forget Password?
          </Link>
        </Col>
      </Row>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>
    </Form>
  );
};

export default withRouter(LoginForm);

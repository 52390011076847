import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Datetime from 'react-datetime';
import PageHeaderSplit from './PageHeaderSplit';

const initStart = moment.utc().startOf('month');
const initEnd = moment.utc().endOf('month');

const PageHeaderWithTimeRange = ({ title, defaultStartDate, defaultEndDate, onSubmit }) => {
  const [startDate, setStartDate] = useState(moment.utc(defaultStartDate || initStart.toISOString()));
  const [endDate, setEndDate] = useState(moment.utc(defaultEndDate || initEnd.toISOString()));

  const submitDateFilter = e => {
    e.preventDefault();
    onSubmit({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    });
  };

  return (
    <PageHeaderSplit title={title} className="mb-3">
      <Form inline>
        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
          <InputGroup className="flex-nowrap">
            <Datetime
              closeOnSelect
              isValidDate={current => current.isBefore(endDate)}
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              value={startDate}
              onChange={setStartDate}
              inputProps={{ className: 'form-control border-right-0 rounded-right-0' }}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText>-</InputGroupText>
            </InputGroupAddon>
            <Datetime
              closeOnSelect
              isValidDate={current => current.isAfter(startDate)}
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              value={endDate}
              onChange={setEndDate}
              inputProps={{ className: 'form-control rounded-left-0' }}
              className="calendar-right"
            />
          </InputGroup>
        </FormGroup>
        <Button onClick={submitDateFilter} color="falcon-default">
          Apply
        </Button>
      </Form>
    </PageHeaderSplit>
  );
};

PageHeaderWithTimeRange.propTypes = {
  title: PropTypes.string.isRequired,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default PageHeaderWithTimeRange;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Badge } from 'reactstrap';
import { useQueryCache } from 'react-query';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { formatUser } from '../helpers/utils';
import ButtonIcon from '../components/common/ButtonIcon';
import FormModal from '../components/FormModal';
import UserForm from '../components/form/UserForm';
import Flex from '../components/common/Flex';
import {useStoreState} from "../stores/SettingsStore";

const columns = [
  {
    dataField: 'firstName',
    text: 'User',
    formatter: (dataField, entry) => formatUser(entry) || <i>Unknown</i>
  },
  {
    dataField: 'email',
    text: 'E-Mail'
  },
  {
    dataField: 'group.name',
    text: 'Group'
  },
  {
    dataField: 'lastActionAt',
    text: 'Last Action',
    formatter: (dataField, item) => format(new Date(dataField || item.updatedAt), 'dd/MM/yyyy HH:mm'),
    sort: true
  },
  {
    dataField: 'blockedAt',
    text: 'Blocked',
    formatter: dataField =>
      dataField ? (
        format(new Date(dataField), 'dd/MM/yyyy HH:mm')
      ) : (
        <Badge color="soft-success" className="rounded-capsule">
          enabled
          <FontAwesomeIcon icon="check" transform="shrink-2" className="ml-1" />
        </Badge>
      )
  },
  {
    formatter: (dataField, { id }) => (
      <Link to={`/users/${id}`} className="fs--1 text-600 px-2">
        <FontAwesomeIcon icon="eye" className="fs--1" />
      </Link>
    ),
    align: 'right'
  }
];

const Users = () => {
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    size: 15,
    order: 'firstName,lastName'
  });
  const queryCache = useQueryCache();
  const [currentUser] = useStoreState('currentUser');

  return (
    <>
      <WidgetsSectionTitle title="Users" className="mb-4 mt-3 align-items-center" />
      {currentUser?.isAdmin && (
        <Flex justify="end" className="mb-3">
          <ButtonIcon icon="plus" color="falcon-primary" onClick={() => setShowModal(true)}>
            New
          </ButtonIcon>
        </Flex>
      )}
      <CardTable params={params} setParams={setParams} queryKey="users" columns={columns} />
      <FormModal
        open={showModal}
        form={UserForm}
        title="User"
        onClose={newUser => {
          if (newUser) {
            queryCache.invalidateQueries('users');
          }
          setShowModal(false);
        }}
      />
    </>
  );
};

export default Users;

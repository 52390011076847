import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { format } from 'date-fns';
import { formatNumber } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import CardTable from '../../components/CardTable';
import { useStoreState } from '../../stores/RequestStore';
import PageHeaderWithTimeRange from '../../components/common/PageHeaderWithTimeRange';

const columns = params => {
  return [
    {
      dataField: 'relation.deviceId',
      text: 'Device',
      formatter: dataField =>
        dataField ? (
          <Link className="text-700" to={`/devices/${dataField}`}>
            {dataField}
          </Link>
        ) : (
          ''
        ),
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.['relation.deviceId']?.iLike
      })
    },
    {
      dataField: 'vin',
      text: 'VIN',
      formatter: (dataField, entry) =>
        dataField ? (
          <Link className="text-700" to={`/vehicles/${entry.id}`}>
            {dataField}
          </Link>
        ) : (
          ''
        ),
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.vin?.iLike
      })
    },
    {
      dataField: 'brandName',
      text: 'Brand (AA)',
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.brandName?.iLike
      })
    },
    {
      dataField: 'modelName',
      text: 'Model (AA)',
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.modelName?.iLike
      })
    },
    {
      dataField: 'soh',
      text: 'SOH (latest)',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`
    },
    {
      dataField: 'batteryCapacity',
      text: 'Battery Capacity (user input)',
      formatter: dataField => `${formatNumber(dataField) || '–'} kWh`
    },
    {
      dataField: 'batteryTotalCapacity',
      text: 'Battery Capacity (vehicle)',
      formatter: dataField => `${formatNumber(dataField) || '–'} kWh`
    },
    {
      dataField: 'avgSoc',
      text: 'Calculated Battery Total Capacity',
      formatter: (dataField, entry) =>
        dataField && entry.avgRemainingEnergy
          ? `${formatNumber((entry.avgRemainingEnergy / dataField) * 100)} kWh`
          : '– kWh'
    },
    {
      dataField: 'batteryCapacity2',
      text: 'Difference',
      formatter: (dataField, entry) =>
        entry.batteryCapacity && entry.avgRemainingEnergy && entry.avgSoc
          ? `${formatNumber((1 - entry.batteryCapacity / ((entry.avgRemainingEnergy / entry.avgSoc) * 100)) * 100)} %`
          : '–'
    },
    {
      dataField: 'createdAt',
      text: 'Created at',
      sort: true,
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm')
    },
    {
      formatter: (dataField, { id }) => (
        <Link to={`/vehicles/${id}`} className="fs--1 text-600 px-2">
          <FontAwesomeIcon icon="eye" className="fs--1" />
        </Link>
      ),
      align: 'right'
    }
  ];
};

const initStart = moment.utc().subtract(1, 'month');
const initEnd = moment.utc();

const StateOfHealth = () => {
  const [params, setParams] = useStoreState('stateofhealth', {
    page: 1,
    size: 15,
    order: '-createdAt',
    filter: {
      createdAt: {
        gte: initStart.toISOString(),
        lte: initEnd.toISOString()
      }
    }
  });

  const submitDateFilter = ({ startDate, endDate }) => {
    const filter = { ...params.filter };
    filter.createdAt = {
      gte: startDate,
      lte: endDate
    };
    setParams({ ...params, filter });
  };

  return (
    <>
      <PageHeaderWithTimeRange
        title="State of Health"
        defaultStartDate={params.filter?.createdAt?.gte}
        defaultEndDate={params.filter?.createdAt?.lte}
        onSubmit={submitDateFilter}
      />
      <CardTable params={params} setParams={setParams} queryKey="control-center/soh" columns={columns(params)} />
    </>
  );
};

export default StateOfHealth;

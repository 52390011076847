import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { useAuth } from './context/auth';
import { useStoreState } from './stores/SettingsStore';
import { useFetch } from './request';
import { useQuery } from 'react-query';
import { Spinner } from 'reactstrap';
import Flex from './components/common/Flex';

function PrivateRoute({ component: Component, ...rest }) {
  const { authToken } = useAuth();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useStoreState('currentUser');
  const [, setIsNavbarCollapsed] = useStoreState('isNavbarCollapsed');
  useEffect(() => {
    setIsNavbarCollapsed(true);
  }, [location]);

  const fetch = useFetch();
  const { isFetching, isLoading } = useQuery(`users/@me`, fetch, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: currentUser === null && authToken,
    onSuccess: data => setCurrentUser(data)
  });

  return (
    <Route
      {...rest}
      render={props => {
        if (isFetching || isLoading) {
          return (
            <Flex justify="center" align="center" className="p-5" style={{ height: '100vh' }}>
              <Spinner type="grow" size="lg" color="primary" />
            </Flex>
          );
        }
        if (authToken && !isNil(currentUser)) {
          return <Component {...props} />;
        }
        return <Redirect to={{ pathname: '/login', state: { referrer: props.location } }} />;
      }}
    />
  );
}

export default PrivateRoute;

import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Label,
  Col,
  Input,
  FormFeedback,
  FormGroup,
  Alert
} from 'reactstrap';
import { normalizeError, usePatch } from '../request';
import { useStoreState } from '../stores/SettingsStore';
import defaultTo from 'lodash/defaultTo';
import { convertValues } from '../helpers/utils';

const classes = {
  row: 'my-3',
  colLabel: 'text-sm-right text-500'
};

const CurrentUserModal = ({ open, onClose }) => {
  const [currentUser, setCurrentUser] = useStoreState('currentUser');
  const { control, handleSubmit, errors, setError } = useForm();
  const patch = usePatch('users/@me', { throwOnError: true });

  const update = async data => {
    try {
      const newData = await patch.send(convertValues(data));
      setCurrentUser(newData);
      onClose();
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
  };

  return (
    <Modal isOpen={open} keyboard={false} centered>
      <ModalHeader>Account</ModalHeader>
      <ModalBody tag={Form} onSubmit={handleSubmit(update)} id="currentUserModalForm">
        {patch.isError && (
          <Alert color="danger">{patch.error?.data?.message || patch.error?.message || 'An error occurred.'}</Alert>
        )}
        <FormGroup row className={classes.row}>
          <Label sm={6} className={classes.colLabel}>
            Firstname
          </Label>
          <Col>
            <Controller
              as={<Input invalid={!!errors?.firstName} />}
              control={control}
              name="firstName"
              defaultValue={defaultTo(currentUser?.firstName, '')}
            />
            <FormFeedback>{errors?.firstName?.message}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row className={classes.row}>
          <Label sm={6} className={classes.colLabel}>
            Surname
          </Label>
          <Col>
            <Controller
              as={<Input invalid={!!errors?.lastName} />}
              control={control}
              name="lastName"
              defaultValue={defaultTo(currentUser?.lastName, '')}
            />
            <FormFeedback>{errors?.lastName?.message}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row className={classes.row}>
          <Col sm={6} className={`${classes.colLabel} font-weight-medium`}>
            E-Mail
          </Col>
          <Col>{currentUser.email}</Col>
        </FormGroup>
        <FormGroup row className={classes.row}>
          <Label sm={6} className={classes.colLabel}>
            Phone
          </Label>
          <Col>
            <Controller
              as={<Input invalid={!!errors?.phone} />}
              control={control}
              name="phone"
              defaultValue={defaultTo(currentUser?.phone, '')}
            />
            <FormFeedback>{errors?.phone?.message}</FormFeedback>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button outline color="falcon-default" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button disabled={patch.isLoading} color="falcon-primary" type="submit" form="currentUserModalForm">
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CurrentUserModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default CurrentUserModal;

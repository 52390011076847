import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import Flex from '../common/Flex';
import { formatNumber, formatSeconds } from '../../helpers/utils';

const RideSummary = ({ className, filter, from, to }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(
    [
      `stats/summary/ride`,
      {
        ...filter,
        from,
        to
      }
    ],
    fetch,
    {
      enabled: from && to
    }
  );

  return (
    <Card className={className}>
      <FalconCardHeader title="Rides" titleTag="h5" />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <Row>
            <Col md={6} className="border-md-right">
              <Flex wrap>
                <div className="w-50 mb-3">Rides</div>
                <div className="w-50 mb-3">{formatNumber(data?.count) || '0'}</div>
                <div className="w-50 mb-3">Kilometers driven</div>
                <div className="w-50 mb-3">{formatNumber(data?.distance) || '–'} km</div>
                <div className="w-50 mb-3">Consumption</div>
                <div className="w-50 mb-3">{formatNumber(data?.diffEnergy) || '–'} kWh</div>
                <div className="w-50 mb-3">kWh / 100 km</div>
                <div className="w-50 mb-3">{formatNumber((data?.diffEnergy / data?.distance) * 100) || '–'} kWh</div>
                <div className="w-50 mb-3">Duration</div>
                <div className="w-50 mb-3">{formatSeconds(defaultTo(data?.duration, 0))}</div>
                <div className="w-50 mb-3">avg. Duration</div>
                <div className="w-50 mb-3">{formatSeconds(defaultTo(data?.durationAvg, 0))}</div>
              </Flex>
            </Col>
            <Col md={6}>
              <Flex wrap>
                <div className="w-50 mb-3">avg. Speed</div>
                <div className="w-50 mb-3">{formatNumber(data?.speedAvg) || '–'} km/h</div>
                <div className="w-50 mb-3">max. Speed</div>
                <div className="w-50 mb-3">{formatNumber(data?.speedMax) || '–'} km/h</div>
                <div className="w-50 mb-3">avg. Battery Temperature</div>
                <div className="w-50 mb-3">{formatNumber(data?.batteryTemperatureAvg) || '–'} °C</div>
                <div className="w-50 mb-3">avg. SOC at trip start</div>
                <div className="w-50 mb-3">{formatNumber(data?.socStartAvg) || '–'} %</div>
                <div className="w-50 mb-3">avg. SOC at trip end</div>
                <div className="w-50 mb-3">{formatNumber(data?.socEndAvg) || '–'} %</div>
              </Flex>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

RideSummary.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

RideSummary.defaultProps = { filter: {} };

export default RideSummary;

import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Label,
  Col,
  Input,
  FormFeedback,
  FormGroup,
  Alert
} from 'reactstrap';
import { normalizeError, usePost } from '../request';
import { convertValues } from '../helpers/utils';

const classes = {
  row: 'my-3',
  colLabel: 'text-sm-right text-500'
};

const ChangePasswordModal = ({ open, onClose }) => {
  const { control, handleSubmit, errors, setError, watch, reset } = useForm();
  const post = usePost('users/@me/password', { throwOnError: true });

  const update = async data => {
    try {
      await post.send(convertValues(data));
      reset();
      onClose();
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
  };

  return (
    <Modal isOpen={open} keyboard={false} centered>
      <ModalHeader>Change Password</ModalHeader>
      <ModalBody tag={Form} onSubmit={handleSubmit(update)} id="changePasswordModalForm">
        {post.isError && (
          <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
        )}
        <FormGroup row className={classes.row}>
          <Label sm={6} className={classes.colLabel}>
            Current password
          </Label>
          <Col>
            <Controller
              as={<Input type="password" invalid={!!errors?.password} />}
              control={control}
              rules={{ required: 'Required' }}
              name="password"
              defaultValue=""
            />
            <FormFeedback>{errors?.password?.message}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row className={classes.row}>
          <Label sm={6} className={classes.colLabel}>
            New password
          </Label>
          <Col>
            <Controller
              as={<Input type="password" invalid={!!errors?.newPassword} />}
              control={control}
              rules={{ required: 'Required' }}
              name="newPassword"
              defaultValue=""
            />
            <FormFeedback>{errors?.newPassword?.message}</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row className={classes.row}>
          <Label sm={6} className={classes.colLabel}>
            Repeat new password
          </Label>
          <Col>
            <Controller
              as={<Input type="password" invalid={!!errors?.repeatPassword} />}
              control={control}
              rules={{
                required: 'Required',
                validate: value => value === watch('newPassword') || "Passwords don't match."
              }}
              name="repeatPassword"
              defaultValue=""
            />
            <FormFeedback>{errors?.repeatPassword?.message}</FormFeedback>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button outline color="falcon-default" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button disabled={post.isLoading} color="falcon-primary" type="submit" form="changePasswordModalForm">
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ChangePasswordModal;

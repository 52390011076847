import React from 'react';
import { CustomInput } from 'reactstrap';
import PropTypes from 'prop-types';

const RadioGroup = React.forwardRef((props, ref) => {
  const { onChange, name, value: defaultValue, options, invalid, ...attr } = props;
  const handleChange = event => {
    onChange(event, event.target.value);
  };
  return (
    <>
      {options.map(({ label, value }, index) => {
        return (
          <CustomInput
            {...attr}
            className={invalid ? 'is-invalid' : undefined} // dirty fix for error label
            key={index}
            id={`${name}-${index}`}
            name={name}
            type="radio"
            inline
            invalid={invalid}
            label={label}
            value={value}
            checked={defaultValue === value}
            onChange={handleChange}
            {...(index === 0 ? { ref } : {})}
          />
        );
      })}
    </>
  );
});

RadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  invalid: PropTypes.bool
};

RadioGroup.defaultProps = { invalid: false };

export default RadioGroup;

import React from 'react';
import _filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import { format } from 'date-fns';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getGrays, themeColors, getPosition, formatNumber } from '../../helpers/utils';

const getOption = (data, from, to) => {
  const grays = getGrays(false);
  const start = data.map(entry => [entry.startTime, entry.socStart]);
  const diff = data.map(entry => [entry.startTime, entry.socEnd - entry.socStart]);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(entry) {
        return (
          format(new Date(entry[0].axisValue), 'dd/MM HH:mm') +
          ' ' +
          formatNumber(entry[0].value[1]) +
          '% → ' +
          formatNumber(entry[0].value[1] + entry[1].value[1]) +
          '%'
        );
        // return format(entry[0].axisValue, 'dd/MM') + ' ' + formatNumber(entry[1].value[0]) + '%';
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'time',
      // type: 'category',
      // data: labels,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      min: from,
      max: to,
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return format(new Date(value), 'dd/MM');
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        color: grays['400'],
        margin: 15,
        formatter: `{value} %`
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        stack: 'one',
        barMaxWidth: 10,
        itemStyle: {
          barBorderColor: 'rgba(0,0,0,0)',
          color: 'rgba(0,0,0,0)'
        },
        emphasis: {
          itemStyle: {
            barBorderColor: 'rgba(0,0,0,0)',
            color: 'rgba(0,0,0,0)'
          }
        },
        data: start
      },
      {
        type: 'bar',
        stack: 'one',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.info,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: diff
      }
    ],
    grid: { right: '28px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const SocDiffBarChartByTime = ({ data, from, to }) => {
  return (
    <ReactEchartsCore
      echarts={echarts}
      option={getOption(_filter(data, entry => !isNil(entry.socStart) && !isNil(entry.socEnd)), from, to)}
      style={{ width: '100%', minHeight: '15rem', height: '100%' }}
    />
  );
};

export default SocDiffBarChartByTime;

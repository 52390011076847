import React from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Card, CardBody } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import { formatNumber, getChartOptions, getGrays, getPosition, themeColors } from '../../helpers/utils';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import { format } from 'date-fns';

const getOption = data => {
  const hours = data.map(d => d.recordedTimestamp);
  const dataSoc = data.map(d => d.soc);
  const dataEnergy = data.map(d => d.remainingEnergy);
  const dataCalc = data.map(d => (d.remainingEnergy / d.soc) * 100);
  const unit = ['kWh', '%', 'kWh'];
  const grays = getGrays(false);
  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(params) {
        const paramText = params
          .map((p, i) => {
            return `<span class="chart-tooltip-point" style="background-color:${p.color};"></span> ${
              p.seriesName
            }: ${formatNumber(p.value, 1)} ${unit[i]}`;
          })
          .join('<br />');
        return `${format(new Date(params[0].axisValue), 'dd/MM HH:mm')}<br />${paramText}`;
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'category',
      data: hours,
      // type: 'time',
      boundaryGap: true,
      splitLine: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return format(new Date(value), 'HH:mm');
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: [
      {
        type: 'value',
        splitLine: {
          lineStyle: {
            color: grays['300'],
            type: 'dashed'
          }
        },
        axisLine: { show: false },
        axisLabel: {
          show: true,
          formatter: `{value} kWh`,
          color: grays['400'],
          margin: 15
        },
        axisTick: { show: false },
        axisPointer: { show: false }
      },
      {
        type: 'value',
        splitLine: { show: false },
        axisLine: { show: false },
        axisLabel: {
          show: true,
          formatter: `{value} %`,
          color: grays['400'],
          margin: 15
        },
        axisTick: { show: false },
        axisPointer: { show: false }
      }
    ],
    series: [
      {
        type: 'bar',
        name: 'Remaining Energy',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.info,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: dataEnergy
      },
      {
        type: 'bar',
        name: 'State of Charge',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.success,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: dataSoc,
        yAxisIndex: 1
      },
      {
        type: 'line',
        name: 'Calculated',
        lineStyle: {
          color: '#3A434A',
          width: 3
        },
        itemStyle: {
          color: '#3A434A'
        },
        hoverAnimation: true,
        data: dataCalc,
        symbol: 'circle',
        symbolSize: 6
      }
    ],
    grid: { right: '70px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const BatteryCapacityGraph = ({ vehicle, className }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(`vehicles/${vehicle.id}/points/capacity`, fetch);
  const { data: dataSoc, isLoading: isLoadingSoc } = useQuery(`vehicles/${vehicle.id}/compare/soc-energy`, fetch);

  return (
    <Card className={className}>
      <FalconCardHeader title="Battery Total Capacity" titleTag="h5" />
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : size(data?.data) > 0 ? (
          <ReactEchartsCore
            echarts={echarts}
            option={getChartOptions(data.data.map(d => [d.recordedTimestamp, d.value]), 'kWh', themeColors.info)}
            style={{ width: '100%', minHeight: '15rem', height: '100%' }}
          />
        ) : (
          <Alert color="info" className="text-center mb-0">
            Could not find any data.
          </Alert>
        )}

        <h6 className="mt-3">Calculated Battery Total Capacity</h6>
        {isLoadingSoc ? (
          <Loader />
        ) : size(dataSoc?.data) > 0 ? (
          <ReactEchartsCore
            echarts={echarts}
            option={getOption(dataSoc.data)}
            style={{ width: '100%', minHeight: '15rem', height: '100%' }}
          />
        ) : (
          <Alert color="info" className="text-center mb-0">
            Could not find any data.
          </Alert>
        )}
      </CardBody>
    </Card>
  );
};

BatteryCapacityGraph.propTypes = { className: PropTypes.string, vehicle: PropTypes.object.isRequired };

export default BatteryCapacityGraph;

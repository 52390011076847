import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from './common/ButtonIcon';
import { useRequest } from '../request';

const ActiveButtonIcon = ({ onSuccess, onError, method, action, icon, ...rest }) => {
  const request = useRequest(method, action, { throwOnError: true, errorResult: true });
  const handleButtonClick = () => {
    request
      .send()
      .then(() => {
        onSuccess();
      })
      .catch(err => {
        onError(err);
      });
  };

  return (
    <ButtonIcon
      {...rest}
      disabled={request.isLoading}
      icon={request.isLoading ? 'spinner' : icon}
      spin={request.isLoading}
      onClick={handleButtonClick}
    />
  );
};

ActiveButtonIcon.propTypes = {
  ...ButtonIcon.propTypes,
  method: PropTypes.string,
  action: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

ActiveButtonIcon.defaultProps = {
  method: 'POST',
  onSuccess: () => {},
  onError: () => {}
};

export default ActiveButtonIcon;

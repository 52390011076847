import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

const getContentClassNames = color => {
  const contentClassNames = 'display-4 fs-4 mb-2 font-weight-normal text-sans-serif';
  return `${contentClassNames} text-${color}`;
};

const CardSummary = ({ title, rate, color, children }) => {
  return (
    <Card className="h-100" style={{ minWidth: '12rem' }}>
      <CardBody className="position-relative">
        <h6>{title}</h6>
        <div className={getContentClassNames(color)}>{children}</div>
        {rate !== null && <span className={`badge badge-soft-${color} rounded-capsule`}>{rate}</span>}
      </CardBody>
    </Card>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  rate: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node
};

CardSummary.defaultProps = {
  color: 'primary',
  rate: null
};

export default CardSummary;

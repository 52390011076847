import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import CardTable from './CardTable';
import { useStoreState } from '../stores/RequestStore';
import { formatUser } from '../helpers/utils';

const reasonFormatter = entry => {
  if (!entry) return '';
  const [reason] = entry.split(';');
  switch (reason) {
    case 'DRIVER_REGISTERED':
      return 'registered by driver';
    case 'VIN_MISMATCH':
      return 'VIN changed';
    case 'MODIFIED_BY_USER':
      return 'changed by admin';
    default:
      return reason;
  }
};

const RelationHistoryCard = ({ title, queryKey, storeKey, entries, defaultOrder, defaultFilter }) => {
  const [params, setParams] = useStoreState(storeKey || queryKey, {
    page: 1,
    size: entries,
    filter: defaultFilter,
    order: defaultOrder
  });

  const columns = [
    {
      dataField: 'group.name',
      text: 'Group'
    },
    {
      dataField: 'enabledAt',
      text: 'Enabled at',
      formatter: (dataField, entry) => format(new Date(dataField || entry.createdAt), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      dataField: 'enabledReason',
      text: 'Enabled reason',
      formatter: reasonFormatter
    },
    {
      dataField: 'disabledAt',
      text: 'Disabled at',
      formatter: dataField => (dataField ? format(new Date(dataField), 'dd/MM/yyyy HH:mm') : ''),
      sort: true
    },
    {
      dataField: 'disabledReason',
      text: 'Disabled reason',
      formatter: reasonFormatter
    },
    {
      dataField: 'updatedAt',
      text: 'Updated at',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      dataField: 'createdAt',
      text: 'Created at',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    }
  ];

  if (!defaultFilter?.driverId) {
    columns.unshift({
      dataField: 'driver',
      text: 'Driver',
      formatter: dataField => formatUser(dataField) || <i>Unknown</i>
    });
  }

  if (!defaultFilter?.vehicleId) {
    columns.unshift({
      dataField: 'vehicle.vin',
      text: 'VIN'
    });
  }

  if (!defaultFilter?.deviceId) {
    columns.unshift({
      dataField: 'deviceId',
      text: 'Device'
    });
  }

  return <CardTable title={title} params={params} setParams={setParams} queryKey={queryKey} columns={columns} />;
};

RelationHistoryCard.propTypes = {
  title: PropTypes.string,
  queryKey: PropTypes.string.isRequired,
  storeKey: PropTypes.string,
  defaultFilter: PropTypes.object
};

RelationHistoryCard.defaultProps = {
  defaultOrder: '-updatedAt',
  title: 'History of Relations (Usage)',
  entries: 20
};

export default RelationHistoryCard;

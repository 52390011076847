import React from 'react';
import { Navbar } from 'reactstrap';
import Logo from './Logo';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import { useStoreState } from '../../stores/SettingsStore';

const NavbarTop = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useStoreState('isNavbarCollapsed');

  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand={topNavbarBreakpoint}
    >
      <div className={`toggle-icon-wrapper mr-md-3 mr-2 d-${navbarBreakPoint}-none`}>
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={() => {
            setIsNavbarCollapsed(!isNavbarCollapsed);
          }}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo at="navbar-top" height={52} id="topLogo" />
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;

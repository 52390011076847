import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import FalconCardHeader from './common/FalconCardHeader';
import { Card, CardBody } from 'reactstrap';
import Flex from './common/Flex';

const SimpleCard = ({ className, title, description, value, unit }) => {
  return (
    <Card className={className}>
      <FalconCardHeader className="pb-0" title={title} light={false} titleTag="h6" />
      <CardBody tag={Flex} column>
        <div className="display-4 fs-1 mt-1 mb-2 font-weight-normal text-sans-serif">
          {defaultTo(value, 'n/a')} {unit || ''}
        </div>
        {description && (
          <div className="text-500 fs--1">{description}</div>
        )}
      </CardBody>
    </Card>
  );
};

SimpleCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  unit: PropTypes.string
};

export default SimpleCard;

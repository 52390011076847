import React from 'react';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import toUpper from 'lodash/toUpper';
import { format } from 'date-fns';
import { Badge } from 'reactstrap';
import { formatNumber, formatSeconds } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import CardTable from '../../components/CardTable';
import { useStoreState } from '../../stores/RequestStore';
import PageHeaderWithTimeRange from '../../components/common/PageHeaderWithTimeRange';

const columns = params => {
  return [
    {
      dataField: 'deviceId',
      text: 'Device',
      formatter: dataField => (
        <Link className="text-700" to={`/devices/${dataField}`}>
          {dataField}
        </Link>
      ),
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.deviceId?.iLike
      })
    },
    {
      dataField: 'startTime',
      text: 'Start date',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      dataField: 'avgCharge',
      text: 'Charging speed',
      formatter: (dataField, entry) =>
        `${entry.diffEnergy ? formatNumber(entry.diffEnergy / (entry.duration / 3600), 2) : '–'} kW`
    },
    {
      dataField: 'chargingType2',
      text: 'Type (calc.)',
      formatter: (dataField, entry) => {
        if (!entry.diffEnergy) {
          return '';
        }
        const calc = entry.diffEnergy / (entry.duration / 3600);
        let type = 'AC';
        if (calc > 280) {
          type = 'HPC';
        } else if (calc > 30) {
          type = 'DC';
        }
        return type;
      },
    },
    {
      dataField: 'chargingType',
      text: 'Type (user)',
      formatter: dataField => toUpper(dataField),
      filter: selectFilter({
        placeholder: 'All',
        className: 'form-control-sm',
        options: [{ value: 'ac', label: 'AC' }, { value: 'dc', label: 'DC' }, { value: 'hpc', label: 'HPC' }],
        defaultValue: params?.filter?.chargingType
      })
    },
    {
      dataField: 'diffEnergy',
      text: 'Δ kWh',
      formatter: dataField => `${formatNumber(dataField) || '–'} kWh`
    },
    {
      dataField: 'socStart',
      text: 'SOC start',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`,
      sort: true
    },
    {
      dataField: 'socEnd',
      text: 'SOC end',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`,
      sort: true
    },
    {
      dataField: 'duration',
      text: 'Standing time',
      formatter: (dataField, entry) =>
        dataField === null && entry.endTime === null ? (
          <Badge color="soft-success" className="rounded-capsule">
            active
          </Badge>
        ) : (
          formatSeconds(dataField || 0)
        ),
      sort: true
    },
    {
      formatter: (dataField, { id }) => (
        <Link to={`/activities/${id}`} className="fs--1 text-600 px-2">
          <FontAwesomeIcon icon="eye" className="fs--1" />
        </Link>
      ),
      align: 'right'
    }
  ];
};

const initStart = moment.utc().subtract(1, 'month');
const initEnd = moment.utc();

const ChargingSpeed = () => {
  const [params, setParams] = useStoreState('chargingspeed', {
    page: 1,
    size: 30,
    order: '-startTime',
    filter: {
      type: 'charge',
      startTime: {
        gte: initStart.toISOString(),
        lte: initEnd.toISOString()
      }
    }
  });

  const submitDateFilter = ({ startDate, endDate }) => {
    const filter = { ...params.filter };
    filter.startTime = {
      gte: startDate,
      lte: endDate
    };
    setParams({ ...params, filter });
  };

  return (
    <>
      <PageHeaderWithTimeRange
        title="Charging Speed"
        defaultStartDate={params.filter?.startTime?.gte}
        defaultEndDate={params.filter?.startTime?.lte}
        onSubmit={submitDateFilter}
      />
      <CardTable params={params} setParams={setParams} queryKey="trips" columns={columns(params)} />
    </>
  );
};

export default ChargingSpeed;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody, Row, Col } from 'reactstrap';
import { useQuery } from 'react-query';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/gauge';
import { getGrays, themeColors, getPosition, formatNumber } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';
import { useFetch } from '../../request';
import Loader from '../common/Loader';

const getOption = data => {
  const grays = getGrays(false);
  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      // formatter: function(entry) {
      //   const value = entry[0].value[1] === '-' ? entry[1].value[1] : entry[0].value[1];
      //   return format(entry[0].axisValue, 'HH:mm') + ' ' + formatNumber(value) + ' ' + unit;
      // },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    series: [
      {
        type: 'gauge',
        detail: false,
        hoverAnimation: true,
        radius: '100%',
        data
      }
    ],
    grid: {
      top: '0%',
      left: '0%',
      right: '4%',
      bottom: '0%'
    }
  };
};

const SpeedChart = ({ className, filter, from, to }) => {
  const fetch = useFetch();
  const { data, isLoading, isFetching } = useQuery(
    [
      'stats/summary/ride',
      {
        ...filter,
        from,
        to
      }
    ],
    fetch,
    {
      enabled: from && to
    }
  );

  return (
    <Card className={className}>
      <FalconCardHeader title="Speed" titleTag="h5" />
      <CardBody>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            {data?.speedAvg > 0 ? (
              <>
                <ReactEchartsCore
                  echarts={echarts}
                  option={getOption([data?.speedAvg])}
                  style={{ width: '100%', minHeight: '15rem', height: '100%' }}
                />
                <Row>
                  <Col xs={6}>
                    <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                      {formatNumber(data?.speedAvg) || 0} km/h
                    </div>
                    <div className="text-500 fs--1">avg.</div>
                  </Col>
                  <Col xs={6}>
                    <div className="display-4 fs-2 font-weight-normal text-sans-serif">
                      {formatNumber(data?.speedMax) || 0} km/h
                    </div>
                    <div className="text-500 fs--1">max.</div>
                  </Col>
                </Row>
              </>
            ) : (
              <Alert color="info" className="text-center mb-0">
                Could not find any data.
              </Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

SpeedChart.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default SpeedChart;

import { useState } from 'react';
import qs from 'qs';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import { useAuth } from './context/auth';

// const PATH = 'http://localhost:8080/v1/studio/';
const PATH = 'https://api.evelix.app/v1/studio/';

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  isError: false
};

const injectPlaceholder = data => {
  const stars = val => {
    if (!val.startsWith('*') && !val.endsWith('*')) {
      val = `*${val}*`;
    }
    return val;
  };

  mapValues(data.filter, filter => {
    if (filter?.like) {
      filter.like = stars(filter.like);
    } else if (filter?.iLike) {
      filter.iLike = stars(filter.iLike);
    }
  });
};

async function request(method, action, data = null, headers = {}) {
  let url = `${PATH}${action}`;
  const headerDef = {
    'Content-Type': 'application/json',
    ...headers
  };
  const fetchOptions = {
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headerDef,
    referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  if (data) {
    if (method === 'GET') {
      injectPlaceholder(data);
      url += `?${qs.stringify(data)}`;
    } else if (['PUT', 'POST', 'PATCH'].includes(method)) {
      fetchOptions.body = JSON.stringify(data);
    }
  }
  return fetch(url, fetchOptions);
}

async function handleResponse(response) {
  let result = { ...initialState };
  let data = await response.text();
  let isJSON = false;
  try {
    data = JSON.parse(data);
    isJSON = true;
  } catch (err) {}

  return response.ok
    ? { ...result, data }
    : {
        ...result,
        isError: true,
        error: {
          status: response.status,
          [isJSON ? 'data' : 'message']: data
        }
      };
}

function handleResult(result, options) {
  if (!result.isError) {
    return result.data;
  }
  if (options.throwOnError) {
    throw result.error;
  }
  return options.errorResult ? result : null;
}

function createSend(method, action, authToken, setState, options) {
  return async (data, subAction) => {
    let result;
    setState({ ...initialState, isLoading: true });
    try {
      const response = await request(
        method,
        action + (subAction ? `/${subAction}` : ''),
        data,
        authToken
          ? {
              Authorization: `Bearer ${authToken}`
            }
          : {}
      );
      result = await handleResponse(response);
    } catch (e) {
      console.log(e);
      result = {
        ...initialState,
        isError: true,
        error: {
          status: 0,
          message: e.message
        }
      };
    }
    setState(result);
    return handleResult(result, options);
  };
}

export const normalizeError = errList =>
  map(errList, err => ({
    name: err.param,
    message: err.message
  }));

export const useRequest = (method, action, options = { throwOnError: true, errorResult: true }) => {
  const [state, setState] = useState(initialState);
  const { authToken } = useAuth();
  const send = createSend(method, action, authToken, setState, options);
  return { ...state, send };
};

export const useFetch = (options = { throwOnError: true }) => {
  const { authToken } = useAuth();
  return async (action, data) => {
    let result;
    try {
      const response = await request(
        'GET',
        action,
        data,
        authToken
          ? {
              Authorization: `Bearer ${authToken}`
            }
          : {}
      );
      result = await handleResponse(response);
    } catch (e) {
      console.log(e);
      result = {
        ...initialState,
        isError: true,
        error: {
          status: 0,
          message: e.message
        }
      };
    }
    return handleResult(result, options);
  };
};

export const usePost = (action, options = { throwOnError: false, errorResult: true }) => {
  const [state, setState] = useState(initialState);
  const { authToken } = useAuth();
  const send = createSend('POST', action, authToken, setState, options);
  return { ...state, send };
};

export const usePatch = (action, options = { throwOnError: false, errorResult: true }) => {
  const [state, setState] = useState(initialState);
  const { authToken } = useAuth();
  const send = createSend('PATCH', action, authToken, setState, options);
  return { ...state, send };
};

export const usePostOrPatch = (action, options = { throwOnError: false, errorResult: true }) => {
  const [state, setState] = useState(initialState);
  const { authToken } = useAuth();

  let method = 'POST';
  let path = action[0];
  if (action.length > 1 && !isNil(action[1])) {
    method = 'PATCH';
    path = action.join('/');
  }
  const send = createSend(method, path, authToken, setState, options);
  return { ...state, send, isPost: method === 'POST', isPatch: method === 'PATCH' };
};

// export const getApi = async (...args) => useRequestApi('GET', ...args);
// export const patchApi = async (...args) => useRequestApi('PATCH', ...args);
// export const deleteApi = async (...args) => useRequestApi('DELETE', ...args);

import React from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'reactstrap';
import CardSummary from '../CardSummary';
import { useFetch } from '../../request';
import { formatNumber } from '../../helpers/utils';
import Loader from '../common/Loader';

const VehicleStats = () => {
  const fetch = useFetch();
  const { data, isLoading, isFetching } = useQuery(['vehicles', { size: 1 }], fetch);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <Row className="row-cols-2 row-cols-lg-4 card-gutter-wrapper align-items-stretch">
      <Col className="mb-3 card-gutter">
        <CardSummary title="Number of Vehicles" color="info">
          {formatNumber(data?.page?.totalElements, 0) || 0}
        </CardSummary>
      </Col>
      {/*<Col className="mb-3 card-gutter">*/}
      {/*  <CardSummary title="Kilometers driven" color="success">*/}
      {/*    {formatNumber(data?.distance, 0) || 0} km*/}
      {/*  </CardSummary>*/}
      {/*</Col>*/}
      {/*<Col className="mb-3 card-gutter">*/}
      {/*  <CardSummary title="Total Energy charged" color="success">*/}
      {/*    {formatNumber(data?.energyCharged, 1) || 0} kWh*/}
      {/*  </CardSummary>*/}
      {/*</Col>*/}
      {/*<Col className="mb-3 card-gutter">*/}
      {/*  <CardSummary title="Consumption" color="info">*/}
      {/*    {formatNumber(data?.consumption, 1) || 0} kWh*/}
      {/*  </CardSummary>*/}
      {/*</Col>*/}
    </Row>
  );
};

// VehicleStats.propTypes = {
//   from: PropTypes.string,
//   to: PropTypes.string
// };

export default VehicleStats;

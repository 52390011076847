import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { format } from 'date-fns';
import { formatNumber } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import CardTable from '../../components/CardTable';
import { useStoreState } from '../../stores/RequestStore';
import PageHeaderWithTimeRange from '../../components/common/PageHeaderWithTimeRange';

const columns = params => {
  return [
    {
      dataField: 'deviceId',
      text: 'Device',
      formatter: dataField => (
        <Link className="text-700" to={`/devices/${dataField}`}>
          {dataField}
        </Link>
      ),
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.deviceId?.iLike
      })
    },
    {
      dataField: 'relation.vehicle.vehicleModel.label',
      text: 'Model (user)',
    },
    {
      dataField: 'startTime',
      text: 'Start date',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      dataField: 'chargingProviderEnergy',
      text: 'Charged Energy (user input)',
      formatter: dataField => `${formatNumber(dataField) || '–'} kWh`
    },
    {
      dataField: 'diffEnergy',
      text: 'Charged Energy (vehicle)',
      formatter: dataField => `${formatNumber(dataField) || '–'} kWh`
    },
    {
      dataField: 'chargingProviderEnergy',
      text: 'Difference',
      formatter: (dataField, entry) =>
        dataField ? `${formatNumber((1 - entry.diffEnergy / dataField) * -100)} %` : '– %'
    },
    {
      dataField: 'remainingEnergy',
      text: 'Remaining Energy',
      formatter: dataField => `${formatNumber(dataField) || '–'} kWh`
    },
    {
      formatter: (dataField, { id }) => (
        <Link to={`/activities/${id}`} className="fs--1 text-600 px-2">
          <FontAwesomeIcon icon="eye" className="fs--1" />
        </Link>
      ),
      align: 'right'
    }
  ];
};

const initStart = moment.utc().subtract(1, 'month');
const initEnd = moment.utc();

const ChargingDifference = () => {
  const [params, setParams] = useStoreState('chargingdiff', {
    page: 1,
    size: 30,
    order: '-startTime',
    filter: {
      type: 'charge',
      chargingProviderEnergy: { not: 'null' },
      startTime: {
        gte: initStart.toISOString(),
        lte: initEnd.toISOString()
      }
    }
  });

  const submitDateFilter = ({ startDate, endDate }) => {
    const filter = { ...params.filter };
    filter.startTime = {
      gte: startDate,
      lte: endDate
    };
    setParams({ ...params, filter });
  };

  return (
    <>
      <PageHeaderWithTimeRange
        title="Charging Difference"
        defaultStartDate={params.filter?.startTime?.gte}
        defaultEndDate={params.filter?.startTime?.lte}
        onSubmit={submitDateFilter}
      />
      <CardTable params={params} setParams={setParams} queryKey="trips" columns={columns(params)} />
    </>
  );
};

export default ChargingDifference;

import React, { useState } from 'react';
import Datetime from 'react-datetime';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { format } from 'date-fns';
import { Button, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Badge } from 'reactstrap';
import { formatNumber, formatSeconds, formatUser } from '../helpers/utils';
import { Link } from 'react-router-dom';
import PageHeaderSplit from '../components/common/PageHeaderSplit';
import CardTable from '../components/CardTable';
import { useStoreState } from '../stores/RequestStore';
import TripStats from '../components/trip/TripStats';

export const badgeFormatter = status => {
  return (
    <Badge color={`soft-${status ? 'success' : 'warning'}`} className="rounded-capsule">
      <FontAwesomeIcon icon="bars" transform="shrink-2" />
    </Badge>
  );
};

const columns = params => {
  return [
    {
      dataField: 'deviceId',
      text: 'Device',
      formatter: dataField => (
        <Link className="text-700" to={`/devices/${dataField}`}>
          {dataField}
        </Link>
      ),
      sort: true,
      filter: textFilter({
        className: 'form-control-sm',
        defaultValue: params?.filter?.deviceId?.iLike
      })
    },
    {
      dataField: 'relation.vehicle.vehicleModel.label',
      text: 'Model'
    },
    {
      dataField: 'relation.driver',
      text: 'Driver',
      formatter: dataField =>
        dataField ? (
          <Link className="text-700 demo-blur" to={`/drivers/${dataField.id}`}>
            {formatUser(dataField)}
          </Link>
        ) : (
          ''
        )
    },
    {
      dataField: 'startTime',
      text: 'Start date',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      sort: true
    },
    {
      dataField: 'duration',
      text: 'Duration',
      formatter: (dataField, entry) =>
        dataField === null && entry.endTime === null ? (
          <Badge color="soft-success" className="rounded-capsule">
            active
          </Badge>
        ) : (
          formatSeconds(dataField || 0)
        ),
      filter: selectFilter({
        className: 'form-control-sm',
        options: [
          { value: '{"gte":3600}', label: '≥ 1 hour' },
          { value: '{"lt":3600}', label: '< 1 hour' },
          { value: '{"lt":1800}', label: '< 30 minutes' }
        ],
        defaultValue: params?.filter?.duration ? JSON.stringify(params?.filter?.duration) : undefined
      }),
      sort: true
    },
    {
      dataField: 'type',
      text: 'Type',
      formatter: dataField => (dataField === 'ride' ? 'Ride' : 'Charging'),
      filter: selectFilter({
        placeholder: 'All',
        className: 'form-control-sm',
        options: [{ value: 'ride', label: 'Rides' }, { value: 'charge', label: 'Chargings' }],
        defaultValue: params?.filter?.type
      })
    },
    {
      dataField: 'socStart',
      text: 'SOC start',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`,
      sort: true
    },
    {
      dataField: 'socEnd',
      text: 'SOC end',
      formatter: dataField => `${formatNumber(dataField) || '–'} %`,
      sort: true
    },
    {
      dataField: 'diffEnergy',
      text: 'Δ kWh',
      formatter: dataField => `${formatNumber(dataField) || '–'} kWh`
    },
    {
      dataField: 'hasGraph',
      text: 'Currents Chart',
      formatter: badgeFormatter,
      filter: selectFilter({
        placeholder: 'All',
        className: 'form-control-sm',
        options: [{ value: 'true', label: 'Success' }, { value: 'false', label: 'Missing' }],
        defaultValue: params?.filter?.hasGraph
      })
    },
    {
      formatter: (dataField, { id }) => (
        <Link to={`/activities/${id}`} className="fs--1 text-600 px-2">
          <FontAwesomeIcon icon="eye" className="fs--1" />
        </Link>
      ),
      align: 'right'
    }
  ];
};

const initStart = moment.utc().startOf('month');
const initEnd = moment.utc().endOf('month');

const Trips = () => {
  const [params, setParams] = useStoreState('trips', {
    page: 1,
    size: 15,
    order: '-startTime',
    filter: {
      startTime: {
        gte: initStart.toISOString(),
        lte: initEnd.toISOString()
      }
    }
  });

  const [startDate, setStartDate] = useState(moment.utc(params.filter.startTime.gte));
  const [endDate, setEndDate] = useState(moment.utc(params.filter.startTime.lte));

  const submitDateFilter = e => {
    e.preventDefault();
    const filter = { ...params.filter };
    filter.startTime = {
      gte: startDate.toISOString(),
      lte: endDate.toISOString()
    };
    setParams({ ...params, filter });
  };

  return (
    <>
      <PageHeaderSplit title="Rides & Chargings" className="mb-3">
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <InputGroup className="flex-nowrap">
              <Datetime
                closeOnSelect
                isValidDate={current => current.isBefore(endDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={startDate}
                onChange={setStartDate}
                inputProps={{ className: 'form-control border-right-0 rounded-right-0' }}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>-</InputGroupText>
              </InputGroupAddon>
              <Datetime
                closeOnSelect
                isValidDate={current => current.isAfter(startDate)}
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                value={endDate}
                onChange={setEndDate}
                inputProps={{ className: 'form-control rounded-left-0' }}
                className="calendar-right"
              />
            </InputGroup>
          </FormGroup>
          <Button onClick={submitDateFilter} color="falcon-default">
            Apply
          </Button>
        </Form>
      </PageHeaderSplit>
      <TripStats from={params.filter.startTime.gte} to={params.filter.startTime.lte} />
      <CardTable params={params} setParams={setParams} queryKey="trips" columns={columns(params)} />
    </>
  );
};

export default Trips;

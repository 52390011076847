import { useFetch } from '../request';
import React, { useMemo } from 'react';
import { usePaginatedQuery } from 'react-query';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';
import Loader from './common/Loader';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import startsWith from 'lodash/startsWith';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import FalconCardHeader from './common/FalconCardHeader';

const getOrder = orderParam => {
  let sort = 'asc';
  let property = orderParam;
  if (orderParam.charAt(0) === '-') {
    sort = 'desc';
    property = orderParam.substring(1);
  }
  return [sort, property];
};

const headerFormatter = (column, index, { sortElement, filterElement }) => {
  return (
    <div className="d-flex flex-column">
      <div>
        {column.text} {sortElement}
      </div>
      {filterElement && <div className="mt-1 mb-n2">{filterElement}</div>}
    </div>
  );
};

const paginationArray = ({ page: currPage, paginationSize, pageStartIndex, sizePerPage, totalSize }) => {
  const totalPages = Math.ceil(totalSize / sizePerPage);
  const lastPage = pageStartIndex + totalPages - 1;
  let pages = [];
  let endPage = totalPages;
  if (endPage <= 0) return [];

  let startPage = Math.max(currPage - Math.floor(paginationSize / 2), pageStartIndex);
  endPage = startPage + paginationSize - 1;

  if (endPage > lastPage) {
    endPage = lastPage;
    startPage = endPage - paginationSize + 1;
  }
  for (let i = startPage; i <= endPage; i += 1) {
    if (i >= pageStartIndex) pages.push(i);
  }
  return pages;
};

const CardTable = ({ queryKey, params, setParams, columns, title }) => {
  const [columnConfig, columnFilterKeys] = useMemo(() => {
    const filterKeys = [];
    const newCols = columns.map(col => {
      if (col.filter) {
        filterKeys.push(col.dataField);
      }
      return {
        dataField: '',
        text: '',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        headerFormatter,
        ...col
      };
    });
    return [newCols, filterKeys];
  }, [columns]);

  const fetch = useFetch();
  const { resolvedData, isLoading, isError, error } = usePaginatedQuery([queryKey, params], fetch);

  if (isLoading) {
    return (
      <Card className="mb-3 overflow-hidden">
        <Loader />
      </Card>
    );
  }
  if (isError) {
    return (
      <Card className="mb-3 overflow-hidden">
        <Alert color="danger" className="text-center mb-0">
          An error occurred during the request. Status code {error.status}.
        </Alert>
      </Card>
    );
  }
  const data = resolvedData?.data;
  const page = resolvedData?.page;

  const onTableChange = (type, { page, filters, sortField, sortOrder }) => {
    switch (type) {
      case 'sort':
        const order = `${sortOrder === 'asc' ? '' : '-'}${sortField}`;
        if (order !== params.order) {
          setParams({ ...params, order });
        }
        break;
      case 'pagination':
        if (page !== params.page) {
          setParams({ ...params, page });
        }
        break;
      case 'filter':
        const stableFilter = omit(params?.filter, columnFilterKeys);
        const newFilter = mapValues(filters, filter => {
          let val = filter.filterVal;
          if (filter.filterType === 'SELECT' && startsWith(val, '{')) {
            try {
              val = JSON.parse(val);
            } catch (e) {}
          } else if (filter.filterType === 'TEXT') {
            val = { iLike: val };
          }
          return val;
        });
        setParams({ ...params, filter: { ...stableFilter, ...newFilter } });
        break;
      default:
    }
  };

  const [orderDirection, orderBy] = params?.order ? getOrder(params?.order) : [];

  return (
    <Card className="mb-3 overflow-hidden">
      {title && <FalconCardHeader title={title} />}
      <CardBody className="p-0">
        <PaginationProvider
          pagination={paginationFactory({
            custom: true,
            page: params.page,
            sizePerPage: params.size,
            totalSize: page.totalElements,
            paginationSize: 5,
            pageStartIndex: 1
          })}
        >
          {({ paginationProps, paginationTableProps }) => {
            const lastIndex = paginationProps.page * paginationProps.sizePerPage;
            return (
              <>
                <div className="table-responsive">
                  <BootstrapTable
                    remote
                    sort={params?.order ? { dataField: orderBy, order: orderDirection } : undefined}
                    bootstrap4
                    keyField="id"
                    data={data}
                    columns={columnConfig}
                    filter={filterFactory()}
                    bordered={false}
                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0"
                    rowClasses="btn-reveal-trigger border-top border-200"
                    headerClasses="bg-200 text-900 border-y border-200"
                    onTableChange={onTableChange}
                    {...paginationTableProps}
                  />
                </div>
                {page.totalElements === 0 && (
                  <Alert color="info" className="text-center rounded-0 mb-0">
                    Could not find any data.
                  </Alert>
                )}
                {page.totalElements > 0 && page.totalPages > 1 && (
                  <Row noGutters className="px-1 py-3 flex-center">
                    <Col xs="auto">
                      <Button
                        color="falcon-default"
                        size="sm"
                        onClick={() => paginationProps.onPageChange(paginationProps.pageStartIndex)}
                        disabled={paginationProps.page === 1}
                      >
                        <FontAwesomeIcon icon="angle-double-left" />
                      </Button>
                      <Button
                        color="falcon-default"
                        size="sm"
                        className="ml-2"
                        onClick={() => paginationProps.onPageChange(paginationProps.page - 1)}
                        disabled={paginationProps.page === 1}
                      >
                        <FontAwesomeIcon icon="angle-left" />
                      </Button>
                      {paginationArray(paginationProps).map(pageNo => (
                        <Button
                          color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                          size="sm"
                          className="ml-2"
                          onClick={() => paginationProps.onPageChange(pageNo)}
                          key={pageNo}
                        >
                          {pageNo}
                        </Button>
                      ))}
                      <Button
                        color="falcon-default"
                        size="sm"
                        className="ml-2"
                        onClick={() => paginationProps.onPageChange(paginationProps.page + 1)}
                        disabled={lastIndex >= paginationProps.totalSize}
                      >
                        <FontAwesomeIcon icon="angle-right" />
                      </Button>
                      <Button
                        color="falcon-default"
                        size="sm"
                        className="ml-2"
                        onClick={() => paginationProps.onPageChange(page.totalPages)}
                        disabled={lastIndex >= paginationProps.totalSize}
                      >
                        <FontAwesomeIcon icon="angle-double-right" />
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};

CardTable.propTypes = {
  queryKey: PropTypes.string.isRequired,
  params: PropTypes.shape({
    order: PropTypes.string,
    page: PropTypes.number,
    size: PropTypes.number,
    filter: PropTypes.object
  }).isRequired,
  setParams: PropTypes.func.isRequired,
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object)
};

CardTable.defaultProps = { params: { page: 1, size: 15 }, title: null };

export default CardTable;

import { Store } from 'pullstate';
import defaultTo from 'lodash/defaultTo';

export const RequestStore = new Store({});

export const useStoreState = (key, initialState) => {
  const params = defaultTo(RequestStore.useState(s => s[key]), initialState);
  const setParams = newParams => {
    RequestStore.update(s => {
      s[key] = newParams;
    });
  };
  return [params, setParams];
};

import React from 'react';
import PropTypes from 'prop-types';
import upperCase from 'lodash/upperCase';
import SocCard from './SocCard';
import EnergyCard from './EnergyCard';
import { Badge, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Flex from '../common/Flex';
import { formatAddress, formatChargingType, formatSeconds, formatNumber } from '../../helpers/utils';
import WeatherCard from './WeatherCard';
import { format } from 'date-fns';
import SocGraph from './SocGraph';
import CurrentsGraph from './CurrentsGraph';
import TemperatureGraph from './TemperatureGraph';
import VoltageGraph from './VoltageGraph';
import LogsCard from './LogsCard';
import SimpleCard from '../SimpleCard';
import DriverCard from './DriverCard';
import { useStoreState } from '../../stores/SettingsStore';
import VehicleCard from './VehicleCard';

const DetailsCharge = ({ trip }) => {
  const [{ isAdmin = false }] = useStoreState('currentUser');
  const [isDemoMode] = useStoreState('demoMode');
  return (
    <>
      <Row className="card-gutter-wrapper align-items-stretch">
        <Col md={6} className="card-gutter mb-3">
          <Card className="h-100">
            <CardHeader className="pb-0">
              <h6 className="mb-0">
                {format(new Date(trip.startTime), 'MMMM, dd, yyyy')}
                <span className="ml-4">
                  {format(new Date(trip.startTime), 'HH:mm')}
                  {trip.endTime && ` - ${format(new Date(trip.endTime), 'HH:mm')}`}
                </span>
                {!trip.endTime && (
                  <Badge color="soft-success" className="ml-4 rounded-capsule">
                    currently active
                  </Badge>
                )}
                {trip.duration && <span className="ml-4">{formatSeconds(trip.duration)}</span>}
              </h6>
            </CardHeader>
            <CardBody tag={Flex} column>
              <div className="demo-blur">{formatAddress(trip.start)}</div>
              <div className="mt-2">{formatChargingType(trip.start)}</div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <VehicleCard deviceId={trip.deviceId} vehicle={trip?.relation?.vehicle} className="h-100" />
        </Col>
        <Col md={3} className="card-gutter mb-3">
          <DriverCard driver={trip?.relation?.driver} className="h-100" />
        </Col>
      </Row>
      <Row className="row-cols-1 row-cols-sm-2 row-cols-lg-4 card-gutter-wrapper align-items-stretch">
        <Col className="mb-3 card-gutter">
          <EnergyCard
            className="h-sm-100"
            type="charge"
            diffEnergy={trip.diffEnergy}
            remainingEnergy={trip.remainingEnergy}
          />
        </Col>
        <Col className="mb-3 card-gutter">
          <SocCard className="h-sm-100" begin={trip.socStart} end={trip.socEnd} />
        </Col>
        <Col className="mb-3 card-gutter">
          <Card className="h-sm-100">
            <FalconCardHeader className="pb-0" title="HV Battery Voltage" light={false} titleTag="h6" />
            <CardBody tag={Flex} column justify="center">
              <div className="display-2 fs-2 mb-2 font-weight-normal text-sans-serif">
                Start: {trip.batteryVoltageStart !== null ? formatNumber(trip.batteryVoltageStart, 1) : 'n/a'} V
              </div>
              <div className="display-2 fs-2 mb-2 font-weight-normal text-sans-serif">
                End: {trip.batteryVoltageEnd !== null ? formatNumber(trip.batteryVoltageEnd, 1) : 'n/a'} V
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="mb-3 card-gutter">
          <WeatherCard className="h-sm-100" trip={trip} />
        </Col>
      </Row>

      <Row className="card-gutter-wrapper">
        <Col lg={9} className="card-gutter">
          <SocGraph trip={trip} className="mb-3" />
          <CurrentsGraph queryKey={`trips/${trip.id}`} className="mb-3" />
          <TemperatureGraph trip={trip} className="mb-3" />
          <VoltageGraph trip={trip} className="mb-3" />
        </Col>
        <Col lg={3} className="card-gutter">
          {!!trip.diffEnergy && !!trip.chargingProviderEnergy && (
            <Card className="mb-3">
              <FalconCardHeader className="pb-0" title="Charging Loss" light={false} titleTag="h6" />
              <CardBody tag={Flex} column>
                <div className="display-4 fs-1 font-weight-normal text-sans-serif">
                  {formatNumber(trip.chargingProviderEnergy, 1)} kWh
                </div>
                <div className="fs--2 text-600">On station, entry by user</div>

                <div className="mt-3">
                  <span className="display-4 fs-1 font-weight-normal text-sans-serif">
                    {formatNumber((1 - trip.diffEnergy / trip.chargingProviderEnergy) * -100, 1)} %
                  </span>
                  <span className="fs--1 text-600 ml-2">
                    {formatNumber(trip.diffEnergy - trip.chargingProviderEnergy, 1)} kWh
                  </span>
                </div>
                <div className="fs--2 text-600">Charging loss</div>

                <div className="mt-3 display-4 fs-1 font-weight-normal text-sans-serif">
                  = {formatNumber(trip.diffEnergy, 1)} kWh
                </div>
                <div className="fs--2 text-600">In battery</div>
              </CardBody>
            </Card>
          )}
          <SimpleCard
            className="mb-3"
            title="avg. Charging Power (beta)"
            value={trip.diffEnergy !== null ? formatNumber(trip.diffEnergy / (trip.duration / 3600), 2) : null}
            unit="kW"
          />
          <SimpleCard
            className="mb-3"
            title="Costs"
            value={trip.costs !== null ? formatNumber(trip.costs, 2) : null}
            unit="€"
          />
          {trip.chargingProvider && (
            <SimpleCard className="mb-3" title="Charging Provider" value={trip.chargingProvider.label} />
          )}
          <SimpleCard
            className="mb-3"
            title="Charging Type"
            value={trip.chargingType ? upperCase(trip.chargingType) : null}
          />
        </Col>
      </Row>

      {isAdmin && !isDemoMode && <LogsCard queryKey={`trips/${trip.id}/logs`} />}
    </>
  );
};

DetailsCharge.propTypes = {
  trip: PropTypes.object
};

export default DetailsCharge;

import React, { useState } from 'react';
import CardTable from '../../components/CardTable';
import { Badge, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCurrency } from '../../helpers/utils';
import FalconCardHeader from '../common/FalconCardHeader';

const badgeFormatter = status => {
  return (
    <Badge color={`soft-${status ? 'success' : 'warning'}`} className="rounded-capsule">
      <FontAwesomeIcon icon="bars" transform="shrink-2" />
    </Badge>
  );
};

const ElectricityContracts = ({ className }) => {
  const [params, setParams] = useState({
    page: 1,
    size: 15,
    select: 'energyProvider,kwhPrice,monthlyFee,isGreenElectricity',
    filter: {
      type: 'home',
      energyProvider: { not: null }
    },
    order: 'energyProvider'
  });

  const columns = [
    {
      dataField: 'energyProvider',
      text: 'Energy Provider'
    },
    {
      dataField: 'kwhPrice',
      formatter: dataField => `${formatCurrency(dataField) || '–'} €`,
      text: 'kWh Price'
    },
    {
      dataField: 'monthlyFee',
      formatter: dataField => `${formatCurrency(dataField) || '–'} €`,
      text: 'Monthly Fee'
    },
    {
      dataField: 'isGreenElectricity',
      formatter: badgeFormatter,
      text: 'Green Electricity'
    }
  ];

  return (
    <Card className={className}>
      <FalconCardHeader title="Electricity Contracts" titleTag="h5" />
      <CardBody className="pb-1">
        <CardTable params={params} setParams={setParams} queryKey="driver-addresses" columns={columns} />
      </CardBody>
    </Card>
  );
};

export default ElectricityContracts;

import React from 'react';
import { Redirect } from 'react-router-dom';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { useFetch } from '../request';
import { useQuery } from 'react-query';
import Loader from '../components/common/Loader';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';
import { format } from 'date-fns';
import RequestLogs from '../components/group/RequestLogs';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const GroupDetails = ({ match }) => {
  const { id } = match.params;
  const fetch = useFetch();
  const { data: group, isLoading, isError, error } = useQuery(`groups/${id}`, fetch, {
    enabled: id
  });

  if (!id) {
    return <Redirect to={`/users`} />;
  }
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <Alert color="danger" className="text-center mt-4">
        An error occurred during the request. Status code {error.status}.
      </Alert>
    );
  }

  return (
    <>
      <WidgetsSectionTitle title="Group" className="mb-4 mt-3 align-items-center" />

      <Card className="mb-3">
        <FalconCardHeader title="Details" />
        <CardBody>
          <Row className={classes.row}>
            <Col xs={6} className={classes.colLabel}>
              Group name
            </Col>
            <Col>{group?.name || '–'}</Col>
          </Row>
          <Row className={classes.row}>
            <Col xs={6} className={classes.colLabel}>
              Created at
            </Col>
            <Col>{format(new Date(group?.createdAt), 'dd/MM/yyyy HH:mm')}</Col>
          </Row>
        </CardBody>
      </Card>

      <RequestLogs groupId={group.id} />
    </>
  );
};

export default GroupDetails;

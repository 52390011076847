import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import map from 'lodash/map';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { normalizeError, useFetch, usePostOrPatch } from '../../request';
import { useQuery } from 'react-query';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const UserForm = ({ className, formId, data: user, onSubmit, onStateChange }) => {
  const { control, handleSubmit, errors, setError } = useForm();
  const fetch = useFetch();
  const post = usePostOrPatch(['users', user?.id], { throwOnError: true });
  const { data: groups } = useQuery(['groups', { order: 'name' }], fetch);
  const isEdit = !!user?.id;

  const update = async data => {
    onStateChange && onStateChange(true);
    try {
      const newUser = await post.send({
        ...data,
        password: isEdit && data.password === '' ? undefined : data.password
      });
      onSubmit(newUser);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
    onStateChange && onStateChange(false);
  };

  return (
    <Form className={className} onSubmit={handleSubmit(update)} id={formId}>
      {post.isError && (
        <Alert color="danger">{post.error?.data?.message || post.error?.message || 'An error occurred.'}</Alert>
      )}
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Firstname
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.firstName} />}
            control={control}
            name="firstName"
            defaultValue={defaultTo(user?.firstName, '')}
          />
          <FormFeedback>{errors?.firstName?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Surname
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.lastName} />}
            control={control}
            name="lastName"
            defaultValue={defaultTo(user?.lastName, '')}
          />
          <FormFeedback>{errors?.lastName?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          E-Mail {!isEdit && '*'}
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.email} />}
            control={control}
            name="email"
            defaultValue={defaultTo(user?.email, '')}
          />
          <FormFeedback>{errors?.email?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Password {!isEdit && '*'}
        </Label>
        <Col>
          <Controller
            as={<Input type="password" invalid={!!errors?.password} />}
            control={control}
            name="password"
            defaultValue=""
          />
          <FormFeedback>{errors?.password?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Phone
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.phone} />}
            control={control}
            name="phone"
            defaultValue={defaultTo(user?.phone, '')}
          />
          <FormFeedback>{errors?.phone?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Group {!isEdit && '*'}
        </Label>
        <Col>
          <Controller
            as={
              <Input type="select" invalid={!!errors?.groupId}>
                <option value="">Please select</option>
                {map(groups?.data, group => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Input>
            }
            control={control}
            name="groupId"
            defaultValue={defaultTo(user?.groupId, '')}
          />
          <FormFeedback>{errors?.groupId?.message}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
};

UserForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onStateChange: PropTypes.func
};

UserForm.defaultProps = { formId: 'userUpdateForm' };

export default UserForm;

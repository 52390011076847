import React, { useState } from 'react';
import { format } from 'date-fns';
import { useQueryCache } from 'react-query';
import CardTable from '../components/CardTable';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import FormModal from '../components/FormModal';
import ButtonIcon from '../components/common/ButtonIcon';
import Flex from '../components/common/Flex';
import SponsorForm from '../components/form/SponsorForm';

const Sponsors = () => {
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    size: 15,
    order: 'name'
  });
  const queryCache = useQueryCache();

  const columns = [
    {
      dataField: 'name',
      text: 'Sponsor Name'
    },
    {
      dataField: 'tag',
      text: 'Tag (App identifier)'
    },
    {
      dataField: 'inviteCode',
      text: 'Invite Code'
    },
    {
      dataField: 'codeLimit',
      text: 'Limit (usage)'
    },
    {
      dataField: 'showNextCharging',
      text: 'Next Charging (App)',
      formatter: dataField => (dataField ? 'enabled' : 'disabled')
    },
    {
      dataField: 'createdAt',
      formatter: dataField => format(new Date(dataField), 'dd/MM/yyyy HH:mm'),
      text: 'Created at'
    },
    {
      formatter: (dataField, entry) => (
        <>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon="pen"
            className="fs--1 text-600 px-2 m-1"
            onClick={() => setShowModal(entry)}
            iconClassName="fs--1"
          />
        </>
      ),
      align: 'right'
    }
  ];

  return (
    <>
      <WidgetsSectionTitle title="Sponsors" className="mb-4 mt-3 align-items-center" />
      <Flex justify="end" className="mb-3">
        <ButtonIcon icon="plus" color="falcon-primary" onClick={() => setShowModal(true)}>
          New
        </ButtonIcon>
      </Flex>
      <CardTable params={params} setParams={setParams} queryKey="sponsors" columns={columns} />
      <FormModal
        open={!!showModal}
        data={typeof showModal !== 'boolean' ? showModal : undefined}
        form={SponsorForm}
        title="Sponsor"
        onClose={newData => {
          if (newData) {
            queryCache.invalidateQueries('sponsors');
          }
          setShowModal(false);
        }}
      />
    </>
  );
};

export default Sponsors;

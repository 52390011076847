import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HelpTooltip = ({ className, children, placement, id }) => {
  return (
    <span className={className}>
      <FontAwesomeIcon icon={['far', 'question-circle']} transform="shrink-1" className="text-400" id={id} />
      <UncontrolledTooltip placement={placement} target={id}>
        {children}
      </UncontrolledTooltip>
    </span>
  );
};

HelpTooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

HelpTooltip.defaultProps = {
  placement: 'bottom'
};

export default HelpTooltip;

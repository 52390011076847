import React from 'react';
import { format } from 'date-fns';
import WidgetsSectionTitle from '../components/common/WidgetsSectionTitle';
import { Redirect } from 'react-router-dom';
import SimpleCard from '../components/SimpleCard';
import { useStoreState } from '../stores/SettingsStore';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import FalconCardHeader from '../components/common/FalconCardHeader';

const ApiDoc = () => {
  const [{ isAdmin, group }] = useStoreState('currentUser');

  if (!group.token && !isAdmin) {
    return <Redirect to={`/`} />;
  }
  const shortToken = group?.token ? group.token.substr(0, 15) + '...' : '...';

  return (
    <>
      <WidgetsSectionTitle title="API docs" icon="book" className="mb-4 mt-3 align-items-center" />

      <div className="card-deck align-items-stretch mb-3">
        <SimpleCard
          title="Access token"
          value={group?.token}
          description={format(new Date(group?.tokenUpdatedAt), 'dd/MM/yyyy HH:mm')}
        />
        <SimpleCard
          title="Last Request"
          value={group?.tokenLastActionAt ? format(new Date(group?.tokenLastActionAt), 'dd/MM/yyyy HH:mm') : 'never'}
        />
      </div>

      <Card className="mb-3">
        <FalconCardHeader title="API Access" />
        <CardBody>
          Our API service can be use with your personal access token. Authenticate via Bearer Authentication
          (Authorization header needs to be set). The response body of all requests is always JSON.
          <Row className="mt-3 align-items-end">
            <Col sm={2}>API endpoint:</Col>
            <Col>
              <pre>https://api.evelix.app/v1/fetch</pre>
            </Col>
          </Row>
          <Row className="mt-3 align-items-end">
            <Col sm={2}>Auth header:</Col>
            <Col>
              <pre>Authorization: Bearer {shortToken}</pre>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="List Devices" />
        <CardBody>
          This endpoint returns a list of all devices assigned to the given access token.
          <Row className="mt-3 mb-2 align-items-end">
            <Col sm={2}>Request:</Col>
            <Col>
              <pre>/devices</pre>
            </Col>
          </Row>
          Example:
          <pre className="mt-1">
            curl --location --request GET 'https://api.evelix.app/v1/fetch/devices' \<br />
            --header 'Authorization: Bearer {shortToken}'
          </pre>
          <hr />
          <h5 className="mt-4 mb-2">Response Codes</h5>
          <Table className="table-nospace">
            <thead>
              <tr>
                <th>Code</th>
                <th>Status</th>
                <th>Description</th>
                <th>Response body</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>200</td>
                <td>Successful</td>
                <td>Returns a list of devices.</td>
                <td>
                  <pre>
                    {`{
  "data": [
    {
      "id": 123456789012345,
      "imsi": "9012345678978",
      "createdAt": "2020-11-27T00:00:00.000Z",
      "updatedAt": "2020-11-27T00:00:00.000Z"
    }
  ]
}`}
                  </pre>
                </td>
              </tr>
              <tr>
                <td>401</td>
                <td>Forbidden</td>
                <td>The access token is missing, invalid or disabled.</td>
                <td>
                  <pre>
                    {`{
  "errCode": "INVALID_TOKEN",
  "message": "Missing or invalid token."
}`}
                  </pre>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title="Single Device" />
        <CardBody>
          This endpoint returns data about a specific device.
          <Row className="mt-3 mb-2 align-items-end">
            <Col sm={2}>Request:</Col>
            <Col>
              <pre>/devices/{'{deviceId}'}</pre>
            </Col>
          </Row>
          <Row className="mt-3 mb-2 align-items-end">
            <Col sm={2}>with additional data:</Col>
            <Col>
              <pre>
                /devices/{'{deviceId}'}?select={'<comma separated list of properties>'}
              </pre>
            </Col>
          </Row>
          Example:
          <pre className="mt-1">
            curl --location --request GET
            'https://api.evelix.app/v1/fetch/devices/12345678901234?select=stateOfCharge,remainingEnergy' \<br />
            --header 'Authorization: Bearer {shortToken}'
          </pre>
          <h5 className="mt-4 mb-2">Properties</h5>
          The following properties can be used within the query string. Keep in mind, that the request receives live
          data and more properties will lower down the response time. A property value is null if it could not
          be received by the dongle.
          <Table className="table-nospace mt-2">
            <thead>
              <tr>
                <th>Property</th>
                <th>Description</th>
                <th>Data Type</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>status</td>
                <td>
                  Vehicle's current status. Value can be "idle", "ride" or "charge".<br />
                  <i>This property is always in the response body.</i>
                </td>
                <td>string</td>
                <td />
              </tr>
              <tr>
                <td>stateOfCharge</td>
                <td>Live State of Charge (SOC) of battery</td>
                <td>float</td>
                <td>%</td>
              </tr>
              <tr>
                <td>remainingEnergy</td>
                <td>Battery's current charge level</td>
                <td>float</td>
                <td>kWh</td>
              </tr>
              <tr>
                <td>remainingRange</td>
                <td>Remaining available distance</td>
                <td>float</td>
                <td>km</td>
              </tr>
              <tr>
                <td>chargingCurrent</td>
                <td>Charging current at a specific time; negative during rides, positive during charging</td>
                <td>float</td>
                <td>km</td>
              </tr>
              <tr>
                <td>position</td>
                <td>Current GPS position coordinates</td>
                <td>object</td>
                <td>lat / lon</td>
              </tr>
              <tr>
                <td>avgSpeedLastCharging</td>
                <td>Average speed of all rides since last charging</td>
                <td>float</td>
                <td>km/h</td>
              </tr>
              <tr>
                <td>distanceLastCharging</td>
                <td>Driven distance since last charging</td>
                <td>float</td>
                <td>km</td>
              </tr>
              <tr>
                <td>consumptionLastCharging</td>
                <td>Consumed energy since last charging</td>
                <td>float</td>
                <td>kWh</td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <h5 className="mt-4 mb-2">Response Codes</h5>
          <Table className="table-nospace">
            <thead>
              <tr>
                <th>Code</th>
                <th>Status</th>
                <th>Description</th>
                <th>Response body</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>200</td>
                <td>Successful</td>
                <td>Device information</td>
                <td>
                  <pre>
                    {`{
    "id": 123456789012345,
    "imsi": "9012345678978",
    "createdAt": "2020-11-27T10:23:00.886Z",
    "updatedAt": "2020-11-27T10:23:00.886Z",
    "status": "idle"
}`}
                  </pre>
                </td>
              </tr>
              <tr>
                <td>400</td>
                <td>Bad Request</td>
                <td>The API cannot process the request due to an error (e.g. malformed query string).</td>
                <td>
                  <pre>
                    {`Request: /devices/12345678901234?select=
{
  "errors": {
    "select": ""select" is not allowed to be empty"
  },
  "errorList": [
    {
      "param": "select",
      "path": "select",
      "value": "",
      "message": ""select" is not allowed to be empty"
    }
  ]
}`}
                  </pre>
                </td>
              </tr>
              <tr>
                <td>401</td>
                <td>Forbidden</td>
                <td>The access token is missing, invalid or disabled.</td>
                <td>
                  <pre>
                    {`{
  "errCode": "INVALID_TOKEN",
  "message": "Missing or invalid token."
}`}
                  </pre>
                </td>
              </tr>
              <tr>
                <td>404</td>
                <td>Not Found</td>
                <td>The device with the given could not be found.</td>
                <td>
                  <pre>
                    {`{
  "errCode": "NOT_FOUND",
  "param": "id",
  "message": "unknown id"
}`}
                  </pre>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default ApiDoc;

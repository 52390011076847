import React, { useMemo, useRef } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import Flex from '../common/Flex';
import allRoutes from '../../routes';
import { navbarBreakPoint } from '../../config';
import { useStoreState } from '../../stores/SettingsStore';

const filterAdminRoutes = (routes, currentUser) => {
  return routes.filter(route => {
    if (route.admin !== undefined && route.admin !== currentUser.isAdmin) {
      return false;
    }
    if (route.check && !route.check(currentUser)) {
      return false;
    }
    if (route.children) {
      route.children = filterAdminRoutes(route.children, currentUser);
    }
    return true;
  });
};

const NavbarVertical = () => {
  const navBarRef = useRef(null);
  const [isNavbarCollapsed] = useStoreState('isNavbarCollapsed');
  const [currentUser] = useStoreState('currentUser');

  const routes = useMemo(() => {
    return filterAdminRoutes(allRoutes, currentUser);
  }, [currentUser]);

  return (
    <Navbar expand={navbarBreakPoint} className="navbar-vertical navbar-glass" light>
      <Flex align="center">
        <Logo at="navbar-vertical" height={52} />
      </Flex>

      <Collapse navbar isOpen={!isNavbarCollapsed} className="scrollbar" innerRef={navBarRef}>
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={routes} />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarVertical;

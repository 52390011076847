import React from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Alert, Card, CardBody } from 'reactstrap';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import { getGrays, themeColors, getPosition, formatNumber } from '../../helpers/utils';
import { format } from 'date-fns';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import size from 'lodash/size';

const getOption = (data, unit) => {
  const grays = getGrays(false);
  const dataPos = data.map(entry => [entry.recordedTimestamp, entry.value >= 0 ? entry.value : '-']);
  const dataNeg = data.map(entry => [entry.recordedTimestamp, entry.value < 0 ? entry.value : '-']);

  return {
    tooltip: {
      triggerOn: 'mousemove',
      trigger: 'axis',
      padding: [7, 10],
      formatter: function(entry) {
        const value = entry[0].value[1] === '-' ? entry[1].value[1] : entry[0].value[1];
        return format(entry[0].axisValue, 'HH:mm') + ' ' + formatNumber(value) + ' ' + unit;
      },
      backgroundColor: grays.white,
      borderColor: grays['300'],
      borderWidth: 1,
      transitionDuration: 0,
      position(pos, params, dom, rect, size) {
        return getPosition(pos, params, dom, rect, size);
      },
      textStyle: { color: themeColors.dark }
    },
    xAxis: {
      type: 'time',
      // splitNumber: 4,
      // interval: 300 * 1000,
      // minInterval: 600 * 1000,
      // maxInterval: 600 * 1000,
      // data: labels,
      boundaryGap: false,
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLabel: {
        color: grays['400'],
        margin: 15,
        formatter: function(value) {
          return format(new Date(value), 'HH:mm');
        }
      },
      axisTick: { show: false },
      axisPointer: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: grays['300'],
          type: 'dashed'
        }
      },
      axisLine: { show: false },
      axisLabel: {
        show: true,
        formatter: `{value} ${unit}`,
        color: grays['400'],
        margin: 15
      },
      axisTick: { show: false },
      axisPointer: { show: false }
    },
    series: [
      {
        type: 'bar',
        stack: 'colorbyvalue',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.success,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: dataPos
      },
      {
        type: 'bar',
        stack: 'colorbyvalue',
        barMaxWidth: 10,
        itemStyle: {
          color: themeColors.info,
          borderWidth: 0
        },
        hoverAnimation: true,
        data: dataNeg
      }
    ],
    grid: { right: '28px', left: '70px', bottom: '15%', top: '5%' }
  };
};

const CurrentsGraph = ({ queryKey, className }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(`${queryKey}/points/currents`, fetch, {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const { data: dataPower, isLoading: powerIsLoading } = useQuery(`${queryKey}/points/power`, fetch, {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  return (
    <Card className={className}>
      <FalconCardHeader title="Consumption / Currents" titleTag="h5" />
      <CardBody>
        {isLoading || powerIsLoading ? (
          <Loader />
        ) : (
          <>
            <h6>CURRENTS</h6>
            {size(data?.data) > 0 ? (
              <ReactEchartsCore
                echarts={echarts}
                option={getOption(data?.data, 'kW')}
                style={{ width: '100%', minHeight: '15rem', height: '100%' }}
              />
            ) : (
              <Alert color="danger" className="text-center mb-0">
                Could not find any data.
              </Alert>
            )}

            <h6 className="mt-3">POWER</h6>
            {size(dataPower?.data) > 0 ? (
              <ReactEchartsCore
                echarts={echarts}
                option={getOption(dataPower?.data, 'Ah')}
                style={{ width: '100%', minHeight: '15rem', height: '100%' }}
              />
            ) : (
              <Alert color="danger" className="text-center mb-0">
                Could not find any data.
              </Alert>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

CurrentsGraph.propTypes = { className: PropTypes.string, queryKey: PropTypes.string.isRequired };

export default CurrentsGraph;

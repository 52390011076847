import React from 'react';
import PropTypes from 'prop-types';
import defaultTo from 'lodash/defaultTo';
import { Alert, Col, Form, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { normalizeError, usePatch } from '../../request';
import NumberFormat from 'react-number-format';
import RadioGroup from './RadioGroup';
import isNil from 'lodash/isNil';
import { convertValues } from '../../helpers/utils';

const classes = {
  row: 'my-3',
  colLabel: 'text-right text-500'
};

const VehicleForm = ({ className, formId, data: vehicle, onSubmit, onStateChange }) => {
  const { control, handleSubmit, errors, setError } = useForm();
  const patch = usePatch(`vehicles/${vehicle?.id}`, { throwOnError: true });

  const update = async data => {
    onStateChange && onStateChange(true);
    try {
      const newData = await patch.send(convertValues(data));
      onSubmit(newData);
    } catch (e) {
      if (e?.data?.errorList) {
        setError(normalizeError(e?.data?.errorList));
      }
    }
    onStateChange && onStateChange(false);
  };
  if (!vehicle) {
    return <></>;
  }

  return (
    <Form className={className} onSubmit={handleSubmit(update)} id={formId}>
      {patch.isError && !patch.error?.data?.errorList && (
        <Alert color="danger">{patch.error?.data?.message || patch.error?.message || 'An error occurred.'}</Alert>
      )}
      <FormGroup row className={classes.row}>
        <Col xs={6} className={`${classes.colLabel} font-weight-medium`}>
          VIN
        </Col>
        <Col>{vehicle.vin || <i>Unknown</i>}</Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Number plate
        </Label>
        <Col>
          <Controller
            as={<Input invalid={!!errors?.numberPlate} />}
            control={control}
            name="numberPlate"
            defaultValue={defaultTo(vehicle?.numberPlate, '')}
          />
          <FormFeedback>{errors?.numberPlate?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Alarm system
        </Label>
        <Col className="py-1">
          <Controller
            as={
              <RadioGroup
                invalid={!!errors?.hasAlarm}
                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
              />
            }
            name="hasAlarm"
            control={control}
            defaultValue={isNil(vehicle?.hasAlarm) ? '' : vehicle.hasAlarm.toString()}
          />
          <FormFeedback>{errors?.hasAlarm?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Second car
        </Label>
        <Col className="py-1">
          <Controller
            as={
              <RadioGroup
                invalid={!!errors?.isSecondCar}
                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
              />
            }
            name="isSecondCar"
            control={control}
            defaultValue={isNil(vehicle?.isSecondCar) ? '' : vehicle.isSecondCar.toString()}
          />
          <FormFeedback>{errors?.isSecondCar?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Business vehicle
        </Label>
        <Col className="py-1">
          <Controller
            as={
              <RadioGroup
                invalid={!!errors?.isCompanyCar}
                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
              />
            }
            name="isCompanyCar"
            control={control}
            defaultValue={isNil(vehicle?.isCompanyCar) ? '' : vehicle.isCompanyCar.toString()}
          />
          <FormFeedback>{errors?.isCompanyCar?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Has trailer
        </Label>
        <Col className="py-1">
          <Controller
            as={
              <RadioGroup
                invalid={!!errors?.hasTrailer}
                options={[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]}
              />
            }
            name="hasTrailer"
            control={control}
            defaultValue={isNil(vehicle?.hasTrailer) ? '' : vehicle.hasTrailer.toString()}
          />
          <FormFeedback>{errors?.hasTrailer?.message}</FormFeedback>
        </Col>
      </FormGroup>
      <FormGroup row className={classes.row}>
        <Label xs={6} className={classes.colLabel}>
          Battery capacity
        </Label>
        <Col>
          <InputGroup>
            <Controller
              as={
                <NumberFormat
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator={true}
                  customInput={Input}
                />
              }
              control={control}
              name="batteryCapacity"
              defaultValue={defaultTo(vehicle?.batteryCapacity, '')}
            />
            <InputGroupAddon addonType="append">kWh</InputGroupAddon>
          </InputGroup>
          <FormFeedback>{errors?.batteryCapacity?.message}</FormFeedback>
        </Col>
      </FormGroup>
    </Form>
  );
};

VehicleForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired,
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onStateChange: PropTypes.func
};

VehicleForm.defaultProps = { formId: 'vehicleUpdateForm' };

export default VehicleForm;

import React from 'react';
import PropTypes from 'prop-types';
import reduce from 'lodash/reduce';
import moment from 'moment';
import FalconCardHeader from '../common/FalconCardHeader';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';
import { useFetch } from '../../request';
import { useQuery } from 'react-query';
import Loader from '../common/Loader';
import { formatCurrency, formatNumber, formatSeconds } from '../../helpers/utils';
import PieChart from '../vehicle/PieChart';

const ChargeLocationsWithoutHome = ({ className, filter, from, to, children }) => {
  const fetch = useFetch();
  const { data, isLoading } = useQuery(
    [
      `stats/charging-locations`,
      {
        ...filter,
        from,
        to
      }
    ],
    fetch,
    {
      enabled: from && to
    }
  );

  const sumCount = reduce(
    data,
    function(sum, value) {
      return sum + (value?.num || 0);
    },
    0
  );
  const sumEnergy = reduce(
    data,
    function(sum, value) {
      return sum + (value?.diffEnergySum || 0);
    },
    0
  );

  const formatUtcTime = time => {
    if (!time) return null;
    return moment
      .utc(`2020-01-01T${time}Z`)
      .local()
      .format('HH:mm');
  };

  return (
    <Card className={className}>
      <FalconCardHeader title={`Charging Locations without home address`} titleTag="h5">
        {children}
      </FalconCardHeader>
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {sumCount > 0 && (
              <Row className="mb-4">
                <Col xs={6}>
                  <PieChart data={[data?.work?.num || 0, data?.custom?.num || 0]} noHome />
                  <div className="text-center">Charging</div>
                </Col>
                <Col xs={6}>
                  <PieChart data={[data?.work?.diffEnergySum || 0, data?.custom?.diffEnergySum || 0]} noHome />
                  <div className="text-center">Energy</div>
                </Col>
              </Row>
            )}

            <Table borderless className="table-nospace value-center">
              <thead>
                <tr>
                  <th />
                  <th className="text-center color-work">Work</th>
                  <th className="text-center color-custom">Public</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Charging</td>
                  <td>
                    {formatNumber(data?.work?.num, 0) || 0} (
                    {formatNumber((data?.work?.num / (sumCount || 1)) * 100, 0) || 0} %)
                  </td>
                  <td>
                    {formatNumber(data?.custom?.num, 0) || 0} (
                    {formatNumber((data?.custom?.num / (sumCount || 1)) * 100, 0) || 0} %)
                  </td>
                </tr>
                <tr>
                  <td>Energy total</td>
                  <td>
                    {formatNumber(data?.work?.diffEnergySum) || '–'} kWh (
                    {formatNumber((data?.work?.diffEnergySum / (sumEnergy || 1)) * 100, 0) || 0} %)
                  </td>
                  <td>
                    {formatNumber(data?.custom?.diffEnergySum) || '–'} kWh (
                    {formatNumber((data?.custom?.diffEnergySum / (sumEnergy || 1)) * 100, 0) || 0} %)
                  </td>
                </tr>
                <tr>
                  <td>avg. Energy</td>
                  <td>{formatNumber(data?.work?.diffEnergyAvg) || '–'} kWh</td>
                  <td>{formatNumber(data?.custom?.diffEnergyAvg) || '–'} kWh</td>
                </tr>
                <tr>
                  <td>avg. Charging Speed</td>
                  <td>
                    {formatNumber(
                      data?.work?.chargeAvg < 0 ? Math.abs(data?.work?.chargeAvg) : data?.work?.chargeAvg
                    ) || '–'}{' '}
                    kW
                  </td>
                  <td>
                    {formatNumber(
                      data?.custom?.chargeAvg < 0 ? Math.abs(data?.custom?.chargeAvg) : data?.custom?.chargeAvg
                    ) || '–'}{' '}
                    kW
                  </td>
                </tr>
                <tr>
                  <td>avg. start time</td>
                  <td>{formatUtcTime(data?.work?.startTimeAvg) || '–'}</td>
                  <td>{formatUtcTime(data?.custom?.startTimeAvg) || '–'}</td>
                </tr>
                <tr>
                  <td>avg. end time</td>
                  <td>{formatUtcTime(data?.work?.endTimeAvg) || '–'}</td>
                  <td>{formatUtcTime(data?.custom?.endTimeAvg) || '–'}</td>
                </tr>
                <tr>
                  <td>avg. standing time</td>
                  <td>{data?.work?.durationAvg ? formatSeconds(data?.work?.durationAvg) : '–'}</td>
                  <td>{data?.custom?.durationAvg ? formatSeconds(data?.custom?.durationAvg) : '–'}</td>
                </tr>
                <tr>
                  <td>avg. start SOC</td>
                  <td>{formatNumber(data?.work?.startSocAvg) || '–'} %</td>
                  <td>{formatNumber(data?.custom?.startSocAvg) || '–'} %</td>
                </tr>
                <tr>
                  <td>avg. end SOC</td>
                  <td>{formatNumber(data?.work?.endSocAvg) || '–'} %</td>
                  <td>{formatNumber(data?.custom?.endSocAvg) || '–'} %</td>
                </tr>
                <tr>
                  <td>avg. costs</td>
                  <td>{formatCurrency(data?.work?.costsAvg) || '–'} €</td>
                  <td>{formatCurrency(data?.custom?.costsAvg) || '–'} €</td>
                </tr>
                <tr>
                  <td>avg. price per kWh</td>
                  <td>{formatCurrency(data?.work?.pricePerKwhAvg) || '–'} €</td>
                  <td>{formatCurrency(data?.custom?.pricePerKwhAvg) || '–'} €</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </CardBody>
    </Card>
  );
};

ChargeLocationsWithoutHome.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.object,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

ChargeLocationsWithoutHome.defaultProps = { filter: {} };

export default ChargeLocationsWithoutHome;

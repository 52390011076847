import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FalconCardHeader from '../common/FalconCardHeader';
import { Badge, Card, CardBody } from 'reactstrap';
import Flex from '../common/Flex';
import _map from 'lodash/map';
import { formatUser } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useFetch } from '../../request';

const DriverCard = ({ className, driver }) => {
  const fetch = useFetch();
  const { data: addresses } = useQuery(`drivers/${driver?.id}/address`, fetch, {
    enabled: driver?.id
  });
  const state = useMemo(() => {
    const res = {};
    _map(addresses?.data, addr => {
      res[addr.type] = addr.canCharge;
    });
    return res;
  }, [addresses]);

  return (
    <Card className={className}>
      <FalconCardHeader className="pb-0" title="Driver" light={false} titleTag="h6" />
      <CardBody tag={Flex} column>
        {driver ? (
          <Link
            to={`/drivers/${driver.id}`}
            className="display-4 fs-1 mb-2 font-weight-normal text-sans-serif text-700 demo-blur"
          >
            {formatUser(driver)}
          </Link>
        ) : (
          <i className="mb-2">Driver not set</i>
        )}

        <div className="mt-1">
          <Badge className={`mr-2 mb-1 badge-home${state.home ? '' : '-outline'} badge-large`} pill>
            Home {state.home ? '✓' : '✗'}
          </Badge>
          <Badge className={`mr-2 mb-1 badge-work${state.work ? '' : '-outline'} badge-large`} pill>
            Work {state.work ? '✓' : '✗'}
          </Badge>
          <Badge className={`mr-2 mb-1 badge-custom${driver?.usePublicCharging ? '' : '-outline'} badge-large`} pill>
            Public {driver?.usePublicCharging ? '✓' : '✗'}
          </Badge>
        </div>
      </CardBody>
    </Card>
  );
};

DriverCard.propTypes = {
  className: PropTypes.string,
  driver: PropTypes.object
};

export default DriverCard;

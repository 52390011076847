import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Badge } from 'reactstrap';
import Avatar from '../common/Avatar';
import { useAuth } from '../../context/auth';
import { usePost } from '../../request';
import { useStoreState } from '../../stores/SettingsStore';
import { useQueryCache } from 'react-query';
import CurrentUserModal from '../CurrentUserModal';
import ChangePasswordModal from '../ChangePasswordModal';
import { setItemToStore } from '../../helpers/utils';

const ProfileDropdown = ({ history }) => {
  const { setAuthToken } = useAuth();
  const [{ isAdmin = false }, setCurrentUser] = useStoreState('currentUser');
  const [isDemoMode, setDemoMode] = useStoreState('demoMode');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentUserModal, setCurrentUserModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const post = usePost('logout');
  const queryCache = useQueryCache();

  const handleLogout = async () => {
    await post.send();
    queryCache.invalidateQueries('users/@me');
    setAuthToken(null);
    setCurrentUser(null);
    history.push('/login');
  };

  return (
    <>
      <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle nav className="pr-0">
          <Avatar icon="user" />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-card">
          <div className="bg-white rounded-soft py-2">
            <DropdownItem onClick={() => setCurrentUserModal(true)}>Account</DropdownItem>
            <DropdownItem onClick={() => setChangePasswordModal(true)}>Change password</DropdownItem>
            {isAdmin && (
              <DropdownItem
                onClick={() => {
                  const newVal = !isDemoMode;
                  setItemToStore('demoMode', newVal);
                  setDemoMode(newVal);
                }}
              >
                Demo{' '}
                <Badge color={`soft-${isDemoMode ? 'success' : 'secondary'}`} className="rounded-capsule px-2 ml-2">
                  {isDemoMode ? 'on' : 'off'}
                </Badge>
              </DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
          </div>
        </DropdownMenu>
      </Dropdown>
      <CurrentUserModal open={currentUserModal} onClose={() => setCurrentUserModal(false)} />
      <ChangePasswordModal open={changePasswordModal} onClose={() => setChangePasswordModal(false)} />
    </>
  );
};

export default withRouter(ProfileDropdown);
